<template>
    <div v-if="!object.id" class="mt-8">
        <main-component :object="item" @callLoadItem="loadItem"></main-component>
    </div>
    <div v-else>
        <v-tabs
            v-model="active_tab"
            show-arrows 

        >
            <v-tab href="#tab-1" class="caption"> Principal</v-tab>
            <v-tab href="#tab-2" class="caption"> Foto </v-tab>
            <v-tab href="#tab-3" class="caption"> Alterar Senha </v-tab>
            <v-tab v-if="item.profile == 'Corretor' && me.profile == 'Master'" href="#tab-4" class="caption"> Permissões </v-tab>
        </v-tabs>
    

        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="item" @callLoadItem="loadItem"></main-component>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <upload-component :object="upload" @callLoadItem="loadItem"></upload-component> 
                </v-card>    
            </v-tab-item>                

            <v-tab-item value="tab-3">
                <v-card flat class="pa-8">
                    <change-password-component :object="change_password"></change-password-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item v-if="item.profile == 'Corretor' && me.profile == 'Master'" value="tab-4">
                <v-card flat class="pa-8">
                    <property-item-component :object="property_items" @callLoadItem="loadItem"></property-item-component>
                </v-card>    
            </v-tab-item>

        </v-tabs-items>

    </div>    
</template>


<script>
import { URL_UPLOAD } from '../../../config/config.js'
import MainComponent from './MainComponent.vue'
import UploadComponent from '../../upload/UploadComponent.vue'
import ChangePasswordComponent from '../../changepassword/ChangePasswordComponent.vue'
import PropertyItemComponent from '../../property_items/PropertyItemComponent.vue'

export default {
    created() {
        this.setTab()
        this.setObject()
    },

    components: {
       MainComponent,
       UploadComponent,
       ChangePasswordComponent,
       PropertyItemComponent
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.item.id != this.object.id){
                this.setTab()
            }
            this.setObject()
        },
        
        item () {   
            this.setObject()
        }
    },
    
    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    data () {
        return {
            active_tab: 'tab-1',
            item: {},

            upload:{
                multiple: false,
                model: 'users',
                item_id: '',
                items: '',
                extensions: '.jpg,.png,.jpeg',
                label_extensions: 'File  (jpeg e png)',
                path: '',
                description: 'photo',
                icon: 'mdi-camera'
            },

            change_password: {
                model: "users",
                item_id: "",     
            },

            property_items: {
                model: "users",
                model_property: "users",
                order: "order",
                method: "storePermission",
                item_id: "",
                items: []     
            }
        }
    },

    methods: {
        changeStep(step){
            this.active_tab = step
        },

        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        setObject(){
            this.item = this.object

            // uploads
            this.upload.item_id = this.object.id
            this.upload.items = this.object.uploads
            this.upload.path = URL_UPLOAD + `users/${this.object.id}`

            // set password
            this.change_password.item_id = this.object.id

            // set property_items
            this.property_items.item_id = this.object.id
            this.property_items.items = this.object.permissions
        },
        
    },
}
</script>

<style scoped>

</style>