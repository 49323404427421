<template>
    <div>
        <v-container>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <slide-group-component :object="object"></slide-group-component>
                </v-col>    
            </v-row>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <show-main-component class="mt-4" :object="object"></show-main-component>
                    <show-plan-component v-if="object.plans && object.plans.length > 0" class="mt-4" :object="object"></show-plan-component>
                    <show-tower-component v-if="object.towers && object.towers.length > 0" class="mt-4" :object="object"></show-tower-component>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <map-component class="mt-4" :object="object"></map-component>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SlideGroupComponent from '../../slidegroup/SlideGroupComponent.vue'
import ShowMainComponent from '../show/show_partials/ShowMainComponent.vue'
import ShowPlanComponent from '../show/show_partials/ShowPlanComponent.vue'
import ShowTowerComponent from '../show/show_partials/ShowTowerComponent.vue'
import MapComponent from '../../maps/MapComponent.vue'

export default {
    components: {
        SlideGroupComponent,
        ShowMainComponent,
        ShowPlanComponent,
        ShowTowerComponent,
        MapComponent
    },

    props: {
        object: {
            require: true,
        },
    }, 

}
</script>