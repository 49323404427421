<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.last_update"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="item.last_update"
                                label="Ultima Atualizaçāo"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                class="rounded-0 caption"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="last_update" @input="calendar.last_update = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>
import { STATUS_ITEMS } from '../config_condominiums/config_condominiums.js'

export default {
    components: {
    
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },

        last_update () {
            this.item.last_update = this.formatDate(this.last_update)
        },
    }, 

    data () {
        return {
            item: {
                last_update: ''
            },

            items: {
                status: STATUS_ITEMS,
            },

            last_update: '',

            calendar: {
                last_update: false,
            },

            rules: {
                required: value => !!value || 'Obrigatório'
            },
        }
    },

    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            const action = 'updateCondominium' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 

        setObject(){
            this.item = this.object
        },
        
    },
}
</script>

<style scoped>

</style>