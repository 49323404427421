<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.url"
                        label="URL"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.titulo_site"
                        label="Titulo do Site"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        v-model="item.descricao_site"
                        label="Descriçāo"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.status_website"
                        :items="items.status_website"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status do website:</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.chat_tawk"
                        label="Chat Tawk"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.mapbox"
                        label="Mapbox"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.google_site_verification"
                        label="Google Site Verification"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.facebook_pixel_code"
                        label="Facebook pixel code"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>  
            </v-row>
            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>
export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    data () {
        return {
            descricao: 'teste',

            item: {},

            items: {
                status_website: ['Ativo','Desatualizado']
            },

            rules: {
                required: value => !!value || 'Required',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },
        }
    },

    methods: {
        setObject(){
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.update(this.item)
            }
        },

        update(item){
            this.$store.dispatch('updateBranch', item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
        
    },
}
</script>

<style scoped>

</style>