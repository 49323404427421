<template>
    <div>
        <v-card
            class="pa-0 rounded-0"
            outlined
            id="table_border"
        >
            <v-simple-table dense class="rounded-0">
                <thead fixed-header>
                    <tr>
                        <th colspan="2" :class="$css_title_module">
                            Informações
                        </th>
                    </tr>
                </thead>
                <tbody>
                     <tr>
                        <td :class="$css_show_title" id="td_border">Status</td>
                        <td :class="$css_show_description" id="td_border">
                            <v-chip
                            :color="object.status | statusPAI"
                            label
                            small
                            :class="$css_report_list"
                        >
                            {{ object.status }}
                        </v-chip> 
                        </td>
                     </tr>
                     <tr v-if="object.last_update && object.last_update != ''">    
                        <td :class="$css_show_title" id="td_border">Dt.Atualizaçāo</td>
                        <td :class="$css_show_description">{{ object.last_update }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Nome</td>
                        <td :class="$css_show_description">{{ object.nome }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Endereço</td>
                        <td :class="$css_show_description">{{ object.endereco_completo }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Tipo</td>
                        <td :class="$css_show_description">{{ object.tipo_condominio }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Estado</td>
                        <td :class="$css_show_description">{{ object.estado_condominio }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Finalidade</td>
                        <td :class="$css_show_description">{{ object.finalidade }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Regiāo</td>
                        <td :class="$css_show_description">{{ object.regiao }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Torre única</td>
                        <td :class="$css_show_description">{{ object.torre_unica }}</td>
                    </tr>
                    <tr v-if="object.sindico && object.sindico != ''">
                        <td :class="$css_show_title" id="td_border">Sindico</td>
                        <td :class="$css_show_description">{{ object.sindico }}</td>
                    </tr>
                    <tr v-if="object.portaria && object.portaria != ''">
                        <td :class="$css_show_title" id="td_border">Portaria</td>
                        <td :class="$css_show_description">{{ object.portaria }}</td>
                    </tr>
                    <tr v-if="object.builder && object.builder != ''">
                        <td :class="$css_show_title" id="td_border">Construtora</td>
                        <td :class="$css_show_description">{{ object.builder }}</td>
                    </tr>
                    <tr v-if="object.places && object.places.length > 0">
                        <td :class="$css_show_title" id="td_border">Locais</td>
                        <td :class="$css_show_description">
                            <v-chip
                                small
                                class="ma-1 caption"
                                v-for="lc in object.places" :key="lc.id"    
                            >
                                {{ lc.distrito }} - {{ lc.cidade }}
                            </v-chip>
                        </td>
                    </tr>
                    <tr v-if="object.positions && object.positions.length > 0">
                        <td :class="$css_show_title" id="td_border">Posições</td>
                        <td :class="$css_show_description">
                            <v-chip
                                small
                                class="ma-1 caption"
                                v-for="ps in object.positions" :key="ps.id"    
                            >
                                {{ ps.nome }}
                            </v-chip>
                        </td>
                    </tr>
                    <tr v-if="object.infrastructures && object.infrastructures.length > 0">
                        <td :class="$css_show_title" id="td_border">Infra</td>
                        <td :class="$css_show_description">
                            <v-chip
                                small
                                class="ma-1 caption"
                                v-for="inf in object.infrastructures" :key="inf.id"    
                            >
                                {{ inf.description }}
                            </v-chip>
                        </td>
                    </tr>
                    <tr v-if="object.recreations && object.recreations.length > 0">
                        <td :class="$css_show_title" id="td_border">Lazer</td>
                        <td :class="$css_show_description">
                            <v-chip
                                small
                                class="ma-1 caption"
                                v-for="inf in object.recreations" :key="inf.id"    
                            >
                                {{ inf.description }}
                            </v-chip>
                        </td>
                    </tr>
                    <tr v-if="object.colaborators && object.colaborators.length > 0">
                        <td :class="$css_show_title" id="td_border">Colaboradores</td>
                        <td :class="$css_show_description">
                            <v-chip
                                small
                                class="ma-1 caption"
                                v-for="colab in object.colaborators" :key="colab.id"    
                            >
                                {{ colab.name }} - {{ colab.description }}
                            </v-chip>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true
        }
    }
}
</script>