<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense> 
                <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
                    <v-tooltip top max-width="650px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="params.multiple_codigo"
                                outlined
                                dense
                                label="Codigos"
                                append-icon="mdi-help-circle-outline"
                                v-on="on"
                                :class="$css_field"
                                clearable
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Código</span>
                                </template>
                            </v-text-field>
                        </template>
                        <span>
                            Para pesquisar mais de um codigo basta separar por virgula
                        </span>
                    </v-tooltip>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.multiple_status"
                        :items="items.status"
                        outlined
                        multiple
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_status.length - 1 }} opções)
                            </span>
                        </template>   

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_tipo_imovel"
                        :items="items.tipos_imovel"
                        item-text="tipo"
                        item-value="tipo"
                        outlined
                        dense
                        multiple
                        clearable
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo Imovel</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                                (+{{ params.multiple_tipo_imovel.length - 1 }} opções)
                            </span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.multiple_finalidade"
                        :items="items.finalidade"
                        outlined
                        multiple
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Finalidade</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_finalidade.length - 1 }} opções)
                            </span>
                        </template>   

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="params.nome"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_locacao_from"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl locação de</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_locacao_to"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl locação até</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_venda_from"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl venda de</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_venda_to"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl venda até</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_condominio_from"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl condominio de</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_condominio_to"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl condominio até</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.dormitorios"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Dormitórios minimo</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.banheiros"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Banheiros minimo</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.vagas"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vagas minima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.salas"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Salas minima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_from"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área mínima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_to"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área máxima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.endereco"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="params.numero"
                        label="Número"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_estado_imovel"
                        :items="items.estado_imovel"
                        outlined
                        multiple
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado imóvel</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_estado_imovel.length - 1 }} opções)
                            </span>
                        </template>   

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.places"
                        :items="placeitems"
                        outlined
                        dense
                        item-text="distrito_cidade_estado"
                        item-value="all"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Locais</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.distrito_cidade_estado }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.places.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.private_areas"
                        :items="filterPropertyItems(private_areas)"
                        outlined
                        dense
                        item-text="description"
                        item-value="description"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área privativa</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.description }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.private_areas.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.recreations"
                        :items="filterPropertyItems(recreations)"
                        outlined
                        dense
                        item-text="description"
                        item-value="description"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área lazer</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.description }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.recreations.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.infrastructures"
                        :items="filterPropertyItems(infrastructures)"
                        outlined
                        dense
                        item-text="description"
                        item-value="description"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Infra</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.description }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.infrastructures.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col> 


                <v-col v-if="builders && builders.length > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.builder_id"
                        :items="builders"
                        outlined
                        dense
                        item-text="nome"
                        item-value="id"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Construtoras</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.nome }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.builder_id.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>    
            </v-row>
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="blue darken-1"
                        text  
                        @click.prevent="clear" 
                        :class="$css_button"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click.prevent="submit"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>        
    </div>
</template>

<script>
import { STATUS_ITEMS, TIPO_IMOVEL_CONDOMINIUM_ITEMS, FINALIDADES_CONDOMINIUM_ITEMS, ESTADO_CONDOMINIUM_ITEMS } from '../config_condominiums/config_condominiums.js' 

export default {
    created(){
        this.setItems()
        this.loadPlaceItems()
        this.loadBuilders()
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        placeitems() {
            return this.$store.state.placeitems.items.data    
        },

        builders() {
            return this.$store.state.builders.items.data    
        },
    },

    data() {
        return {
            params: {
                id: ''
            },

            items_params: {
                paginate: 'false'
            },

            private_areas: {
                model: "private_areas",
                order: "description"
            },

            infrastructures: {
                model: "infrastructures",
                order: "order"
            },

            recreations: {
                model: "recreations",
                order: "order"
            },
            
            items: {
                status: STATUS_ITEMS,
                tipos_imovel: TIPO_IMOVEL_CONDOMINIUM_ITEMS,
                finalidade: FINALIDADES_CONDOMINIUM_ITEMS,
                estado_imovel: ESTADO_CONDOMINIUM_ITEMS
            },

            filterProperty: {
                model: 'zonings',
                tipos: '',
                regioes: '',
                order: 'description'
            },
        }
    },

    methods: {

        clear() {
            this.$refs.form.reset()
        },

        submit(){
            this.params.id = ''
            this.$emit('callLoadItems',this.params)
        },

        setItems() {
            const zonings = []
            const results = this.filterPropertyItems(this.filterProperty)
            results.forEach(i => {
                    zonings.push(i.description)
            })

            this.items.zonings = zonings
        },

        loadPlaceItems(){
            console.log(this.place_params)
            this.$store.dispatch('loadPlaceItems', this.place_params) 
        },

        loadBuilders () {
            this.$store.dispatch('loadBuilders', this.items_params) 
        },
    }
}
</script>