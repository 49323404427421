<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-0 ma-0"
                >        
                    <v-expansion-panels class="pa-0 ma-0" flat v-model="panel_search" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header hide-actions>
                                <bread-crumb-component :object="breadcrumbs"></bread-crumb-component>     
                                
                                <span class="text-right">
                                    <v-tooltip left class="text-right">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-if="$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                            <v-btn
                                                v-else
                                                color="pink"
                                                outlined
                                                class="ma-2"
                                            >
                                                <v-icon v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                                FILTROS
                                            </v-btn>
                                        </template>

                                        <span>Filtros</span>
                                    </v-tooltip>  
                                </span> 
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>                                
                                <filter-component @callLoadItems="loadItems"></filter-component>    
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense class="d-print-none">
            <v-col class="pa-1 mt-1" cols="12" xs="12" sm="12" md="3" lg="3" xl="3"> 
                <order-component v-if="listitems" :object="items_order" @callLoadItems="loadItems"></order-component>
            </v-col>
        </v-row>        
         
        <div>
            <report-component v-if="listitems" :object="listitems" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" @callShowItem="showItem"  ref="report"></report-component>
        </div>

        <div class="mb-6">&nbsp;</div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog.insert_edit" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Fac

                    <span v-if="item.codigo" class="ml-2">- Código {{item.codigo}}  </span>
                    <span v-else class="ml-2"> Nova</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialogInsertEdit()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" @callLoadItem="loadItem" @callRedirectEditItem="redirectEditItem"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog.show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="item.codigo">Código {{item.codigo}}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="editItem(item.id)" class="mr-4">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="openCloseDialogShow()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-component :object="item"></show-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Icon new -->
        <button-new-register-component @callNewItem="newItem()"></button-new-register-component>
        <!-- End Icon new -->

    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'
import FilterComponent from './FilterComponent.vue'
import ShowComponent from '../show/ShowComponent.vue'
import ButtonNewRegisterComponent from '@/components/button_newregister/ButtonNewRegisterComponent.vue'
import OrderComponent from '@/components/order/OrderComponent.vue'

export default {
    components:{
        ButtonNewRegisterComponent,
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent,
        FilterComponent,
        ShowComponent,
        OrderComponent
    },
    
    created() {
        this.loadItems()
        this.setUserPermission()
        //this.setOrder()
    },

    computed: {
        listitems() {
            return this.$store.state.facs.items    
        },

        me () {
          return this.$store.state.auth.me
        },

        store_fac() {
            return this.$store.state.facs.store    
        },
    },    

    data () {
        return {
            permissions:{
                store: true,
            }, 

            item: {},

            dialog: {
                insert_edit: false,
                show: false
            },
            
            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Facs',
                    disabled: true,
                    href: 'facs',
                },
            ],

            panel_search: [],

            params: {
                id: ''
            },

            items_order: {
                current_order: { 
                    value: 'facs.created_at', 
                    description: 'Data de cadastro',
                    sense: { value: 'asc', description: 'menor -> maior'},
                    params: ''
                },
                sense: [
                    { value: 'asc', description: 'menor -> maior' },
                    { value: 'desc', description: 'maior -> menor' },
                ],
                items:[
                    {
                        value: 'facs.created_at', 
                        description: 'Data de cadastro'
                    },
                    {
                        value: 'facs.updated_at', 
                        description: 'Data atualização'
                    },
                    {
                        value: 'facs.status', 
                        description: 'Status'
                    }    
                ] 
            },
        }
    },


    methods: {
       
        loadItems (params = '') {
            this.$store.commit('STORE_FAC', false)

            if(this.$route.params.status && this.$route.params.status != ''){
                this.params.status = this.$route.params.status
                this.$route.params.status = ''
            }
            if(this.$route.params.id && this.$route.params.id != ''){
                this.params.id = this.$route.params.id
                this.$route.params.id = ''
            }
            if(this.$route.params.filter_home && this.$route.params.filter_home != ''){
                this.params.filter_home = this.$route.params.filter_home
                this.$route.params.filter_home = ''
            }
            if(this.$route.query.filter_home && this.$route.query.filter_home != undefined){
                this.params.filter_home = this.$route.query.filter_home
                this.$route.query.filter_home = ''
            }
            // in case is exists params from search is require set null all $route params
            if(params != ''){
                this.params = {} 
                this.params = params
            }

            this.items_order.current_order.params = this.params
            this.$store.dispatch('loadFacs', this.params) 
            this.panel_search = []

            const order = this.items_order.items.filter(model => model.value == this.params.order)
            if(order && order.length > 0){
                this.items_order.current_order.value = order[0].value
                this.items_order.current_order.description = order[0].description
                const sense = this.items_order.sense.filter(model => model.value == this.params.sense)
                if(sense && sense.length > 0){
                    this.items_order.current_order.sense = sense[0]
                }
            }

            if(this.$route.params.show && this.$route.params.show == 'true' && this.params.id){
                this.$route.params.show = null
                this.showItem(this.params.id)
            }
        },

        newItem(){
            this.item = {}
            this.dialog.insert_edit = !this.dialog.insert_edit
        },

        redirectEditItem(id){
            this.item = {}
            this.dialog.insert_edit = !this.dialog.insert_edit
            this.editItem(id)    
        },

        setItem(item){
            this.item.nome = item.client.nome
            this.item.como_conheceu = item.client.como_conheceu
            this.item.telefone = item.client.telefone
            this.item.telefone2 = item.client.telefone2
            this.item.email = item.client.email
            this.item.email2 = item.client.email2
        },

        loadItem(id){
            this.$store.dispatch('loadFac', id) 
                        .then(response => {
                            this.item = response.data
                            this.setItem(this.item)
                        })
        },

        editItem (id) {   
            this.dialog_show = false 
            this.$store.dispatch('loadFac', id) 
                        .then(response => {
                            this.item = response.data
                            this.setItem(this.item)

                            this.dialog.show = false
                            this.dialog.insert_edit = true
                        })
                                   
        },

        showItem (id) {    
            this.$store.dispatch('loadFac', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog.insert_edit = false
                            this.dialog.show = true
                        })
                                   
        },

        openCloseDialogInsertEdit(){
            
            if(this.item.id != undefined && this.store_fac == true){
                this.params = {}
                this.params.id = this.item.id
            }else{
                this.$store.commit('STORE_FAC', false)
            }
        
            this.item = {}
            this.dialog.insert_edit = !this.dialog.insert_edit
            this.dialog.show = false
            if(this.dialog.show == false){
                this.loadItems(this.params)
            }
             
        },

        openCloseDialogShow(){
            this.dialog.insert_edit = false
            this.dialog.show = !this.dialog.show
        },

        setUserPermission(){
            this.permissions.store = this.userPermission('Cadastra FAC')
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>