<template>
    <div>
        <v-dialog
            v-model="preloader"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                Carregando ...
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    computed: {
        preloader () {
          return this.$store.state.preloader.loading
        },
    },
}
</script>