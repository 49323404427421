<template>
    <div>
        <v-row class="mt-4">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                
                <v-data-table
                    :headers="headers"
                    :items="object.data"
                    :options.sync="options"
                    :server-items-length="object.meta && object.meta.total"
                    class="elevation-1 table-striped caption"
                    :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:[`item.photo`]="{ item }"> 
                        <v-avatar
                            color="#BDBDBD"
                        >
                            <v-img 
                                v-if="item.uploads && item.uploads.length > 0"
                                :src="item.uploads[0] | urlUpload"
                                :lazy-src="item.uploads[0] | urlUpload"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-icon v-else large dark>
                                mdi-account-circle-outline
                            </v-icon>
                        </v-avatar>
                    </template>
                    
                    <template v-slot:[`item.name`]="{ item }">
                        <span :class="$css_report_list">{{ item.name }}</span> 
                    </template>

                    <template v-slot:[`item.profile`]="{ item }">
                        <v-chip
                            :color="item.profile | userProfileColor"
                            :class="$css_report_list + ' ml-0 mr-2 black--text'"
                            label
                            small
                        >
                            {{ item.profile }}
                        </v-chip>
                    </template>


                    <template v-slot:[`item.email`]="{ item }">
                        <span :class="$css_report_list">{{ item.email }}</span> 
                    </template>

                    <template v-slot:[`item.telefone1`]="{ item }">
                        <span v-if="item.telefone1" :class="$css_report_list">{{ item.telefone1 }}</span>
                        <span v-if="item.telefone2" :class="$css_report_list + ' m-2'">{{ item.telefone2 }}</span>
                    </template>

                    

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-card-actions>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>

                             <v-tooltip top v-if="me.profile == 'Master'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="confirmDestroy(item)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </v-card-actions>     
                    </template> 
                </v-data-table>
               
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
//import FormLabelComponent from './FormLabelComponent.vue'; 

export default {

    components: {
        //FormLabelComponent
    },

    props: {
        object: {},

        filters: {},
    }, 

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },  

    watch: {
        options: {
            handler() {
                if(this.options.page >= 1){
                    this.loadItems();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    data () {
        return {
            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'FOTO', value: 'photo' , sortable: false},
                { text: 'NOME', value: 'name' , sortable: false}, 
                { text: 'PERFIL', value: 'profile' , sortable: false},  
                { text: 'EMAIL', value: 'email' , sortable: false},  
                { text: 'CELULAR', value: 'telefone1' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            confirmDelete: {
                id: '',
                status: false,
                name: '',
                text: '',
                destroyfunction: '',
            },
        }    
    },

    methods: {
        loadItems(){
            this.params = this.filters 
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            this.$emit('callLoadItems', this.params)   
        },

        openCloseDialog() {
            this.dialog = !this.dialog
        },

        editItem(id){
            this.$emit('callEditItem', id)
        },

        confirmDestroy(item) {
            this.$snotify.error(`Tem certeza que deseja excluir esse registro?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.destroy(item)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        destroy(item){
            this.$store.dispatch('destroyUser', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Delete')
                            this.$emit('callLoadItems')
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },
    },
}
</script>
