<template>
    <div class="pa-8">
        <main-component :object="item" @callLoadItem="loadItem"></main-component>
    </div>
</template>


<script>
import MainComponent from './MainComponent.vue'

export default {
    created() {
        this.setObject()
    },

    components: {
       MainComponent,
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            this.setObject()
        },
        
        item () {   
            this.setObject()
        }
    },
    
    data () {
        return {
            item: {},
        }
    },

    methods: {
        loadItem(id){
            this.$emit('callLoadItem', id)
        },
    
        setObject(){
            this.item = this.object
        },
        
    },
}
</script>

<style scoped>

</style>