<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-0 ma-0"
                >        
                    <v-expansion-panels class="pa-0 ma-0" flat v-model="panel_search" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header hide-actions>
                                <bread-crumb-component :object="breadcrumbs"></bread-crumb-component>     
                                
                                <span class="text-right">
                                    <v-tooltip left class="text-right">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-if="$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                            <v-btn
                                                v-else
                                                color="pink"
                                                outlined
                                                class="ma-2"
                                            >
                                                <v-icon v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                                FILTROS
                                            </v-btn>
                                        </template>

                                        <span>Filtros</span>
                                    </v-tooltip>   
                                </span> 
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>                                
                                <filter-component @callLoadItems="loadItems"></filter-component>    
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
         
        <div class="mb-8">
            <report-component v-if="listitems" :object="listitems" :permissions="permissions" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" @callShowItem="showItem" ref="report"></report-component>
        </div>

        <div class="mb-6">&nbsp;</div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Condominio
                    <span v-if="item.codigo" class="ml-2">- Código {{item.codigo}} {{item.nome}} </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" @callLoadItem="loadItem"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog_show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="item.codigo">Código {{item.codigo}} - {{item.nome}}</span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="permissions.update == true" icon @click="editItem(item.id)" class="mr-4">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="openCloseDialogShow()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-component :object="item"></show-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Icon new -->
        <button-new-register-component @callNewItem="newItem()"></button-new-register-component>
        <!-- End Icon new -->

    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'
import FilterComponent from './FilterComponent.vue'
import ShowComponent from '../show/ShowComponent.vue'
import ButtonNewRegisterComponent from '@/components/button_newregister/ButtonNewRegisterComponent.vue'

export default {
    components:{
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent,
        FilterComponent,
        ShowComponent,
        ButtonNewRegisterComponent
    },
    
    created() {
        this.loadItems()
        this.setUserPermission()
    },

    computed: {
        listitems() {
            return this.$store.state.condominiums.items    
        },

        me () {
          return this.$store.state.auth.me
        },

        store_condominium() {
            return this.$store.state.condominiums.store    
        },
    },    

    data () {
        return {
            permissions:{
                store: true,
                update: true,
            }, 

            item: {},

            dialog: false,
            dialog_show: false,

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Condomínios',
                    disabled: true,
                    href: 'condominiums',
                },
            ],

            panel_search: [],

            params: {
                id: ''
            },

            excel: {
                fields: {
                   // 'Days'                      : 'days',
                },

                data: [],   
            },

        }
    },


    methods: {
       
        loadItems (params = '') {
            this.$store.commit('STORE_CONDOMINIUM', false)
            
            if(this.$route.params.status && this.$route.params.status != ''){
                this.params.status = this.$route.params.status
                this.$route.params.status = ''
            }
            if(this.$route.params.filter_home && this.$route.params.filter_home != ''){
                this.params.filter_home = this.$route.params.filter_home
                this.$route.params.filter_home = ''
            }
            if(this.$route.query.filter_home && this.$route.query.filter_home != undefined){
                this.params.filter_home = this.$route.query.filter_home
                this.$route.query.filter_home = ''
            }
            // in case is exists params from search is require set null all $route params
            if(params != ''){
                this.params = {} 
                this.params = params
                this.$route.params.renewal_open = ''
                this.$route.params.days = ''
            }
            this.$store.dispatch('loadCondominiums', this.params) 
            this.panel_search = []
            //this.generateRenewalExcel()
            //this.generateRenewalExcel()
        },

        newItem(){
            this.item = {}
            this.dialog = true
        },

        loadItem(id){
            this.$store.dispatch('loadCondominium', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        editItem (id) {   
            this.dialog_show = false 
            this.$store.dispatch('loadCondominium', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog_show = false
                            this.dialog = true
                        })
                                   
        },

        showItem (id) {    
            this.dialog = false
            this.$store.dispatch('loadCondominium', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog = false
                            this.dialog_show = true
                        })
                                   
        },

        openCloseDialog(){
            if(this.item.id != undefined && this.store_condominium == true){
                this.params = {}
                this.params.id = this.item.id
            }else{
                this.$store.commit('STORE_CONDOMINIUM', false)
            }

            this.item = {}
            this.dialog = !this.dialog
            this.dialog_show = false
            if(this.dialog == false){
                this.loadItems(this.params)
            }
        },

        openCloseDialogShow(){
            this.dialog = false
            this.dialog_show = !this.dialog_show
        },

        setUserPermission(){
            this.permissions.store = this.userPermission('Cadastra condomínio')
            this.permissions.update = this.userPermission('Edita condomínio')
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>