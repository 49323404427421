import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'closerequestpassword'

export default {
    actions: {
        closeRequestPassword(context, params){
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${params.item_id}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
    },

    getters: {

    }

}