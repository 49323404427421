<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.creci"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Creci</span>
                        </template>
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.razao_social"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Razāo social</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row> 

            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }
        },
    },

    data () {
        return {
            item: {},

            rules: {
                required: value => !!value || 'Required',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            params_tenants: {
                paginate: 'false'
            }
        }
    },

    methods: {

        setObject(){
            this.item = this.object
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            const data = {}
            data['id'] = item.id
            data['nome'] = item.nome
            data['creci'] = item.creci
            data['razao_social'] = item.razao_social
            
            this.$store.dispatch('updateTenant', data)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            //this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        }) 
                   
        }, 
    },
}
</script>

<style scoped>

</style>