<template>
    <div>
        <v-container>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <show-main-component class="mt-4" :object="object"></show-main-component>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ShowMainComponent from './show_partials/ShowMainComponent.vue'

export default {
    components: {
        ShowMainComponent
    },

    props: {
        object: {
            require: true,
        },
    }, 

    data () {
        return {
            item: {
                photos: []
            }
        }
    },

    methods: {
        
    }

}
</script>