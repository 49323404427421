<template>
    <div>
        <alert-component v-if="owner_property && owner_property.length > 1" :alert="alerts.property_owners"></alert-component>
        <v-form ref="form">
            <v-row dense>
               <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone2"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 2</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.property_owner_id" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.description"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs</span>
                        </template>
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email2"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail 2</span>
                        </template>
                    </v-text-field>
                </v-col> 
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cpf"
                        v-mask="['###.###.###-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CPF</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.rg"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">RG</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cnpj"
                        v-mask="['##.###.###/####-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CNPJ</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.inscricao_estadual"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Inscriçāo estadual</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nacionalidade"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nacionalidade</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cep"
                        @blur="searchCep(item.cep)"
                        v-mask="['#####-###']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CEP</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.endereco"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.bairro"
                        label="Bairro"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.cidade"
                        label="Cidade"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template> 
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.estado"
                        label="Estado"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.numero"
                        label="Número"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"                      
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.complemento"
                        label="Complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col>    
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.profissao"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Profissāo</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.cargo"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cargo / Funçāo</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.renda_mensal"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Renda mensal</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.faturamento_anual"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Faturamento anual</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.faturamento_mensal"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Faturamento mensal</span>
                        </template>
                    </v-currency-field>
                </v-col>
            </v-row>


            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                   <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>
import AlertComponent from '../alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    data () {
        return {
            owner_property: [],

            alerts: {
                property_owners: {
                    show: 'null',
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['O sistema encontrou esse proprietario em outro(s) imovel(eis). Ao alterar os dados desse proprietário outros imóveis serão afetados. Em caso de dúvida voce poderá desvincular esse proprietario desse imóvel e cadastrar um novo.']
                },
            },    

            item: {},

            items: {
               
            },
  
            rules: {
                required: value => !!value || 'Obrigatório',
                min_value:  v => (v && v.length >= 5) || 'O valor precisa ser maior que zero (0)',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            data_entrega: '',
            vencimento_contrato_locacao: '',

            calendar: {
                data_entrega: false,
                vencimento_contrato_locacao: false
            },
        }
    },

    methods: {
        setObject(){
            this.item = this.object
            this.searchClientProperties(this.item.id) 
        },

        searchClientProperties(client_id){
            if(!client_id) return ''
            const params = {}
            params['client_id'] = client_id
            //loadPromisseProperties
            this.$store.dispatch('loadPromisseProperties', params) 
                        .then(response => {
                            this.owner_property = response.data
                        })
        },

        searchCep(value){
            if(!value) return ''
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setAddress(address){
            this.item.endereco = address.logradouro
            this.item.bairro = address.bairro
            this.item.cidade = address.localidade
            this.item.estado = address.uf
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.update(this.item)
            }
        },

        update(item){
            this.$store.dispatch('updateClient', item)
                        .then(response => {
                            //this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
    },
}
</script>