import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'changepassword'


export default {
    actions: {
        updatePassword(context, params){
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.put(`${URL_BASE}${RESOURCE}/${params.item_id}`, params)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
    },

    getters: {

    }

}