<template>
    <div>
        <v-tabs
            v-model="active_tab"
            show-arrows 
        >
            <v-tab href="#tab-1" class="caption"> Principal</v-tab>
            <v-tab href="#tab-2" class="caption"> Dados profissionais </v-tab>
            <v-tab href="#tab-3" class="caption"> Midias sociais </v-tab>
            <v-tab href="#tab-4" class="caption"> Arquivos </v-tab>
        </v-tabs>
    

        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="object" @callLoadItem="loadItem" ref="main"></main-component>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <company-component :object="company" @callLoadItem="loadItem" ref="company"></company-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-3">
                <v-card flat class="pa-8">
                    <social-media-component :object="social_medias" @callLoadItem="loadItem" ref="social_media"></social-media-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-4">
                <v-card flat class="pa-8">
                    <upload-component :object="uploads" @callLoadItem="loadItem" ref="uploads"></upload-component>
                </v-card>    
            </v-tab-item>       

        </v-tabs-items>

    </div>    
</template>


<script>
import CompanyComponent from '@/components/companies/CompanyComponent.vue'
import MainComponent from './MainComponent.vue'
import SocialMediaComponent from '@/components/social_medias/SocialMediaComponent.vue'
import UploadComponent from '@/components/upload/UploadComponent.vue'

export default {
    created () {
        this.setObject()
    },
    
    components: {
       MainComponent,
       CompanyComponent,
       SocialMediaComponent,
       UploadComponent
    },

    watch: {
        object: {
            handler:function() {
                this.setObject()
            },
            deep:true
        },
    },

    props: {
        object: {
            require: false,
        },
    }, 
    
    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    data () {
        return {
            active_tab: 'tab-1',

            item: {},
      
            company: {
                model: 'clients',
                item_id: '',
                report: 'true',
                items: []
            },

            social_medias:{
                model: 'clients',
                item_id: '',
                items: []
            },

            uploads: {
                multiple: false,
                model: 'clients',
                item_id: '',
                items: [],
                extensions: '.pdf,.jpg,.png,.jpeg',
                label_extensions: 'Arquivos permitidos  (pdf, jpeg e png)',
                path: '',
                outros: true,
                descriptions: ['Certidão Casamento','Certidão Nascimento','Comprovante de Renda','Contrato Social','CNPJ','CPF','Extrato bancário','IPTU','Matricula','RG','Outros']
            },
        }
    },

    methods: {
        loadItem(id){
            this.$emit('callLoadItem', this.object.id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        setObject(){
            this.item = this.object
        
            this.company.item_id = this.object.client.id
            this.company.items = this.object.client.companies   
            
            // social_medias
            this.social_medias.item_id = this.object.client.id
            this.social_medias.items = this.object.client.social_medias

            // uploads
            this.uploads.item_id = this.object.client.id
            this.uploads.items = this.object.client.uploads
        },
        
    },
}
</script>

<style scoped>

</style>