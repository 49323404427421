<template>
    <div>
        <v-card
            class="scroll pa-0 rounded-0"
            max-height="250"
            min-height="250"
            outlined
            id="table_border"
        >
            <v-simple-table dense class="rounded-0">
                <thead fixed-header>
                    <tr>
                        <th :class="$css_title_module">
                            Torres
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <v-card
                                class="mx-auto mt-4"
                                outlined
                            >
                                <v-data-table
                                    hide-default-footer
                                    :headers="headers"
                                    :items="object.towers"
                                    :items-per-page="10"
                                    sort-by="id"
                                    class="elevation-1 table-striped"
                                    :footer-props="{
                                    'items-per-page-text':'Itens por página',
                                    'items-per-page-options': [5, 10],
                                    'items-per-page-all-text': '',
                                    'page-text': '{0}-{1} de {2}'
                                }"
                                >
                                    <template v-slot:[`item.nome`]="{ item }">
                                        <span :class="$css_report_list">{{ item.nome }}</span>
                                    </template>

                                    <template v-slot:[`item.andares`]="{ item }">
                                        <span :class="$css_report_list">{{ item.andares }}</span>
                                    </template>

                                    <template v-slot:[`item.andar_unidades`]="{ item }">
                                        <span :class="$css_report_list">{{ item.andar_unidades }}</span>
                                    </template>

                                    <template v-slot:[`item.elevador_social`]="{ item }">
                                        <span :class="$css_report_list">{{ item.elevador_social }}</span>
                                    </template>

                                    <template v-slot:[`item.elevador_servico`]="{ item }">
                                        <span :class="$css_report_list">{{ item.elevador_servico }}</span>
                                    </template>

                                    <template v-slot:[`item.obs`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                text
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                class="caption"
                                                >
                                                Obs
                                                </v-btn>
                                            </template>
                                            <span :class="$css_report_list">{{ item.obs }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true
        }
    },

    data () {
        return {
            headers: [
                { text: 'NOME', align: 'start', value: 'nome', },
                { text: 'ANDARES', value: 'andares' },
                { text: 'UNIDADE ANDAR', value: 'andar_unidades' },
                { text: 'ELEVADOR SOCIAL', value: 'elevador_social' },
                { text: 'ELEVADOR SERVIÇO', value: 'elevador_servico' },
                { text: 'OBS', value: 'obs' }
            ],
        }
    }    
}
</script>