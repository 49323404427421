<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.user_type"
                        :items="items.user_types"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo de usuário</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-if="item.user_type == 'Interno'" 
                        v-model="item.user"
                        :items="users.data"
                        :rules="[rules.required]"
                        item-text="name"
                        item-value="name"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Usuário</span>
                        </template>
                    </v-select>

                    <v-text-field
                        v-else
                        v-model="item.user"
                        outlined
                        dense
                        required
                        :rules="[rules.required]"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Usuário</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_placa"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            v-model="item.data_placa"
                            append-icon="mdi-calendar-range"
                            readonly
                            v-on="on"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Data</span>
                            </template>
                        </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_placa" @input="calendar.data_placa = false"></v-date-picker>

                    </v-menu>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.obs"
                        label="Obs"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs (opcional)</span>
                        </template>
                    </v-text-field>
                </v-col>
               
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click.prevent="validate()"
                        :class="$css_button"
                    >
                        Adcionar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- List  --> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',   
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.data_placa`]="{ item }">
                            <span :class="$css_report_list">{{ item.data_placa }}</span>
                        </template>

                        <template v-slot:[`item.user`]="{ item }">
                            <span :class="$css_report_list">{{ item.user }}</span>
                        </template>

                        <template v-slot:[`item.obs`]="{ item }">
                            <span :class="$css_report_list">{{ item.obs }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { USER_TYPE_ITEMS } from './config_boards/config_boards'

export default {

    created () {
        this.loadUsers()
    },

    components: {
    },

    props: {
        object: {
            require: true,
        },
    },

    watch: {
        data_placa () {
            this.item.data_placa = this.formatDate(this.data_placa)
        },
    },

    data () {
        return {
            item: {
                user_type: 'Interno',    
                model: '',
                item_id: '',
                data_placa: '',
                user: '',
                obs: ''
            },

            items: {
                user_types: USER_TYPE_ITEMS
            },

            rules: {
                required: value => !!value || 'Required.',
            },

            headers: [
                { text: 'DATA PLACA', align: 'start', value: 'data_placa', },
                { text: 'USUÁRIO', value: 'user' },
                { text: 'OBS', value: 'obs' },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            data_placa: '',
            calendar: {
                data_placa: false,
            },
        }
    },

    computed: {
        users() {
            return this.$store.state.users.items    
        },
    },

    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.store()
            }
        },

        store(){
            this.item.model = this.object.model
            this.item.item_id = this.object.item_id
            const action = 'storeBoard'
            this.$store.dispatch(action, this.item)
                        .then(response => {
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.object.item_id)
                            this.clear()
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

       destroy(item){
            this.$store.dispatch('destroyBoard', item.id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Successfully deleted')
                        )
        },

        clear() {
            this.$refs.form.reset()
        },

        loadUsers(){
            this.$store.dispatch('loadUsers')
        },

    }
}        
</script>