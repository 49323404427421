import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'dashboard'


export default {
    state: {
        items: {
            data: []
        }
    },

    mutations: {    
        LOAD_DASHBOARD (state, data) {
            state.items = data
        }
    },

    actions: {
        loadDashboard(context) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}${RESOURCE}`)
                .then( response =>
                        {
                            context.commit('LOAD_DASHBOARD', response.data)
                        }
                )
                //.catch( errors => console.log(errors))
                .catch((error) => {
                    if(error.response && error.response.status === 401) {
                        window.location.href = "login";
                    }

                })
                .finally(() => context.commit('PRELOADER', false))
        },
    },

    getters: {

    }

}