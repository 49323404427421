<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0 d-print-none">
        <v-row class="d-print-none">
            <v-col>
                <v-card
                    outlined
                    class="pa-0 ma-0"
                >        
                    <v-expansion-panels class="pa-0 ma-0" flat v-model="panel_search" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header hide-actions>
                                <bread-crumb-component :object="breadcrumbs"></bread-crumb-component>     
                                
                                <span class="text-right">
                                    <v-tooltip left class="text-right">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-if="$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                            <v-btn
                                                v-else
                                                color="pink"
                                                outlined
                                                class="ma-2"
                                            >
                                                <v-icon v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                                FILTROS
                                            </v-btn>
                                        </template>

                                        <span>Filtros</span>
                                    </v-tooltip>  
                                </span> 
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>                                
                                <filter-component @callLoadItems="loadItems"></filter-component>    
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense class="d-print-none">
            <v-col class="mt-1" cols="12" xs="12" sm="12" md="4" lg="4" xl="4"> 
                <order-component v-if="listitems" :object="items_order" @callLoadItems="loadItems"></order-component>
            </v-col>    
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-btn
                    depressed
                    small
                    v-if="excel.data.length == 0"
                    class="rounded-0 mt-1" 
                    label
                    @click="loadItemsExcel" 
                    color="purple"
                    dark
                >
                    <v-icon left>
                        mdi-list-box-outline
                    </v-icon>
                    Gerar Lista
                </v-btn>
            </v-col>
            <v-col v-if="excel.data.length > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">    
                <v-btn
                    dark
                    block
                    depressed
                    small
                    class="rounded-0 mt-1" 
                    label
                    color="success"
                >
                    <download-excel
                        :data="excel.data"
                        :fields="excel.fields"
                        type="xls"
                        name="imoveis.xls"
                    >
                    <v-icon left>
                        mdi-file-excel-outline
                    </v-icon>
                    Download Excel
                    </download-excel>
                </v-btn>
            </v-col>
            <v-col class="text-right" v-if="excel.data.length > 0 && list_coma != ''" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-btn
                    block
                    depressed
                    dark
                    small
                    v-if="excel.data.length > 0 && list_coma != ''"
                    class="rounded-0 mt-1" 
                    color="pink"
                    label
                    @click="copyListComa(list_coma)"
                >
                    <v-icon left>
                        mdi-comma-box-outline
                    </v-icon>
                    Copiar codigos
                </v-btn>
            </v-col>    
            <v-col v-if="excel.data.length > 0 && list_coma != ''" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">    
                <v-btn
                    block
                    depressed
                    small
                    v-if="excel.data.length > 0 && list_coma != ''"
                    class="rounded-0 mt-1" 
                    color="purple"
                    dark
                    label
                    @click="openCloseDialogPortal()"
                >
                    <v-icon left>
                        mdi-checkbox-outline
                    </v-icon>   
                    Marcar portal
                </v-btn>
            </v-col>
            
        </v-row>
        <v-row dense class="mb-6 d-print-none">
            <v-col class="text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <report-component v-if="listitems" :object="listitems" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" @callShowItem="showItem" @callEditPortal="editPortal" ref="report"></report-component>
            </v-col>
        </v-row>

        <div class="mb-6">&nbsp;</div>

        <!-- Dialog -->
        <v-dialog 
            class="d-print-none"
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
                class="d-print-none"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Imóvel
                    <span v-if="item.codigo" class="ml-2">- Código {{item.codigo}} {{item.nome}} </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" :permissions="permissions" @callLoadItem="loadItem" ref="insert_edit"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog_show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            class="d-print-none" 
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    


            <v-card>
                <v-card-title class="text-h5">
                    <span v-if="item.codigo">Código {{item.codigo}} - {{ item.tipo_imovel }} <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span> </span>
                    
                    <v-spacer></v-spacer>
                    
                    <button-share-component class="mr-4" :object="item"></button-share-component>
                    
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="confirmCopyItem(item.id)" class="mr-4">
                                <v-icon v-bind="attrs" v-on="on">mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                        <span>Gerar cópia</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="loadBranchVuex(item.branch_id)" class="mr-4">
                                <v-icon v-bind="attrs" v-on="on">mdi-printer-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Imprimir</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="editItem(item.id)" class="mr-4">
                                <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>

                    
                    <v-btn icon @click="openCloseDialogShow()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-component :object="item" :permissions="permissions"></show-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Dialog Portal -->
        <v-dialog
            v-model="dialog_portal"
            max-width="600px"
            class="d-print-none"
        >
            <v-card>
                <v-card-title class="text-h6">
                    <span>Marcaçāo do Portal</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog_portal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <portal-register-list-from-property-component :object="list_coma"></portal-register-list-from-property-component>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>                
        </v-dialog>
        <!-- End Dialog Portal -->


        <!-- Icon new -->
        <button-new-register-component @callNewItem="newItem()"></button-new-register-component>
        <!-- End Icon new -->

        <!-- Dialog Print -->
        <v-dialog 
            class="d-print-none"
            v-if="item && item != ''"
            v-model="dialog_print" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                class="d-print-none"
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>   
 

            <v-card>
                <v-card-title class="text-h5 d-print-none">
                    <span v-if="item.codigo">Código {{item.codigo}} - {{ item.tipo_imovel }} <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span> </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click="openCloseDialogPrint()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider class="d-print-none"></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <display-component :object="item" :branch_logo="branch_logo"></display-component> 
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Dialog Print -->
        <v-dialog 
            class="d-print-none"
            v-if="item && item != ''"
            v-model="dialog_edit_portal" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                class="d-print-none"
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>   
 

            <v-card>
                <v-card-title class="text-h5 d-print-none">
                    <span v-if="item.codigo">Código {{item.codigo}} - {{ item.tipo_imovel }} <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span> </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click="openCloseDialogEditPortal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider class="d-print-none"></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <edit-portal-component :object="item"></edit-portal-component>
                        </v-col>  
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

    </div>
</template>

<script>
import { EXCEL_FIELD } from '../config_properties/config_properties.js'

import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'
import FilterComponent from './FilterComponent.vue'
import ShowComponent from '../show/ShowComponent.vue'
import ButtonShareComponent from '@/components/button_share/ButtonShareComponent.vue'
import PortalRegisterListFromPropertyComponent from '@/components/portals/partials/PortalRegisterListFromPropertyComponent.vue'
import OrderComponent from '@/components/order/OrderComponent.vue'
import DisplayComponent from '../print/DisplayComponent.vue'
import ButtonNewRegisterComponent from '@/components/button_newregister/ButtonNewRegisterComponent.vue'
import EditPortalComponent from './EditPortalComponent.vue'

export default {
    components:{
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent,
        FilterComponent,
        ShowComponent,
        ButtonShareComponent,
        PortalRegisterListFromPropertyComponent,
        OrderComponent,
        DisplayComponent,
        ButtonNewRegisterComponent,
        EditPortalComponent
    },
    
    created() {
        this.loadItems()
        this.setUserPermission()
        this.setOrder()
    },

    computed: {
        listitems() {
            return this.$store.state.properties.items    
        },

        favorites () {
            return this.$store.state.favorites.items.data
        },

        me () {
          return this.$store.state.auth.me
        },

        branchvuex () {
          return this.$store.state.branches.branch.data
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },

        store_property() {
            return this.$store.state.properties.store    
        },
        
    },    

    data () {
        return {
            permissions: {
                store_property: true,
                active_property: true,
                update_active_property: true,
                view_owners_others: true,
                colaborator: true
            },

            item: {},

            dialog: false,
            dialog_show: false,
            dialog_portal: false,
            dialog_print: false,
            dialog_edit_portal: false,

            branch_logo: '',

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Imóveis',
                    disabled: true,
                    href: 'properties',
                },
            ],

            items_order: {
                current_order: { 
                    value: 'properties.created_at', 
                    description: 'Data de cadastro',
                    sense: { value: 'asc', description: 'menor -> maior'},
                    params: ''
                },
                sense: [
                    { value: 'asc', description: 'menor -> maior' },
                    { value: 'desc', description: 'maior -> menor' },
                ],
                items:[
                    {
                        value: 'properties.valor_venda', 
                        description: 'Valor Venda'
                    },
                    {
                        value: 'properties.valor_locacao', 
                        description: 'Valor Locaçāo'
                    },
                    {
                        value: 'properties.data_atualizacao', 
                        description: 'Data de atualizaçāo'
                    },
                    {
                        value: 'properties.created_at', 
                        description: 'Data de cadastro'
                    },    
                ] 
            },

            panel_search: [],

            params: {
                id: ''
            },

            excel: {
                fields: EXCEL_FIELD,
                data: [],
                meta: [
                    {
                        key: "charset",
                        value: "utf-8",
                    },
                ],   
            },

            list_coma: '',

        }
    },


    methods: {
       
        loadItems (params = '') {
            if(!this.me.id){
                window.location.href = "login";
            }

            this.$store.commit('STORE_PROPERTIES', false)
            this.excel.data = []
            this.list_coma = ''

            if(this.$route.params.multiple_status && this.$route.params.multiple_status != ''){
                this.params.multiple_status = this.$route.params.multiple_status
                this.$route.params.multiple_status = ''
            }
            if(this.$route.params.status && this.$route.params.status != ''){
                this.params.status = this.$route.params.status
                this.$route.params.status = ''
            }
            if(this.$route.params.multiple_colaborator && this.$route.params.multiple_colaborator.length > 0){
                this.params.multiple_colaborator = this.$route.params.multiple_colaborator
                this.$route.params.multiple_colaborator = ''
            }
            if(this.$route.params.filter_home && this.$route.params.filter_home != ''){
                this.params.filter_home = this.$route.params.filter_home
                this.$route.params.filter_home = ''
            }
            if(this.$route.query.filter_home && this.$route.query.filter_home != undefined){
                this.params.filter_home = this.$route.query.filter_home
                this.$route.query.filter_home = ''
            }
            if(this.$route.params.setor && this.$route.params.setor != ''){
                this.params.setor = this.$route.params.setor
                this.$route.params.setor = ''
            }
            if(this.$route.params.data_cadastro_from && this.$route.params.data_cadastro_from != ''){
                this.params.data_cadastro_from = this.$route.params.data_cadastro_from
                this.$route.params.data_cadastro_from = ''
            }
            if(this.$route.params.data_cadastro_to && this.$route.params.data_cadastro_to != ''){
                this.params.data_cadastro_to = this.$route.params.data_cadastro_to
                this.$route.params.data_cadastro_to = ''
            }

            if(this.tenant[0] && this.tenant[0].json_params && this.tenant[0].json_params.property_order  && this.tenant[0].json_params.sense){
                this.params.order = this.tenant[0].json_params.property_order
                this.params.sense = 'desc'
            }

            // in case is exists params from search is require set null all $route params
            if(params != ''){
                this.params = {} 
                this.params = params
                this.$route.params.renewal_open = ''
                this.$route.params.days = ''
            }

            this.items_order.current_order.params = this.params
            this.$store.dispatch('loadProperties', this.params) 
            
        
            const order = this.items_order.items.filter(model => model.value == this.params.order)
            if(order && order.length > 0){
                this.items_order.current_order.value = order[0].value
                this.items_order.current_order.description = order[0].description
                const sense = this.items_order.sense.filter(model => model.value == this.params.sense)
                if(sense && sense.length > 0){
                    this.items_order.current_order.sense = sense[0]
                }
            }
            this.panel_search = []
        },

        loadItemsExcel (){
            this.excel.data = []
            this.list_coma = ''
            const params = this.params
            params.paginate = 'false'
            params.excel = 'true'
            this.items_order.current_order.params = params
            this.$store.dispatch('loadPropertiesExcel', params) 
                        .then(response => {
                            this.excel.data = response.data
                            this.list_coma = this.makeCodeListComa(this.excel.data)
                        })
        },

        makeCodeListComa(item){
            var list_coma = ''
            item.forEach(model => {
                    list_coma += model.codigo + ',' 
                })
            return list_coma
        },

        newItem(){
            this.item = {}
            this.dialog = true
        },

        loadItem(id){
            this.$store.dispatch('loadProperty', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        editItem (id) {  
            if(!this.me.id){
                window.location.href = "login";
            } 
            this.dialog_show = false 
            this.dialog_print = false
            this.$store.dispatch('loadProperty', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog_show = false
                            this.dialog = true
                            this.checkColaborator()
                        })
                                   
        },

        editPortal (id) {  
            if(!this.me.id){
                window.location.href = "login";
            } 
            this.dialog_show = false 
            this.dialog_print = false
            this.$store.dispatch('loadShowPortalProperty', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog_edit_portal = true
                        })
        },

        confirmCopyItem(id){
            this.$snotify.warning(`O sistema irá copiar esse registro. Deseja continuar?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.copyItem(id)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        copyItem (id){
            const copy_params = {}
            copy_params.property_id = id
            copy_params.copy = 1
            this.$store.dispatch('copyProperty', copy_params) 
                        .then(response => {
                            this.item = response.data
                            this.editItem(this.item.id)
                        })
        },

        checkColaborator(){
            if(this.me.profile != 'Master' && this.me.profile != 'Gerente'){
                if(this.item && this.item.colaborators.length > 0){
                    var access = false
                    this.item.colaborators.forEach(i => {
                        if(i.user_id == this.me.id){
                            access = true
                        }
                    })
                    this.permissions.colaborator = access
                }
            }
        },

        showItem (id) { 
            if(!this.me.id){
                window.location.href = "login";
            }   
            this.dialog = false
            this.dialog_print = false 
            this.$store.dispatch('loadProperty', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog = false
                            this.dialog_show = true
                            this.checkColaborator()
                        })
                                   
        },

        openCloseDialog(){
            if(this.item.id != undefined && this.store_property == true){
                this.params = {}
                this.params.id = this.item.id
            }else{
                this.$store.commit('STORE_PROPERTIES', false)
            }
            this.item = {}
            this.dialog = !this.dialog
            this.dialog_show = false
            this.dialog_portal= false
            this.dialog_edit_portal= false
            if(this.dialog == false){
                this.loadItems(this.params)
            }
            if(this.dialog == false){
                this.$refs.insert_edit.setNull()
            }
        },

        openCloseDialogShow(){
            this.dialog = false
            this.dialog_portal= false
            this.dialog_edit_portal= false
            this.dialog_show = !this.dialog_show
        },

        openCloseDialogPortal(){
            this.dialog = false
            this.dialog_show = false
            this.dialog_edit_portal= false
            this.dialog_portal = !this.dialog_portal
        },
        openCloseDialogEditPortal(){
            this.dialog = false
            this.dialog_show = false
            this.dialog_portal = false
            this.dialog_edit_portal = !this.dialog_edit_portal
        },

        copyListComa(list){
            navigator.clipboard.writeText(list);
            this.$snotify.success(`List de imóveis copiado. Utilize o CTRL + V para colar`, 'Copia')
        },

        setUserPermission(){
            this.permissions.store_property = this.userPermission('Cadastra imóvel')
            this.permissions.active_property = this.userPermission('Ativa imóvel da carteira')
            this.permissions.update_active_property = this.userPermission('Edita imóvel ativo da carteira')
            this.permissions.view_owners_others = this.userPermission('Acesso aos proprietarios de outras carteiras')
        },

        userPermission(description){
            if(this.me.profile != 'Master' && this.me.profile != 'Gerente'){
                var result = this.me.permissions.filter(model => model.description == description)
                if(result && result.length > 0){
                    return true
                }else{
                    return false
                }
            }else{
                return true
            }
        },

        setOrder(){
            if(this.tenant && this.tenant.length > 0 && this.tenant[0].modelo_codigo == 'Numérico'){
                const item = {}
                item.value = 'properties.codigo'
                item.description = 'Código'
                this.items_order.items.push(item)
            }
           
        },

        openCloseDialogPrint(){
            this.dialog = false
            this.dialog_show = false
            this.dialog_print = !this.dialog_print
        },

        loadBranchVuex (id) {    
            this.dialog = false
            this.dialog_portal = false 
            this.dialog_edit_portal = false 
            this.$store.dispatch('loadBranchVuex', id) 
                        .then(response => {
                           this.setLogo(response.data)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        setLogo (branch){
            if(branch.branchimages && branch.branchimages.length > 0){
                const result = branch.branchimages.filter(model => model.description == 'Logo quadrado')
                this.branch_logo = result[0].urlremoto
            }
            this.openCloseDialogPrint()
        }

        //loadBranchVuex
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>