<template>
    <div>
        <v-card
            v-if="object.photos_total"
        >
            <v-carousel 
                :height="img.height"
                v-model="model" 
                :cycle="cycle"  
            >
                <v-carousel-item
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                    v-for="(item, i) in object.photos_total" :key="i"
                >
                    <v-sheet
                        color="#000000"
                        height="100%"
                    >
                    <v-img
                        contain
                        position="center"
                        :max-height="img.height"
                        :min-height="img.height"
                        :src="item.urlremoto"
                        :lazy-src="item.urlremoto"
                    >
                        <div>
                        <v-chip
                            v-if="item.description != null"
                            class="ma-2"
                            color="pink"
                            label
                            text-color="white"
                            >
                            <v-icon left>
                                mdi-label
                            </v-icon>
                            {{ item.description }}
                        </v-chip>  
                        </div>
                        <div class="text--right ">  
                            <v-icon @click="downloadImage(item.urlremoto, item.file)" color="#ffffff">mdi-download</v-icon>                             
                        </div>
                    </v-img>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel> 
        </v-card> 
        <v-card v-else elevation="0">
            <v-avatar
                color="#BDBDBD"
                tile
                :width="img.width"
                :height="img.height"
            >
                <v-icon large dark>
                    mdi-camera
                </v-icon>
            </v-avatar>
        </v-card>
        <div v-if="!$vuetify.breakpoint.mobile">
            <v-list two-line>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-chip
                            label
                            small
                            color="blue lighten-5" 
                        >
                            <span style="font-size:12px">{{ object.codigo }}</span> 
                        </v-chip> 
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ object.endereco }}
                        <span v-if="object.numero">, {{ object.numero }}</span>
                        <span v-if="object.apartamento"> - Apto: {{ object.apartamento }} </span>
                        <span v-if="object.andar">  - {{ object.andar }} andar </span>
                        <span v-if="object.tower && object.tower.nome != ''"> - Torre: {{ object.tower.nome }} </span>
                        <span v-if="object.complemento"> - Compl: {{ object.complemento }} </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-switch
                        v-model="cycle"
                        label="Auto Play"
                        inset
                    ></v-switch>
                </v-list-item-action>
            </v-list-item>
            </v-list>  
        </div> 
    </div>
</template>

<script>
export default {
    created(){
        this.setObject()
    },

    props: {
        object: {
            require: true,
        },
    }, 

    watch: {
        object: {
            handler:function() {
                this.setObject()
            },
            deep:true
        },
    },

    data () {
        return {
            photos: [],    
            model: 0,
            cycle: false,
            item: {},
            img:{
                width: '100%',
                height: '600px'
            }
        }
    },

    methods: {
        openCloseDialogCarousel(){
            this.$emit("callOpenCloseDialogCarousel")
        },

        setObject(){
            this.model = 0
            if(this.$vuetify.breakpoint.mobile == true){
                this.img.height = "230px"
            }
        },

        async downloadImage(urlImage, nameImage) {
            
            let fileImage = urlImage.replace('.webp','.jpg');
            let name = nameImage.replace('.webp','.jpg');

            const blob = await (await fetch(fileImage)).blob();
        
            const url = URL.createObjectURL(blob);
            Object.assign(document.createElement('a'), { href: url, download: name })
                .click();
            URL.revokeObjectURL(url);
        }
    }
}
</script>