<template>
    <div style="width:710px">
        <modelo-1-component v-if="modelo == 1" :branch_logo="branch_logo" :object="object"></modelo-1-component>
        <modelo-2-component v-if="modelo == 2" :branch_logo="branch_logo" :object="object"></modelo-2-component>
        <modelo-3-component v-if="modelo == 3" :branch_logo="branch_logo" :object="object"></modelo-3-component>
        
        <div class="text-center mt-6 d-print-none">
            <v-chip
                label
                :class="$css_button"
                dark
                :color="modelo == 1 ? 'red' : 'grey'"
                @click="changeModelo(1)"
            >
                Modelo 1
            </v-chip>
            <span class="ml-8"></span>
            <v-chip
                label
                :class="$css_button"
                dark
                :color="modelo == 2 ? 'red' : 'grey'"
                @click="changeModelo(2)"
            >
                Modelo 2
            </v-chip>
            <span class="ml-8"></span>
            <v-chip
                label
                :class="$css_button"
                dark
                :color="modelo == 3 ? 'red' : 'grey'"
                @click="changeModelo(3)"
            >
                Modelo 3
            </v-chip>
            <span class="ml-8"></span>
            <v-btn
                color="primary"
                @click="print()"
                :class="$css_button"
            
            >
                <v-icon
                    dark
                    class="mr-1"
                >
                    mdi-printer-outline
                </v-icon>
                Imprimir
            </v-btn>
        </div>

    </div>
</template>

<script>
import Modelo1Component from './Modelo1Component.vue'
import Modelo2Component from './Modelo2Component.vue'
import Modelo3Component from './Modelo3Component.vue'


export default {   
    components: {
        Modelo1Component,
        Modelo2Component,
        Modelo3Component
    },    
    
    props: {
        object: {
            require: true,
        },

        branch_logo: {
            require: false,
        }
    },

    data () {
        return {
            modelo: 1,
            img:{
                height: '600px'
            }
        }   
    },

    methods: {
        print(){
            window.print()
        },

        changeModelo(modelo){
            this.modelo = modelo
        }
    }

}
</script>