<template>
    <div>
        <v-card
            v-if="object.photos_total && object.photos_total.length > 0"
            class="pa-0 rounded-0"
            outlined
            id="table_border"
        >
            <v-slide-group
                class="hidden-sm-and-down"
                v-model="model" 
                show-arrows
                mandatory
                center-active
            >
                <v-slide-item
                    v-for="item in object.photos_total"
                    :key="item.id"
                >
                    <v-avatar
                        class="ma-2"
                        color="#BDBDBD"
                        tile
                        width="267px"
                        height="200px"
                        @click.prevent="openCloseDialogCarousel()"
                    >
                        <v-img 
                            :src="item | urlUpload"
                            :lazy-src="item | urlUpload"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                                >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-avatar>
                </v-slide-item>
            </v-slide-group>

            <v-carousel 
                v-if="$vuetify.breakpoint.mobile"
                v-model="model"
                :cycle="cycle"
                height="200px"
            >
                <v-carousel-item
                    v-for="item in object.photos_total"
                    :key="item.id"
                    :src="item.urlremoto != null ? item.urlremoto : item.urllocal"
                    :lazy-src="item.urlremoto != null ? item.urlremoto : item.urllocal"
                    class="text-right"
                >
                     <v-chip
                        v-if="item.description != null"
                        class="ma-2"
                        color="pink"
                        label
                        text-color="white"
                        >
                        <v-icon left>
                            mdi-label
                        </v-icon>
                        {{ item.description }}
                    </v-chip>
                </v-carousel-item>
            </v-carousel> 
        </v-card>

        <v-dialog
            v-model="dialog_carousel"
            width="800"
            overlay-opacity="0.8"
        >
            <v-card class="d-print-none">
                <div class="text-right">
                    <v-btn icon text class="mr-8" @click="openCloseDialogCarousel()">
                        fechar <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <carousel-component :object="object" @callOpenCloseCarousel="openCloseDialogCarousel"></carousel-component>
            </v-card> 
        </v-dialog>

    </div>
</template>

<script>
import CarouselComponent from '../carousel/CarouselComponent.vue'

export default {
    components: {
        CarouselComponent
    },

    props: {
        object: {
            require: true,
        },
    },
    
    watch: {
        object () {   
            this.setObject()
        },
    },

    data () {
        return {
            cycle: false, 
            model: 0,
            dialog_carousel: false,
        }
    },
    
    methods: {
        openCloseDialogCarousel(){
            this.dialog_carousel = !this.dialog_carousel
        },

        setObject(){
            this.model = 0
        }
    }
}
</script>