export default {
    state: {
        items: {
            data: []
        }
    },

    mutations: {
        ADD_FAVORITE(state, item){
            state.items.data.push(item)
        },

        REMOVE_FAVORITE(state, item){
            state.items.data.splice(item, 1)
        },

        REMOVE_ALL(state, item){
            state.items.data = []
        }
    },
}
