<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.name"
                        :items="items.name"
                        :rules="[rules.required]"
                        item-text="description"
                        item-value="description"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.date_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                readonly
                                v-model="item.date_start"
                                append-icon="mdi-calendar-range"
                                v-on="on"
                                :class="$css_field"
                                :rules="[rules.required]"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Data início</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="date_start" @input="calendar.date_start = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.date_end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                readonly
                                v-model="item.date_end"
                                append-icon="mdi-calendar-range"
                                v-on="on"
                                :class="$css_field"
                                :rules="[rules.required]"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Data fim</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="date_end" @input="calendar.date_end = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="item.time_start"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.time_start"
                                readonly
                                dense
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                :class="$css_field"
                                append-icon="mdi-clock-time-four-outline"
                                :rules="[rules.required]"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Hora início (opcional)</span>
                                </template>
                            </v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menu2"
                            v-model="item.time_start"
                            full-width
                            @click:minute="$refs.menu.save(item.time_start)"
                        ></v-time-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="item.time_end"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.time_end"
                                readonly
                                dense
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                :class="$css_field"
                                append-icon="mdi-clock-time-four-outline"
                                :rules="[rules.required]"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Hora fim (opcional)</span>
                                </template>
                            </v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menu3"
                            v-model="item.time_end"
                            full-width
                            @click:minute="$refs.menu3.save(item.time_end)"
                        ></v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.details"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Detalhes</span>
                        </template>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col class="text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                        
                    >
                        Gravar
                    </v-btn>
                </v-col>
            </v-row>                            
        </v-form>        
    </div>
</template>

<script>
import { NAME_ITEMS } from './config_calendar/config_calendar.js'

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {
            this.setObject()
        },

        date_start () {
            this.item.date_start = this.formatDate(this.date_start)
        },
        date_end () {
            this.item.date_end = this.formatDate(this.date_end)
        },
    },

    data () {
        return {
            time: null,
            
            menu2: false,
            menu3: false,

            item: {},

            items: {
                name: NAME_ITEMS
            },

            rules: {
                required: value => !!value || 'Obrigatório'
            },

            date_start: '',
            date_end: '',

            calendar: {
                date_start: false,
                date_end: false
            },

            params: {
                date_from: '',
                date_to: ''
            }
        }
    },    

    methods: {
        setObject(){
            this.item = this.object
        },

        clear() {
            this.$refs.form.reset()
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        checkColor(name){
            var color = this.items.name.filter(model => model.description == name)
            if(color.length > 0){
                return color[0].color
            }else{
                return 'blue'
            }
            
        },

        storeUpdate(item){
            item.color = this.checkColor(item.name)
             
            const action = item.id ? 'updateEvent' : 'storeEvent'
                this.$store.dispatch(action, item)
                            .then(response => {
                                this.$snotify.success('Registered Successfully!')
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            }) 
        }
    },
}
</script>