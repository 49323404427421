<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.comment"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Comentários</span>
                        </template>
                    </v-textarea>
                </v-col>
           </v-row>
           <v-row dense>       
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.comment`]="{ item }">
                            <span :class="$css_report_list">{{ item.user }} - {{ formatDateTime(item.created_at) }}</span> <br />
                            <span :class="$css_report_list">{{ item.comment }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>  
    </div>
</template>

<script>
    export default {
        components: {
            
        },

        props: {
            object: {
                require: true,
            },
        },

        data () {
            return {
                item: {},

                rules: {
                    required: value => !!value || 'Required.',
                    email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
                },

                headers: [
                    { text: 'HISTORICO', align: 'start', value: 'comment', }                
                ],
            }
        },

        methods:{   

            validate () {
                if (this.$refs.form.validate()) {       
                    this.storeUpdate()
                }
            },
            
            storeUpdate(){
                const item = {}
                if(this.item.id && this.item.id != ''){
                    item.id = this.item.id        
                }else{
                    item.model = this.object.model
                    item.item_id = this.object.item_id    
                }
                item.comment = this.item.comment
            
                const action = item.id ? 'updateComment' : 'storeComment'
                this.$store.dispatch(action, item)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                                this.$snotify.success('Registered Successfully!')
                                this.reset()
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            }) 
            },

            editItem (id) {    
                this.$store.dispatch('loadComment', id) 
                            .then(response => {
                                this.item = response.data
                            })
            },

            reset(){
                this.item = {}
                this.$refs.form.reset()
            },

            destroy(item){
                this.$store.dispatch('destroyComment', item.id)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Successfully deleted')
                            )
            },
            
        }    
    }
</script>