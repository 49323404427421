<template>
    <div>
         <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <property-dashboard-component></property-dashboard-component>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <condominium-dashboard-component></condominium-dashboard-component> 
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
               <builder-dashboard-component></builder-dashboard-component> 
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <fac-dashboard-component></fac-dashboard-component>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
               <user-request-dashboard-component></user-request-dashboard-component>
            </v-col>
        </v-row>

        <v-row>
            <v-col :class="$css_show_description" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">      
                <calendar-component></calendar-component>
            </v-col>    
        </v-row>

    </div>
</template>

<script>
import BuilderDashboardComponent from './partials/BuilderDashboardComponent.vue'
import CondominiumDashboardComponent from './partials/CondominiumDashboardComponent.vue'
import PropertyDashboardComponent from './partials/PropertyDashboardComponent.vue'
import FacDashboardComponent from './partials/FacDashboardComponent.vue'
import CalendarComponent from '../calendars/CalendarComponent.vue'
import UserRequestDashboardComponent from './partials/UserRequestDashboardComponent.vue'

export default {
    components: {
        BuilderDashboardComponent,
        CondominiumDashboardComponent,
        PropertyDashboardComponent,
        FacDashboardComponent,
        CalendarComponent,
        UserRequestDashboardComponent
    },

    created() {
        this.loadDashBoard()
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },    

    data (){
        return {
            url: process.env.VUE_APP_ROOT_API,
            phone: '1130423243',
        }    
    },

    methods: {
        loadDashBoard(){
            this.$store.dispatch('loadDashboard') 
            this.$forceUpdate()  
        },
    }
}
</script>