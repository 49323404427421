<template>
    <div>
        <v-row class="mt-4">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                
                <v-data-table
                    :headers="headers"
                    :items="object.data"
                    :options.sync="options"
                    :server-items-length="object.meta && object.meta.total"
                    class="elevation-1 table-striped caption"
                    hide-default-footer	
                >
                    
                    <template v-slot:[`item.nome`]="{ item }">
                        <span :class="$css_report_list">{{ item.nome }}</span>
                    </template>

                    <template v-slot:[`item.creci`]="{ item }">
                        <span :class="$css_report_list">{{ item.creci }}</span> 
                    </template>

                    <template v-slot:[`item.plano`]="{ item }">
                        <span :class="$css_report_list">Usuarios: {{ item.app_plan.users }}</span> 
                        <span :class="$css_report_list + ' ml-4'">Imóveis: {{ item.app_plan.properties }}</span>
                        <span :class="$css_report_list + ' ml-4'">Condominios: {{ item.app_plan.condominiums }}</span> 
                        <span :class="$css_report_list + ' ml-4'">Construtoras: {{ item.app_plan.builders }}</span> 
                        <span :class="$css_report_list + ' ml-4'">FACS: {{ item.app_plan.customers }}</span> 
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-card-actions>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                        </v-card-actions>     
                    </template> 
                </v-data-table>
               
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
//import FormLabelComponent from './FormLabelComponent.vue'; 

export default {

    components: {
        //FormLabelComponent
    },

    props: {
        object: {},

        filters: {},
    }, 

    watch: {
        options: {
            handler() {
                if(this.options.page >= 1){
                    this.loadItems();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    data () {
        return {
            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'CRECI', value: 'creci' , sortable: false},  
                { text: 'NOME', value: 'nome' , sortable: false}, 
                { text: 'PLANO', value: 'plano' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            confirmDelete: {
                id: '',
                status: false,
                name: '',
                text: '',
                destroyfunction: '',
            },
        }    
    },

    methods: {
        loadItems(){
            /* 
            this.params = this.filters 
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            */
            this.$emit('callLoadItems', this.params)   
        },

        openCloseDialog() {
            this.dialog = !this.dialog
        },

        editItem(id){
            this.$emit('callEditItem', id)
        },
    }
}
</script>
