var moment = require('moment')

import { URL_UPLOAD } from '../config/config.js'
import { PROPERTY_ITEMS } from '../config/property_items/property_items.js'

export default {

    statusOpenClosed (value) {
        if(!value) return ''
        if(value == 'Open') return 'red'
        else if(value == 'Closed') return 'green'
        else return ''
    },

    onlyNumber(str) {
        return str.replace(/[^0-9]+/g, '')
    },

    urlUpload(param){
        return URL_UPLOAD + param
    },

    formatDate (date) {
        if(date != '' && date != null){
            return moment(String(date)).format( 'DD/MM/YYYY')
        }else{
            return ''
        }  
    },

    statusDueDate (item) {
        if(!item.due_date) return ''

        const result = {};
        var date = moment(item.due_date)
        var now = moment()

        const diff = now.diff(date, 'days')

        if (diff > 0) {
            result.color = '#F8BBD0'
            result.description = 'Vencido'
        }else{
            result.color = '#FFE0B2'
            result.description = 'A vencer'
        }
        
        if(item.payment_date != null){
            result.color = '#DCEDC8'
            result.description = 'Pago'
        }
        return result
    },

    formatDateTime (date) {
        if(date != '' && date != null){
            return moment(String(date)).format('DD/MM/YYYY H:mm:ss')
        }else{
            return ''
        }  
    },

    formatDate2 (date) {
        if(date != '' && date != null){
            return moment(String(date)).format('YYYYMMDD')
        }else{
            return ''
        }  
    },

    

    filterPropertyItems(search){
        if(search.model && search.model != '' && search.model != undefined){
            var results = PROPERTY_ITEMS.filter(model => model.model == search.model)
            if(search.tipos && search.tipos != ''){
                var tipos = []
                results.forEach(i => {
                    var item = i.tipos.includes(search.tipos)
                    if(item == true){
                        tipos.push(i)
                    }
                })
                results = tipos 
            }

            
            if(search.regioes && search.regioes != '' && search.regioes != 'Não informado'){
                var regioes = []
                results.forEach(i => {
                    var item = i.regioes.includes(search.regioes)
                    if(item == true){
                        regioes.push(i)
                    }
                })
                results = regioes 
            } 


            // order
             
            var ordem = 'order'
            if(search.order && search.order != ''){
                ordem = search.order
            }

            if(ordem == 'description'){
                return results.sort(function(a, b){
                    if(a.description > b.description) { return 1 }
                    if(a.description < b.description) { return -1 }
                    return 0
                })
            }
    
            if(ordem == 'order'){
                return results.sort((a, b) =>
                    a.order-b.order
                )
            }
            
        }
    },

    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    converteObjectArrayToString(object){
        let string = '';
        object.forEach((value, index) => {
            if(string != ''){
                string += ', '
            }
            string += value.description
        })
        return string
    },

    converteArrayToString(array){
        let string = '';
        array.forEach(value => {

            if(string != ''){
                string += ', '
            }
            string += value
        })
        return string
    },

    converteStringToArray(value, separator){
        if(!value && value != '') return ''
        return value.split(separator)
    },

    maskPhone(phone){
        var mask = phone.length > 10 ? '(##) #####-####' : '(##) ####-####'
        return mask
    },

    userPermission(description){
        if(this.me.profile != 'Master' && this.me.profile != 'Gerente'){
            var result = this.me.permissions.filter(model => model.description == description)
            if(result && result.length > 0){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    },

    setTotal(item){
        let total = 0
        if(item.valor_locacao && item.valor_locacao > 0){
            total += item.valor_locacao
        }
        if(item.valor_condominio && item.valor_condominio > 0){
            total += item.valor_condominio
        }
        if(item.valor_iptu && item.valor_iptu > 0){
            total += item.valor_iptu
        }
        return total
    },

    filterData(item, queryText) {
        return item.all.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
    },

}