<template>
    <div class="pa-4">
        <alert-component :alert="alerts.editblock"></alert-component>
        <v-form ref="form" class="p-2">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-select
                        v-model="item.portal_item_id"
                        :items="portal_items"
                        outlined
                        dense
                        item-text="nome"
                        item-value="id"
                        :rules="[rules.required]"
                        :class="$css_field"
                        @change="loadModels()"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Portal</span>
                        </template>
                    </v-select>
                </v-col>
                
                <v-col v-if="modelos && modelos.length > 0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-select
                        v-model="item.modelo"
                        :items="modelos"
                        outlined
                        dense
                        :rules="[rules.required]"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Modelo do anúncio</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.items"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Lista de códigos imóveis separados por virgula</span>
                        </template>
                    </v-textarea>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                        text
                    >
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form> 
        <v-row v-if="step_update_xml">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                <v-btn
                    dark
                    large
                    block        
                    color="primary"
                    @click="generateXml"
                    :class="$css_button"
                >
                    Gerar/atualizar link XML
                </v-btn>
            </v-col>
        </v-row>

        <v-row dense v-if="step_link_xml">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                    outlined
                    required
                    filled
                    readonly
                    :value="link_xml"
                >
                        <template v-slot:append>
                        <v-btn
                            class="mb-4"
                            color="primary"
                            @click="copyLink"
                        >
                            Copiar Link XML
                        </v-btn>
                    </template>
                </v-text-field>
            </v-col>    
        </v-row> 
    </div>
</template>

<script>
import AlertComponent from '../../alert/AlertComponent.vue'

export default {
    created() {
        this.setObject()
    },

    components: {
        AlertComponent
    },
    
    watch: {
        object: {
            handler:function() {
                this.setObject()
            },
            deep:true
        },
    },

    props: {
        object: {
            require: false,
        },
    },

    computed: {
        portal_items() {
            return this.$store.state.portal_items.items.data    
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },
    },


    data () {
        return {
            step_update_xml: false,
            step_link_xml: false,

            link_xml: '',

            alerts: {
                editblock: {
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['Ao gravar, os imoveis anteriormente marcados serāo substituidos.']
                },
            },

            item: {},
            modelos: [],
            rules: {
                required: value => !!value || 'Obrigatório'
            },

            param_xml: {},

            loading:{
                xml: false
            }
        }
    },
    
    methods: {
        setObject(){
            this.item.items = this.object
            this.loadPortalItems()
        },

        loadPortalItems(){
            this.$store.dispatch('loadPortalItems')
        },

        loadModels(){
            const results = this.portal_items.filter(model => model.id === this.item.portal_item_id) 
            if(results[0].modelos.length > 0){
                this.modelos = results[0].modelos
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.store(this.item)
            }
        },

        store(item){
            this.$store.dispatch('portalliststore', item)
                        .then(response => {
                            this.$snotify.success('Marcaçāo concluida')
                            this.step_update_xml = true
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        }, 

        generateXml(){
            this.$snotify.warning('O sistema irá gerar o link XML, volte aqui mais tarde para copiar o link XML')
            this.loading.xml = true 
            this.param_xml.tenant_id = this.tenant[0].id
            this.param_xml.portal_item_id = this.item.portal_item_id
            this.$store.dispatch('makeXml', this.param_xml)
                        .then(response => {
                            this.$snotify.success('XML gerado com sucesso!')
                            this.link_xml = this.tenant[0].space + '/public/xml/' + this.tenant[0].id + '/' + this.item.portal_item_id + '.xml'
                            this.step_link_xml = true
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        }) 
                        .finally( this.loading.xml = false )
        },

        clear() {
            this.$refs.form.reset()
        },

        copyLink(){
            navigator.clipboard.writeText(this.link_xml)
            this.$snotify.success('Link Copiado. Use o CTRL + V para colar')
        }
    },
}
/* 
items: "ap0001, ap0002"
modelo: "Padrão"
portal_item_id: "05347102-0dab-4fe0-958e-689bbd495f87"
*/

</script>