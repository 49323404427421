<template>
    <div>
        <v-card
            class="scroll pa-0 rounded-0"
            max-height="355"
            min-height="355"
            outlined
            id="table_border"
        >
            <v-simple-table dense class="rounded-0">
                <thead fixed-header>
                    <tr>
                        <th colspan="2" :class="$css_title_module">
                            Locais
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <v-data-table
                        v-if="object"
                        :headers="headers"
                        :items="object"
                        disable-pagination
                        class="elevation-1 table-striped"
                        hide-default-footer
                    >
                        <template v-slot:[`item.distrito`]="{ item }">
                            <span :class="$css_report_list">{{ item.distrito }}, </span>
                            <span :class="$css_report_list">{{ item.cidade }} - {{ item.estado_sigla }}</span>
                        </template>
                    </v-data-table>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true
        }
    },

    data () {
        return{
            headers: [
                { text: 'DISTRITO', align: 'start', value: 'distrito' }
            ],
        }
    }
}
</script>