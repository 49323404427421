<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        Adicionar
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.nome`]="{ item }">
                            <span :class="$css_report_list">{{ item.nome }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroyPosition(item)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row> 
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            item: {},
            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            headers: [
                { text: 'NOME', align: 'start', value: 'nome', },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],
        }
    },

    methods:{   
        validate () {
            if (this.$refs.form.validate()) {       
                this.storeUpdate()
            }
        },
        
        storeUpdate(){
            this.item.condominium_id = this.object.item_id

            const action = 'storePosition'
            this.$store.dispatch(action, this.item)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                            this.$snotify.success('Registered Successfully!')
                            this.reset()
                        })
                        .catch(error => {   
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        }) 
        },

        reset(){
            this.item = {}
            this.$refs.form.reset()
        },

        destroyPosition(item){
            this.$store.dispatch('destroyPosition', item.id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Excluido com sucesso')
                        )
        },

        loadItem(){
            this.$emit('callLoadItem',this.object.item_id)
        }
    }    
}
</script>