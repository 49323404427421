<template>
    <div>
        <!-- Icon new -->
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="$vuetify.breakpoint.mobile" fab dark color="pink" fixed right bottom @click.prevent="newItem()">
                    <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                </v-btn>

                <v-btn v-else class="mb-8 mr-2" dark color="pink" fixed right bottom @click.prevent="newItem()">
                    <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                    Novo
                </v-btn>
            </template>

            <span>Novo</span>
        </v-tooltip>
        <!-- End Icon new -->
    </div>
</template>

<script>
export default {
    data () {
        return {
            
        }
    },

    methods: {
        newItem(){
            this.$emit('callNewItem')
        }
    }

}
</script>