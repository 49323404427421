<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.contact_nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.contact_email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.contact_telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>  
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.contact_telefone2"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 2</span>
                        </template>
                    </v-text-field>      
                </v-col> 
           </v-row>
           <v-row dense>       
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.nome`]="{ item }">
                            <span :class="$css_report_list">{{ item.nome }}</span>
                        </template>

                        <template v-slot:[`item.email`]="{ item }">
                            <span :class="$css_report_list">{{ item.email }}</span>
                        </template>

                        <template v-slot:[`item.telefone`]="{ item }">
                            <span :class="$css_report_list">{{ item.telefone }}</span>
                            <span v-if="item.telefone2" :class="$css_report_list"> / {{ item.telefone2 }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroyContact(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>  
    </div>
</template>

<script>
    export default {
        components: {
            
        },

        props: {
            object: {
                require: true,
            },
        },

        computed: {
            flashmessages() {
                return this.$store.state.flashmessages.items  
            }
        },

        data () {
            return {
                item: {
                    contact_nome: '',
                    contact_email: '',
                    contact_telefone: '',
                    contact_telefone2: ''
                },

                rules: {
                    required: value => !!value || 'Required.',
                    email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
                },

                headers: [
                    { text: 'NOME', align: 'start', value: 'nome', },
                    { text: 'EMAIL', align: 'start', value: 'email', },
                    { text: 'TELEFONE', align: 'start', value: 'telefone', },
                    { text: 'AÇĀO', value: 'actions', sortable: false },
                ],
            }
        },

        methods:{   

            changeStep(step){
                this.$emit('callChangeStep',step)
            },

            validate () {
                if (this.$refs.form.validate()) {       
                    this.storeUpdate()
                }
            },
            
            storeUpdate(){
                const item = {}
                if(this.item.id && this.item.id != ''){
                    item.id = this.item.id        
                }else{
                    item.model = this.object.model
                    item.item_id = this.object.item_id    
                }
                item.nome = this.item.contact_nome
                item.email = this.item.contact_email
                item.telefone = this.item.contact_telefone
                item.telefone2 = this.item.contact_telefone2

                const action = item.id ? 'updateContact' : 'storeContact'
                this.$store.dispatch(action, item)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                                this.$snotify.success('Registered Successfully!')
                                this.reset()
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            }) 
            },

            editItem (id) {    
                this.$store.dispatch('loadContact', id) 
                            .then(response => {
                                this.item.id = response.data.id
                                this.item.contact_nome = response.data.nome
                                this.item.contact_email = response.data.email
                                this.item.contact_telefone = response.data.telefone
                                this.item.contact_telefone2 = response.data.telefone2
                            })
            },

            reset(){
                this.item = {}
                this.$refs.form.reset()
            },

            confirmDestroy(item) {
                this.$snotify.error(`Are you sure to delete this register? ${item.nome}`, 'Confirmation', {
                    id: 1,
                    timeout: 10000,
                    showProgressBar: true,
                    closeOnClick: false,
                    buttons: [
                        {text: 'No', action: null},
                        {text: 'Yes', action: () => { 
                            this.destroyContact(item)
                            //this.$snotify.remove()
                        }},
                    ] 
                })
            },

            destroyContact(item){
                this.$store.dispatch('destroyContact', item.id)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Successfully deleted')
                            )
            },

            actionEmitLinks(action, value){
                this.$emit(action,value)
            },
            
        }    
    }
</script>