import axios from "axios"
import { URL_BASE, NAME_TOKEN } from '../../../config/config'

const RESOURCE = 'users'

export default {
    state: {
        me: {},
        authenticated: false,
        urlBack: 'login',
    },

    mutations: {
        AUTH_USER_OK (state, user) {
            state.authenticated = true,
            state.me = user
        },

        AUTH_USER_LOGOUT (state) {
            state.me = {},
            state.authenticated = false,
            state.urlBack = 'login'
        },

        CHANGE_URL_BACK (state, url) {
            state.urlBack = url
        },

        REFRESH_ME (state, user) {
            state.me = user
        },
    },

    actions: {
        login (context, params) {
            var url = URL_BASE + 'auth'

            context.commit('PRELOADER', true) 
            return axios.post(url, params)
                    .then(response => {
                        context.commit('AUTH_USER_OK', response.data.data.user)
                        const token = response.data.data.token
                        localStorage.setItem(NAME_TOKEN, token)
                        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                    })
                    .finally(() => context.commit('PRELOADER', false))
        },

        checkLogin (context) {
            
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem(NAME_TOKEN)
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                
                context.commit('PRELOADER', true)
                var url = URL_BASE + 'me'
                axios.get(url)
                        .then(response => {
                            context.commit('AUTH_USER_OK', response.data.data)
                        })
                        .catch(error => {
                            localStorage.removeItem(NAME_TOKEN)
                            this.logout()
                            //if(error.response && error.response.status === 401) {
                                //window.location.href = "login";
                            //}
                            reject(error)
                        })
                        .finally(() => context.commit('PRELOADER', false))    
            })
            
        },

        logout (context) {
            //context.commit('PRELOADER', true)
            localStorage.removeItem(NAME_TOKEN)
            context.commit('AUTH_USER_LOGOUT')
            window.location.href = "login";
            //context.commit('PRELOADER', false)
        },

        refreshMe (context, id) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => {
                    context.commit('AUTH_USER_OK', response.data.data) 
                })
                .catch(error => {
                    localStorage.removeItem(NAME_TOKEN)
                    if(error.response && error.response.status === 401) {
                        this.logout()
                    }
                })
                .finally(() => context.commit('PRELOADER', false))
        },

        
    },
}