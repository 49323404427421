import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'portals'

export default {

    actions: {
        storePortal(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.post(`${URL_BASE}${RESOURCE}`, params)
                    .then(response => resolve(response.data))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        portalalldelete(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.get(`${URL_BASE}portalalldelete`, {params})
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        portalallstore(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.get(`${URL_BASE}portalallstore`, {params})
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        portalliststore(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.get(`${URL_BASE}portalliststore`, {params})
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
    },

    getters: {

    }

}