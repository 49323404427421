<template>
    <div>
        <v-container>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <slide-group-component :object="object"></slide-group-component>
                </v-col>    
            </v-row>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <show-main-component class="mt-4" :object="object" :permissions="permissions"></show-main-component>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <map-component class="mt-4" :object="object"></map-component>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SlideGroupComponent from '../../slidegroup/SlideGroupComponent.vue'
import ShowMainComponent from '../show/show_partials/ShowMainComponent.vue'
import MapComponent from '../../maps/MapComponent.vue'

export default {
    components: {
        SlideGroupComponent,
        ShowMainComponent,
        MapComponent
    },

    props: {
        object: {
            require: true,
        },

        permissions: {
            require: true,
        }
    }, 

    data () {
        return {
            item: {
                photos: []
            }
        }
    },

    methods: {
        
    }

}
</script>