import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'makexml'

export default {

    actions: {
        makeXml(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.get(`${URL_BASE}${RESOURCE}`, {params})
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
    },

    getters: {

    }

}