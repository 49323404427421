<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                   <v-select
                        v-if="!item.id"
                        v-model="zonings"
                        :items="items.zonings"
                        :rules="[rules.required]"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Zoneamento</span>
                        </template>
                    </v-select>

                    <v-text-field
                        v-if="item.id"
                        v-model="item.description"
                        outlined
                        dense
                        :class="$css_field"
                        filled
                        readonly
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Zoneamento</span>
                        </template>
                    </v-text-field>

                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.metragem_minima"
                        label="Metragem Mínima"
                        outlined
                        dense
                        type="number"
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Zoneamento</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.metragem_maxima"
                        label="Metragem Máxima"
                        outlined
                        dense
                        type="number"
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Zoneamento</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <div class="text-right mt-0 mb-4">
                    <v-btn
                        text
                        color="error"
                        @click="destroyAll"
                        :class="$css_button"
                    >
                        Excluir todos
                    </v-btn>
                </div>

                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.description`]="{ item }">
                            <span :class="$css_report_list">{{ item.description }}</span>
                        </template>

                        <template v-slot:[`item.metragem_minima`]="{ item }">
                            <span :class="$css_report_list">{{ item.metragem_minima }}</span>
                        </template>

                        <template v-slot:[`item.metragem_maxima`]="{ item }">
                            <span :class="$css_report_list">{{ item.metragem_maxima }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroyItem(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        created(){
            this.setObject()
        },

        props: {
            object: {
                require: true,
            },
        },

        data () {
            return {
                zonings: [],

                item: {},

                items:{
                    zonings: []
                },

                deleteAll: {},

                filterProperty: {
                    model: 'zonings',
                    tipos: '',
                    regioes: '',
                    order: 'description'
                },

                rules: {
                    required: value => !!value || 'Required.',
                },

                headers: [
                    { text: 'DESCRIPTION', align: 'start', value: 'description', },
                    { text: 'METRAGEM MÍNIMA', align: 'start', value: 'metragem_minima', },
                    { text: 'METRAGEM MÁXIMA', align: 'start', value: 'metragem_maxima', },
                    { text: 'AÇĀO', value: 'actions', sortable: false },
                ],
            }
        },

        methods:{   
            setObject() {
                this.item.model = this.object.model
                this.item.item_id = this.object.item_id
                this.setItems()
            },

            setItems() {
                const zonings = []
                const results = this.filterPropertyItems(this.filterProperty)
                results.forEach(i => {
                        zonings.push(i.description)
                })
                zonings[0] = 'Todos'
                this.items.zonings = zonings
            },

            selectAll(items){
                const zonings = [] 
                var result = items.filter(model => model === 'Todos')
                if(result.length == 1){
                    this.items.zonings.forEach(i => {
                        zonings.push(i)
                    })
                }
                return zonings
            },

            validate () {
                if (this.$refs.form.validate()) {       
                    this.storeUpdate()
                }
            },
            
            storeUpdate(){
                var result = this.zonings.filter(model => model === 'Todos')
                const zonings = [] 
                if(result.length == 1){
                    this.items.zonings.forEach(i => {
                        if(i != 'Todos'){
                            zonings.push(i)
                        }
                    })
                    this.item.zonings = zonings
                }else{
                    this.item.zonings = this.zonings
                }
                
                const action = this.item.id ? 'updateZoning' : 'storeZoning'
                this.$store.dispatch(action, this.item)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                                this.$snotify.success('Registered Successfully!')
                                this.reset()
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
            },

            editItem (id) {    
                this.$store.dispatch('loadZoning', id) 
                            .then(response => {
                                this.item = response.data
                            })
            },

            reset(){
                this.item = {}
                this.$refs.form.reset()
            },

            destroyItem(item){
                this.$store.dispatch('destroyZoning', item.id)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Successfully deleted')
                            )
            },

            destroyAll(){
                this.deleteAll.deleteall = 'true'
                this.deleteAll.item_id = this.object.item_id
                this.$store.dispatch('destroyAllZonings', this.deleteAll)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Registro excluido com sucesso!')
                            )
            }
            
        }    
    }
</script>