<template>
    <div>
          <v-form ref="form">
            <v-container>
                <v-row dense>
                    <v-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="param.status"
                            :items="items.status"
                            outlined
                            dense
                            :class="$css_field"
                            :rules="[rules.required]"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Status</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="param.mapa"
                            :items="items.mapa"
                            outlined
                            dense
                            :class="$css_field"
                            clearable
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Mapa</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="param.ilimitado"
                            :items="items.yes_no"
                            outlined
                            dense
                            :class="$css_field"
                            :rules="[rules.required]"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Ilimitado?</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col xs="12" sm="12" md="3" lg="3" xl="3">
                       <v-select
                            v-model="param.status_property"
                            :items="items.status_property"
                            outlined
                            dense
                            :class="$css_field"
                            multiple
                        >
                            <template v-slot:label> 
                                <span :class="$css_field">Status Imóvel</span>
                            </template>

                            <template v-slot:selection="{ item, index }">
                                <v-chip small v-if="index === 0">
                                    <span>{{ item }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    :class="$css_field"
                                >
                                (+ {{ param.status_property.length - 1 }})
                                </span>
                            </template>
                        </v-select>                           
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="param.codigo_complemento"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Codigo Complemento</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="param.alterar_valor"
                            :items="items.yes_no"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label> 
                                <span :class="$css_field">Valor dinâmico?</span>
                            </template>
                        </v-select>
                    </v-col>
                    <!--v-col v-if="param.alterar_valor == 'Sim'" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="param.periodo_valor"
                            type="number"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Número de dias</span>
                            </template>
                        </v-text-field>
                    </v-col-->

                    <v-col v-if="param.alterar_valor == 'Sim'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="param.valor"
                            outlined
                            dense
                            prefix="R$"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Valor</span>
                            </template>
                        </v-currency-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col v-if="anuncio_modelos.pacote1" class="body-2 font-weight-medium" xs="12" sm="12" md="12" lg="12" xl="12">
                        Número de anúncios contratados 
                    </v-col>    
                </v-row>    
                <v-row dense>    
                    <v-col v-if="anuncio_modelos.pacote1" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="param.pacote1"
                            type="number"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">{{ anuncio_modelos.pacote1 }}</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-if="anuncio_modelos.pacote2" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="param.pacote2"
                            type="number"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">{{ anuncio_modelos.pacote2 }}</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-if="anuncio_modelos.pacote3" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="param.pacote3"
                            type="number"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">{{ anuncio_modelos.pacote3 }}</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-if="anuncio_modelos.pacote4" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="param.pacote4"
                            type="number"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">{{ anuncio_modelos.pacote4 }}</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-if="anuncio_modelos.pacote5" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="param.pacote5"
                            type="number"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">{{ anuncio_modelos.pacote5 }}</span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row> 
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                        <v-btn
                            dark
                            color="primary"
                            @click="validate()"
                            :class="$css_button"
                        >
                            Gravar
                        </v-btn>
                    </v-col>    
                </v-row>  
                <v-row v-if="param.id || mass_actions" class="mt-16">
                    <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-btn
                            dark
                            large
                            color="error"
                            @click="deleteAllPortal()"
                            :class="$css_button"
                            block
                            :loading="loading.deleteall"
                        >
                            Excluir a marcaçāo dos imóveis
                        </v-btn>
                    </v-col>
                    <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-btn
                            dark
                            large
                            @click="dialogMassUpdate()"
                            :class="$css_button"
                            block
                        >
                            Marcar os imoveis através de lista
                        </v-btn>
                    </v-col>
                    <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-btn
                            dark
                            large
                            @click="storeAllPortal()"
                            :class="$css_button"
                            block
                            :loading="loading.storeall"
                        >
                            Marcar todos imoveis disponíveis
                        </v-btn>
                    </v-col>
                    <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-btn
                            dark
                            large
                            color="primary"
                            :loading="loading.xml"
                            @click="generateXml()"
                            :class="$css_button"
                            block
                        >
                            Gerar/atualizar link XML
                        </v-btn>
                        <div v-if="param.last_update" class="mt-3 text-center caption">
                            Ultima atualizaçāo:  {{ param.last_update }}
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="object.link_xml && object.link_xml != ''">
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <alert-component  :alert="alerts.portal"></alert-component>
                    </v-col>    
                </v-row>
                <v-row dense v-if="object.link_xml && object.link_xml != ''">
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-text-field
                            outlined
                            required
                            filled
                            readonly
                            :value="object.link_xml"
                        >
                             <template v-slot:append>
                                <v-btn
                                    class="mb-4"
                                    color="primary"
                                    @click="copyLink"
                                >
                                    Copiar Link XML
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-col>    
                </v-row>    
            </v-container>
        </v-form>

        <v-dialog
            v-model="dialog.list"
            max-width="600px"
        >
            <v-card class="d-print-none" >
                <v-card-title class="text-h6">
                    <span>Marcaçāo do Portal - {{ object.nome }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog.list = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <portal-register-list-component :object="object"></portal-register-list-component>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>                
        </v-dialog>

    </div>
</template>

<script>
import { STATUS_ITEMS, YES_NO_ITEMS} from '../config_portals/config_portals.js'
import { MAPA_ITEMS } from '../../properties/config_properties/config_properties.js'
import PortalRegisterListComponent from './PortalRegisterListComponent.vue'
import AlertComponent from '../../alert/AlertComponent.vue'

export default {
    components: {
        PortalRegisterListComponent,
        AlertComponent
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: true,
        },
    },

    computed: {
        tenant() {
            return this.$store.state.tenants.items.data    
        },
    },

    watch: {
        object: {
            handler:function() {
                if(!this.object.id || this.object.id != this.param.id){
                    this.setObject()
                }
            },
            //deep:true
        },
    },

    data () {
        return {
            dialog: {
                list: false
            },

            anuncio_modelos: {
                pacote1: false,
                pacote2: false,
                pacote3: false,
                pacote4: false,
                pacote5: false,
            },

            alerts: {
                portal: {
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['Abaixo segue o link XML. Lembre-se de compatilhar o link com o portal somente quando existir pelo menos um (1) imóvel selecionado com status ativo ou desatualizado. Os portais em geral, salvo algumas exceções, costumam ler o link xml a cada 24 horas. Portanto, consulte os imóveis no portal respeitando esse prazo. O sistema atualizará esse link duas (2) vezes ao dia automaticamente. ']
                }
            },

            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 1) || 'Mininmo de 1 ',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            param: {},
            
            mass_actions: false,

            array_status_property: [],

            items: {
                status: STATUS_ITEMS,
                mapa: MAPA_ITEMS,
                yes_no: YES_NO_ITEMS,
                status_property: ['Ativo','Desatualizado']
            },

            param_xml: {},

            param_delete: {},
            param_allstore: {},

            loading:{
                xml: false,
                deleteall: false,
                storeall: false
            }
            
        }
    },
    
    methods: {
        setObject(){
            this.param = this.object
            this.loading.xml = false
            this.mass_actions = false
        
            this.anuncio_modelos.pacote1 = false
            this.anuncio_modelos.pacote2 = false
            this.anuncio_modelos.pacote3 = false
            this.anuncio_modelos.pacote4 = false
            this.anuncio_modelos.pacote5 = false
   
            if(this.param.id != undefined && this.param.id != '' && this.param.status_property && this.param.status_property != '' && this.param.status_property != undefined){
                this.param.status_property = this.param.status_property.split(',')
            }

            if(Array.isArray(this.param.modelos)){
                if(this.param.modelos.length > 0){
                    if(this.param.modelos[0]){
                        this.anuncio_modelos.pacote1 = this.param.modelos[0] 
                    }
                    if(this.param.modelos[1]){
                        this.anuncio_modelos.pacote2 = this.param.modelos[1] 
                    }
                    if(this.param.modelos[2]){
                        this.anuncio_modelos.pacote3 = this.param.modelos[2] 
                    }
                    if(this.param.modelos[3]){
                        this.anuncio_modelos.pacote4 = this.param.modelos[3] 
                    }
                    if(this.param.modelos[4]){
                        this.anuncio_modelos.pacote5 = this.param.modelos[4] 
                    }
                }
            }else{
                this.anuncio_modelos.pacote1 = 'Anuncios'
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.param)
            }
        },

        storeUpdate(item){
            const action = item.id ? 'updatePortalRule' : 'storePortalRule' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.mass_actions = true
                            this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        }) 
                   
        }, 

        deleteAllPortal(){
            this.loading.deleteall = true

            this.param_delete.portal_item_id = this.object.portal_item_id
            this.$store.dispatch('portalalldelete', this.param_delete)
                        .then(response => {
                            this.$snotify.success('A marcaçāo dos imóveis para este portal foram excluídos')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                        .finally(
                            this.loading.deleteall = false
                        ) 
        },

        storeAllPortal(){
            this.loading.storeall = true

            this.param_allstore.portal_item_id = this.object.portal_item_id
            this.$store.dispatch('portalallstore', this.param_allstore)
                        .then(response => {
                            this.$snotify.success('Todos os imoveis disponíveis foram marcados para esse portal')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                        .finally(
                            this.loading.storeall = false
                        ) 
        },

        dialogMassUpdate(){
            this.dialog.list = true
        },

        generateXml(){
            this.$snotify.warning('O sistema irá gerar o link XML, volte aqui mais tarde para copiar o link XML')
            this.loading.xml = true 
            this.param_xml.tenant_id = this.tenant[0].id
            this.param_xml.portal_item_id = this.param.portal_item_id
            this.$store.dispatch('makeXml', this.param_xml)
                        .then(response => {
                            this.param.last_update = response.data.last_update
                            this.$snotify.success('XML gerado com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        }) 
                        .finally( this.loading.xml = false )
        },

        copyLink(){
            navigator.clipboard.writeText(this.object.link_xml)
            this.$snotify.success('Link Copiado. Use o CTRL + V para colar')
        }
    },
}
</script>