export default {
    state: {
        loading: false,

        items: {
            data: []
        }
    },
    mutations: {
        PRELOADER (state, status) {
            state.loading = status
        }
    },
}