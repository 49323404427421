<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.dormitorios"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Dormitório(s)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.suites"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Suíte(s)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.banheiros"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Banheiro(s)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.vagas"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vaga(s)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.area"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área Ut / Cons</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_condominio"
                        label="Vl Cond."
                        :rules="[rules.required]"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Cond.</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_venda"
                        label="Vl Venda"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    />
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_locacao"
                        label="Vl Aluguel"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    />
                </v-col>
           </v-row>
           <v-row dense>       
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.nome`]="{ item }">
                            <span :class="$css_report_list">{{ item.nome }}</span>
                        </template>

                        <template v-slot:[`item.dormitorios`]="{ item }">
                            <span :class="$css_report_list">{{ item.dormitorios }}</span>
                        </template>

                        <template v-slot:[`item.suites`]="{ item }">
                            <span :class="$css_report_list">{{ item.suites }}</span>
                        </template>

                        <template v-slot:[`item.banheiros`]="{ item }">
                            <span :class="$css_report_list">{{ item.banheiros }}</span>
                        </template>

                        <template v-slot:[`item.vagas`]="{ item }">
                            <span :class="$css_report_list">{{ item.vagas }}</span>
                        </template>

                        <template v-slot:[`item.area`]="{ item }">
                            <span :class="$css_report_list">{{ item.area }}</span>
                        </template>

                        <template v-slot:[`item.valor_venda`]="{ item }">
                            <span :class="$css_report_list">{{ item.valor_venda | toCurrency }}</span>
                        </template>

                        <template v-slot:[`item.valor_locacao`]="{ item }">
                            <span :class="$css_report_list">{{ item.valor_locacao | toCurrency }}</span>
                        </template>
                        
                        <template v-slot:[`item.valor_condominio`]="{ item }">
                            <span :class="$css_report_list">{{ item.valor_condominio | toCurrency }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editAreaPrivativa(item)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-door-closed-lock</v-icon>
                                        </v-btn>
                                    </template> 
                                    <span>Area Privativa</span>
                                    <span v-if="item.private_areas && item.private_areas.length > 0">
                                        <v-divider class="white mb-3"></v-divider>
                                        <div v-for="itm in item.private_areas" :key="itm.id">
                                            {{ itm.description }}
                                        </div>
                                    </span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="copy(item.id)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Copiar</span>
                                </v-tooltip>
                                
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroyPlan(item)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row> 

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="red"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Área Privativa
                    <span class="ml-2"> - Planta {{ property_items.title }} </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <property-item-component :object="property_items" @callLoadItem="loadItem" ref="property_items"></property-item-component>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->


    </div>
</template>

<script>
import PropertyItemComponent from '../property_items/PropertyItemComponent.vue'

export default {
    components: {
        PropertyItemComponent
    },

    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            item: {},

            dialog: false,

            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            headers: [
                { text: 'NOME', align: 'start', value: 'nome', },
                { text: 'DORMITÓRIO', value: 'dormitorios' },
                { text: 'SUÍTES', value: 'suites' },
                { text: 'BANHEIROS', value: 'banheiros' },
                { text: 'VAGAS', value: 'vagas' },
                { text: 'ÁREA', value: 'area' },
                { text: 'VL VENDA', value: 'valor_venda' },
                { text: 'VL LOCAÇÃO', value: 'valor_locacao' },
                { text: 'VL COND.', value: 'valor_condominio' },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            property_items: {
                title: "",
                model: "plans",
                model_property: "private_areas",
                order: "description",
                method: "storePrivateArea",
                item_id: "",
                items: []     
            }
        }
    },

    methods:{   

        changeStep(step){
            this.$emit('callChangeStep',step)
        },

        validate () {
            if (this.$refs.form.validate()) {       
                this.storeUpdate()
            }
        },
        
        storeUpdate(){
            this.item.condominium_id = this.object.item_id

            const action = this.item.id ? 'updatePlan' : 'storePlan'

            this.$store.dispatch(action, this.item)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                            this.$snotify.success('Registered Successfully!')
                            this.reset()
                        })
                        .catch(error => {   
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        }) 
        },

        editItem (id) {    
            this.$store.dispatch('loadPlan', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        copy(id){
            this.$store.dispatch('loadPlan', id)
                        .then(response => {
                            this.item = response.data
                            this.item.id = ''
                            this.item.nome = this.item.nome + ' Copia'
                            this.item.valor_condominio = response.valor_condominio
                        })
                        .catch(error => {
                            this.errors = error.response.data.errors
                        })
        },

        reset(){
            this.item = {}
            this.$refs.form.reset()
        },

        destroyPlan(item){
            this.$store.dispatch('destroyPlan', item.id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Excluido com sucesso')
                        )
        },

        openCloseDialog(){
            this.dialog = !this.dialog
            if(this.dialog == false){
                this.$emit('callLoadItem',this.object.item_id)
            }
        },

        editAreaPrivativa(item){
            this.property_items.title = item.nome
            this.property_items.item_id = item.id
            this.property_items.items = item.private_areas

            this.openCloseDialog()
        },

        loadItem(){
            this.$emit('callLoadItem',this.object.item_id)
        }
    }    
}
</script>