<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        v-model="item.nome"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.cnpj"
                        v-mask="['##.###.###/####-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CNPJ</span>
                        </template>
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.profissao"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Profissão</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.cargo"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cargo</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.renda_mensal"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Renda mensal</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.faturamento_mensal"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Faturamento mensal</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.faturamento_anual"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Faturamento anual</span>
                        </template>
                    </v-currency-field>
                </v-col>        
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.nome`]="{ item }">
                            <div :class="$css_report_list">{{ item.nome }}</div>
                            <div :class="$css_report_list" v-if="item.cnpj">{{ item.cnpj }}</div>
                        </template>

                        <template v-slot:[`item.cargo`]="{ item }">
                            <div :class="$css_report_list" v-if="item.profissao">{{ item.profissao }}</div>
                            <div :class="$css_report_list" v-if="item.cargo">{{ item.cargo }}</div> 
                        </template>

                        <template v-slot:[`item.renda`]="{ item }">
                            <div :class="$css_report_list" v-if="item.renda_mensal">{{ item.renda_mensal | toCurrency  }}</div>
                            <div :class="$css_report_list" v-if="item.faturamento_mensal">{{ item.faturamento_mensal | toCurrency  }}</div>
                            <div :class="$css_report_list" v-if="item.faturamento_anual">{{ item.faturamento_anual | toCurrency  }}</div>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>  
    </div>
</template>

<script>
    export default {
        name: 'CompanyComponent',

        created() {
            this.setObject()
        },

        props: {
            object: {
                require: true,
            },
        },

        watch: {
            object: {
                handler:function() {
                    this.setObject()
                },
                deep:true
            },
        },

        computed: {
            users() {
                return this.$store.state.users.items    
            },
        },

        data () {
            return {
                item: {},
                items: [],

                rules: {
                    required: value => !!value || 'Required.',
                },

                headers: [
                    { text: 'NOME / CNPJ', align: 'start', value: 'nome', },
                    { text: 'PROFISSÃO / CARGO', value: 'cargo', },
                    { text: 'RENDA', value: 'renda', },
                    { text: 'AÇĀO', value: 'actions', sortable: false },
                ],
            }
        },

        methods:{   

            setObject(){
                this.items = this.object.items
            },
          
            validate () {
                if (this.$refs.form.validate()) {       
                    this.storeUpdate()
                }
            },
            
            storeUpdate(){
                this.item.model = this.object.model
                this.item.item_id = this.object.item_id

                if(this.object.report && this.object.report != '' && this.object.report != undefined){
                    this.item.report = this.object.report;
                }
                
                const action = this.item.id ? 'updateCompany' : 'storeCompany'
   
                this.$store.dispatch(action, this.item)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                                this.$snotify.success('Registered Successfully!')
                                this.reset()
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
            },

            editItem (id) {    
                this.$store.dispatch('loadCompany', id) 
                            .then(response => {
                                this.item = response.data
                            })
            },

            reset(){
                this.item = {}
                this.$refs.form.reset()
            },

            destroy(item){
                this.$store.dispatch('destroyCompany', item.id)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Successfully deleted')
                            )
            },

            loadUsers(){
                this.$store.dispatch('loadUsers')
            }
        }    
    }
</script>