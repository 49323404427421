<template>
    <div>
        <alert-component v-if="!item.id && total >= tenant[0].app_plan.condominiums" :alert="alerts.limit"></alert-component>
        <alert-component v-if="alerts.check_new.show == 'true'" :alert="alerts.check_new"></alert-component>
   
        <v-form ref="form" v-if="item.id || total < tenant[0].app_plan.condominiums">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cep"
                        @blur="searchCep(item.cep)"
                        :rules="[rules.required]"
                        v-mask="['#####-###']"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CEP</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.endereco"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.bairro"
                        label="Bairro"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.cidade"
                        label="Cidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template> 
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.estado"
                        label="Estado"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.numero"
                        label="Número"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.complemento"
                        label="Complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col>    
            </v-row>
            <v-row dense v-if="item.id || alerts.check_new.show == 'false'">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.tipo_condominio"
                        :items="items.tipo_condominio"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.estado_condominio"
                        :items="items.estado_condominio"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select
                        v-model="item.builder_id"
                        :items="builders.data"
                        item-text="nome"
                        item-value="id"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Construtora (opcional)</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.finalidade"
                        :items="items.finalidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Finalidade</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.sindico"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Sindico</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.portaria"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Portaria</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.regiao"
                        :items="items.regiao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Região</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" v-if="item.tipo_condominio == 'Apartamento' || item.tipo_condominio == 'Apartamento / Sala Comercial'">
                    <v-select
                        v-model="item.torre_unica"
                        :items="items.torre_unica"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Torre Única?</span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense v-if="total < tenant[0].app_plan.condominiums">
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        v-if="!item.id && alerts.check_new.show != 'false'"
                        dark
                        color="primary"
                        @click="validate2()"
                        :class="$css_button"
                    >
                        Próximo
                    </v-btn>
                    <v-btn
                        v-else
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>
import AlertComponent from '../../alert/AlertComponent.vue'
import { STATUS_ITEMS, TIPO_IMOVEL_CONDOMINIUM_ITEMS, ESTADO_CONDOMINIUM_ITEMS, FINALIDADES_CONDOMINIUM_ITEMS, SIM_NAO_ITEMS, REGIOES_IMOVEL_ITEMS } from '../config_condominiums/config_condominiums.js'  

export default {
    components: {
        AlertComponent
    },

    created() {
        this.setObject()
        this.loadBuilders()
        this.countCondominium()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    computed: {
        builders() {
            return this.$store.state.builders.items    
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },
    }, 

    data () {
        return {
            alerts: {
                check_new: {
                    show: '',
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['O sistema encontrou outro condominio no mesmo endereço. Por favor, verifique o condomínio em pesquisa.']
                },

                limit: {
                    show: '',
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['O limite de cadastro foi atingido. Entre em contato com AYT para migrar de plano ou exclua alguns registros desnecessários.']
                },
            },

            params_builders: {
                paginate: 'false'
            },

            descricao: 'teste',
            check_new: {
                meta: {
                    total: 0
                },
            },

            total: 0,

            item: {
                cep: '',
                endereco: '',
                bairro: '',
                cidade: '',
                estado: '',
                numero: '',
                complemento: ''   
            },

            items: {
                status: STATUS_ITEMS,
                tipo_condominio: TIPO_IMOVEL_CONDOMINIUM_ITEMS,
                estado_condominio: ESTADO_CONDOMINIUM_ITEMS,
                finalidade: FINALIDADES_CONDOMINIUM_ITEMS,
                torre_unica: SIM_NAO_ITEMS,
                regiao: REGIOES_IMOVEL_ITEMS
            },
            
            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            params_branch: {
                paginate: 'false'
            }
        }
    },

    methods: {
        searchCep(value){
            //this.setNull()
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setNull(){
            this.item.endereco = ''
            this.item.bairro = ''
            this.item.cidade = ''
            this.item.estado = ''
        },

        checkNew(){
            //checkNewCondominium
            const params = {}
            params.cep = this.item.cep
            params.endereco = this.item.endereco
            params.numero = this.item.numero
            params.complemento = this.item.complemento
            this.$store.dispatch('checkNewCondominium', params)
                        .then(response => {
                            this.check_new = response   
                            if(this.check_new.meta.total > 0){
                                this.alerts.check_new.show = 'true'
                            }else{
                                this.alerts.check_new.show = 'false'
                            }
                        })
                        .catch(error => {
                            
                        })
        },

        setAddress(address){
            this.item.endereco = address.logradouro
            this.item.bairro = address.bairro
            this.item.cidade = address.localidade
            this.item.estado = address.uf
        },

        setObject(){
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }
        },

        loadBuilders () {
            this.$store.dispatch('loadBuilders', this.params_builders) 
        },

        countCondominium(){
            this.$store.dispatch('countCondominium')
                        .then(response => {
                            this.total = response.meta.total 
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        validate2 () {
            if (this.$refs.form.validate()) {
                this.checkNew()
            }
        },

        storeUpdate(item){
            item.cep = this.onlyNumber(item.cep) 
            const action = item.id ? 'updateCondominium' : 'storeCondominium' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            
                            if(action == 'storeCondominium'){
                                this.$store.commit('STORE_CONDOMINIUM', true)
                            }

                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
        
    },
}
</script>

<style scoped>

</style>