<template>
    <div>
        <v-alert 
            text
            dense
            :color="alert.type | alertColor"          
            :icon="alert.icon"    
        >
            <div v-for="alert in alert.messages" :key="alert">
                <span class="black--text" v-html="alert"></span>
            </div>
        </v-alert>
    </div>
</template>

<script>
export default {
    props: {
        alert: {
            require: true,
        },
    },

    data () {
        return {
            color: ''
        }
    },

    methods: {
    },
    
}
</script>