<template>
    <div>
        <v-container>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="7" lg="7" xl="7">
                    <show-main-component :object="object"></show-main-component>
                    <show-contact-component class="mt-4" :object="object.contacts"></show-contact-component>
                    <show-search-property-component class="mt-4" :object="object.search_properties"></show-search-property-component>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                    <show-zoning-component :object="object.zonings"></show-zoning-component>
                    <show-place-component class="mt-4" :object="object.places"></show-place-component>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <show-text-component :object="object.texts"></show-text-component>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>
import ShowMainComponent from '../show/show_partials/ShowMainComponent.vue'
import ShowSearchPropertyComponent from '../show/show_partials/ShowSearchPropertyComponent.vue'
import ShowContactComponent from '../show/show_partials/ShowContactComponent.vue'
import ShowZoningComponent from '../show/show_partials/ShowZoningComponent.vue'
import ShowPlaceComponent from '../show/show_partials/ShowPlaceComponent.vue'
import ShowTextComponent from '../show/show_partials/ShowTextComponent.vue'

export default {
    components: {
        ShowMainComponent,
        ShowSearchPropertyComponent,
        ShowContactComponent,
        ShowZoningComponent,
        ShowPlaceComponent,
        ShowTextComponent
    },

    props: {
        object: {
            require: true,
        },
    }, 

}
</script>