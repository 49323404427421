<template>
    <div>
        <v-card
            class="mx-auto"
            outlined
        >
        <v-data-table
            :headers="headers"
            :items="object"
            disable-pagination

        >
            <template v-slot:[`item.nome`]="{ item }">
                    <span :class="$css_report_list">{{ item.nome }}</span> 
            </template>

            <template v-slot:[`item.details`]="{ item }">
                <div>
                    <span :class="$css_report_list">Metragem minima: {{ item.metragem_minima }}</span>
                    <span class="ma-2"></span>
                    <span :class="$css_report_list">Metragem maxima: {{ item.metragem_maxima }}</span> 
                    <span class="ma-2"></span>
                    <span :class="$css_report_list">Metragem frente: {{ item.metragem_frente }}</span>
                </div>
                <div>
                    <span :class="$css_report_list">Zoneamento: {{ item.zonings }}</span> 
                </div>
                <div>
                    <span :class="$css_report_list">Locais: {{ item.places }}</span> 
                </div>
            </template>
            
        </v-data-table>
        </v-card>
    </div>
</template>

<script>
export default {

    props: {
        object: {},
    }, 

    data () {
        return {
            headers: [
                { text: 'NOME', value: 'nome' , sortable: false}, 
                { text: 'DETALHES', value: 'details', sortable: false },
            ],
        }
    },                    

    methods: {
        
    },
}
</script>