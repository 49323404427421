<template>
    <div class="pa-2">
        <v-form ref="form" v-if="item.id">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                     <v-text-field
                        dense
                        outlined
                        v-model="item.created_at"
                        label="Data registro"
                        append-icon="mdi-calendar-range"
                        readonly
                        filled
                        class="rounded-0"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                     <v-text-field
                        dense
                        outlined
                        v-model="item.updated_at"
                        label="Data atualizaçāo"
                        append-icon="mdi-calendar-range"
                        readonly
                        filled
                        class="rounded-0"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="item.subject"
                        label="Assunto"
                        readonly
                        filled
                        class="rounded-0"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="details_object"
                        :label="label_object"
                        readonly
                        filled
                        class="rounded-0"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="item.name_user_request"
                        label="Solicitante"
                        readonly
                        filled
                        class="rounded-0"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense v-if="item.name_user_manager && item.name_user_manager != ''">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="item.name_user_manager"
                        label="Gerencia"
                        readonly
                        filled
                        class="rounded-0"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense v-if="item.subject != 'Solicitação de ativação'">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.comment"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                        filled
                        readonly
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Detalhes</span>
                        </template>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>  
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        dark
                        color="purple"
                        @click="editItem(item.object[0].model,item.object[0].id)"
                        :class="$css_button"
                    >
                        Editar {{ label_object }}
                    </v-btn>
                    <span class="ma-3"></span>    
                    <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>   
            </v-row> 
        </v-form>

        <!-- Dialog -->
        <v-dialog 
            class="d-print-none"
            v-if="item_object && item_object != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
                class="d-print-none"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="item.object[0].model == 'properties'">
                        Imóvel
                    </span>
                    <span v-if="item.object[0].model == 'condominiums'">
                        Condominio
                    </span>

                    <span v-if="item_object.codigo" class="ml-2">- Código {{item_object.codigo}} </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <edit-property v-if="item.object[0].model == 'properties'" :object="item_object" :permissions="permissions" @callLoadItem="loadItem" ref="insert_edit"></edit-property>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->


    </div>
</template>

<script>
import { STATUS_ITEMS } from '../configs_user_requests/configs_user_requests.js'
import EditProperty from '../../properties/partials/InsertEditComponent.vue'

export default {
    components: {
        EditProperty
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    data () {
        return {
            dialog: false,
            item: {
                comment: '',
                comments: []
            },
            item_object: '',
            label_object: '',
            details_object: '',
            items: {
               status: STATUS_ITEMS,
            },
            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },
            permissions: {
                store_property: true,
                active_property: true,
                update_active_property: true,
                view_owners_others: true,
                colaborator: true
            },
        }
    },

    methods: {
        setObject(){
            this.item = this.object
            this.item.created_at = this.formatDateTime(this.item.created_at)
            this.item.updated_at = this.formatDateTime(this.item.updated_at)

            let details = ''
            if(this.item.object[0].model == 'properties'){
                this.label_object = 'Imóvel'
                if(this.item.object[0].codigo){
                    details += 'CODIGO ' + this.item.object[0].codigo + ' - '
                }
                if(this.item.object[0].endereco){
                    details += this.item.object[0].endereco
                }
                if(this.item.object[0].numero && this.item.object[0].numero > 0){
                    details += ', ' + this.item.object[0].numero
                }
                if(this.item.object[0].apartamento && this.item.object[0].apartamento > 0){
                    details += ' APTO ' + this.item.object[0].apartamento
                }
                if(this.item.object[0].complemento && this.item.object[0].complemento > 0){
                    details += ' COMPL ' + this.item.object[0].complemento
                }
            }

            if(this.item.comments && this.item.comments.length > 0){
                this.item.comment = this.item.comments[0].comment
            }

            this.details_object = details
        },
        
        validate () {
            if (this.$refs.form.validate()) {
                this.update(this.item)
            }
        },

        update(item){
            this.$store.dispatch('updateUserRequest', item)
                        .then(response => {
                            this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        
        }, 

        editItem (model, id) {
            let vuefunction = ''
            if(model == 'properties'){
                vuefunction = 'loadProperty'
            }
            if(model == 'condominiums'){
                vuefunction = 'loadCondominium'
            }

            this.$store.dispatch(vuefunction, id) 
                        .then(response => {
                            this.item_object = response.data
                            this.dialog = true
                        })
                                   
        },

        loadItem(id){
            console.log('loadItem ' + id)
        },

        openCloseDialog(){
            this.dialog = !this.dialog
        }
        
    },
}
</script>

<style scoped>

</style>