<template>
    <div>
        <v-bottom-sheet v-model="order_screen">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    small
                    v-bind="attrs" 
                    v-on="on"
                    class="rounded-0" 
                    label
                    outlined
                >
                    <v-icon left>
                        mdi-wifi-arrow-up-down
                    </v-icon>
                    <span style="font-size: 11px">{{ object.current_order.description }} - {{ object.current_order.sense.description }}</span>
                </v-btn>    

            </template>
            <v-list class="pa-4">
                <v-form ref="form">
                <v-subheader>Ordem</v-subheader>

                <v-radio-group v-model="radioGroup">
                    <v-radio
                        v-for="n in object.items"
                        :key="n.value"
                        :label="`${n.description}`"
                        :value="n.value"
                    ></v-radio>
                </v-radio-group>

                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-select
                            v-model="sense"
                            :items="items.sense"
                            :rules="[rules.required]"
                            item-text="description"
                            item-value="value"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Sentido</span>
                            </template>
                        </v-select>
                    </v-col>    
                </v-row>
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-btn
                            dark
                            color="primary"
                            @click="validate()"
                            :class="$css_button"
                        >
                            Ordenar Lista
                        </v-btn>
                    </v-col>    
                </v-row>
                </v-form>
            </v-list>
        </v-bottom-sheet>
    </div>
</template>

<script>
export default {

    components: {
    },

    props: {
        object: {
            require: true,
        }
    }, 

    data () {
        return {
            order_screen: false,
            radioGroup: this.object.current_order.value,
            sense: this.object.current_order.sense.value,
            items: {
                sense: this.object.sense
            },
            rules: {
                required: value => !!value || 'Obrigatório',
            },
            
        }    
    },

    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.setOrder()
            }
        },

        setOrder(){     
            const params = this.object.current_order.params
            params.order = this.radioGroup
            params.sense = this.sense
            this.$emit('callLoadItems',params)
            this.order_screen = false
        },
    },
}
</script>

