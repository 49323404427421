<template>
    <div>
        <list-component v-if="me.profile == 'Master'" ref="list_component"></list-component>
    </div>

</template>

<script>
import ListComponent from './partials/ListComponent.vue'

export default {
    components: {
        ListComponent,
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    data () {
        return {
          
        }
    },

    methods: {

    },
}
</script>

<style scoped>

</style>