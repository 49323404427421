<template>
    <div>
        <v-card
            class="scroll pa-0 rounded-0"
            max-height="345"
            min-height="345"
            outlined
            id="table_border"
        >
            <v-simple-table dense class="rounded-0">
                <thead fixed-header>
                    <tr>
                        <th colspan="2" :class="$css_title_module">
                            Zoneamentos
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <v-data-table
                        v-if="object"
                        :headers="headers"
                        :items="object"
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:[`item.description`]="{ item }">
                            <span :class="$css_report_list">{{ item.description }}</span>
                        </template>

                        <template v-slot:[`item.metragem_minima`]="{ item }">
                            <span :class="$css_report_list">{{ item.metragem_minima }} m²</span>
                        </template>

                        <template v-slot:[`item.metragem_maxima`]="{ item }">
                            <span :class="$css_report_list">{{ item.metragem_maxima }} m²</span>
                        </template>
                        
                    </v-data-table>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true
        }
    },

    data () {
        return{
            headers: [
                    { text: 'DESCRIPTION', align: 'start', value: 'description' },
                    { text: 'METRAGEM MÍNIMA', align: 'start', value: 'metragem_minima' },
                    { text: 'METRAGEM MÁXIMA', align: 'start', value: 'metragem_maxima' }
                ],
        }
    }
}
</script>