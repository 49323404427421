<template>
    <div>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <vue-dropzone class="dropzone" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-queue-complete="uploadSuccess"  v-on:vdropzone-success="changeOrder"></vue-dropzone>
            </v-col>
        </v-row>
        
        <div v-if="object.items || object.items_condominium">
        <v-row class="ma-2">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">    
                <v-btn
                    dark
                    color="primary"
                    @click="confirmMassDelete()"
                    :class="$css_button"    
                >
                    Excluir todas as fotos
                </v-btn>       
            </v-col>
        </v-row>        
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                 <v-expansion-panels  class="mb-3" v-if="object.items_condominium && object.items_condominium.length > 0">
                    <v-expansion-panel elevation="1">
                        <v-expansion-panel-header>
                        <span class="red--text ma-0">Fotos do condominio - Para alterar as fotos do condominio e necessário entrar no cadastro do condominio.</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col v-for="photo in object.items_condominium" :key="photo.id" cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
                                    <v-avatar
                                        class="ma-2"
                                        color="#BDBDBD"
                                        width="150px"
                                        height="120px"
                                        tile
                                    >
                                        <v-img 
                                            :src="photo | urlUpload"
                                            :lazy-src="photo | urlUpload"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                                >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-avatar>
                                </v-col>
                            </v-row>       
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        class="elevation-1 table-striped"
                    >
                        <template v-slot:[`item.photo`]="{ item }"> 
                            <v-avatar
                                class="ma-2"
                                color="#BDBDBD"
                                width="100px"
                                height="80px"
                                tile
                            >
                                <v-img 
                                    :src="item | urlUpload"
                                    :lazy-src="item | urlUpload"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-avatar>
                        </template>

                        <template v-slot:[`item.order`]="{ item }">
                            <v-text-field
                                class="mt-6"
                                v-model="item.order"
                                outlined
                                dense
                                :class="$css_field"
                            >
                            </v-text-field>
                        </template>

                        <template v-slot:[`item.description`]="{ item }">
                            <v-text-field
                                class="mt-6"
                                v-model="item.description"
                                outlined
                                dense
                                :class="$css_field"
                            >
                            </v-text-field> 
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left v-if="item.file">
                                    <template v-slot:activator="{ on, attrs }">  
                                        <v-icon @click="downloadImage(item.urlremoto, item.file)" v-bind="attrs" v-on="on">mdi-download</v-icon>                       
                                    </template>
                                    <span>DOWNLOAD</span>
                                </v-tooltip>   

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="fileDestroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>EXCLUIR</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="save(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-content-save</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>SALVAR</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog.massdelete"
            transition="dialog-top-transition"
            max-width="600"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Confirmação
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmMassDelete()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row dense>
                        <v-col cols="12">
                            Tem certeza que deseja excluir todas as fotos?  

                            <div class="text-center">
                                <v-radio-group
                                    v-model="confirm_delete"
                                    row
                                >
                                    <v-radio
                                        label="Sim"
                                        value="Sim"
                                    ></v-radio>

                                    <v-radio
                                        label="Não"
                                        value="Não"
                                    ></v-radio>
                                </v-radio-group>   
                            </div>                         
                        </v-col>
                    </v-row>
                    <v-row v-if="confirm_delete == 'Sim'">
                        <v-col cols="12" class="text-right">
                            <v-btn
                                dark
                                color="primary"
                                @click="massDestroyUpload()"
                                :class="$css_button"
                            >
                                Excluir
                            </v-btn>  
                        </v-col>
                    </v-row>     
                </v-container> 
            </v-card>    
        </v-dialog>    

        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    components: {
        vueDropzone: vue2Dropzone
    },

    props: {
        object: {
            require: true,
        },
    },

    data (){
        return {
            loader: [],

            countFiles: 0,
            
            confirm_delete: 'Não',

            params_delete: {},

            dialog: {
                massdelete: false
            },

            params_setphoto: {},

            editItem:{},

            dropzoneOptions: {
                timeout: 0,
                uploadMultiple: true,
                autoProcessQueue: true,
                parallelUploads: 1,
                maxFiles: 30,

                url: process.env.VUE_APP_ROOT_API + '/api/uploads',
                params: {
                    model: this.object.model,
                    item_id: this.object.item_id,
                    resize: 'true',
                    order: this.object.items.length + 1,
                    watermark: this.object.watermark
                    //order: 2
                },
                addRemoveLinks: true,
                dictDefaultMessage: "Clique aqui para inserir as imagens. Extensões válidas (JPG, PNG e WebP). <br /><br /><strong style='color: #CC0000;'> É sugerido enviar 20 fotos por vez. <br /> Para facilitar o envio de fotos diminua o tamanho das fotos antes de enviar. </strong>",
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                maxFilesize: 40,
                resizeHeight: 1500,
                resizeQuality: 1,
                removeFile:true,
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN_AUTH') },
            },

            headers: [
                { text: 'FOTO', value: 'photo' , sortable: false},
                { text: 'ORDEM', value: 'order' , sortable: true}, 
                { text: 'DESCRIÇĀO', value: 'description' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],
        }
    },

    methods: {
        changeOrder(){
            this.dropzoneOptions.params.order += 1
            console.log(this.dropzoneOptions.params.order)
        },

        confirmMassDelete(){
            this.dialog.massdelete = !this.dialog.massdelete
        },

        destroy() {
             this.$refs.myVueDropzone.removeAllFiles(true)
        },

        massDestroyUpload(){
            this.params_delete.model = this.object.model
            this.params_delete.item_id = this.object.item_id
            this.$store.dispatch('massDestroyUpload', this.params_delete)
                        .then(response => {
                            this.setPhotoFinal()
                            this.$snotify.success('Fotos excluídas com sucesso!')
                            this.confirmMassDelete()
                            this.confirm_delete = 'Não'
                            this.$emit('callLoadItem', this.object.item_id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
            
        },

        fileDestroy(file){
            this.$store.dispatch('destroyUpload', file.id)
                        .then(response => {
                            this.setPhotoFinal()
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Registro excluído com sucesso')
                        )
        },

        uploadSuccess(){
            this.setPhotoFinal()
            this.$snotify.success('Fotos enviadas com sucesso!')
            this.destroy()
            this.$emit('callLoadItem',this.object.item_id)
        },

        setPhotoFinal(){
            this.params_setphoto.model = this.object.model
            this.params_setphoto.item_id = this.object.item_id
            console.log(this.params_setphoto)    
             
            this.$store.dispatch('setPhotoFinal', this.params_setphoto)
                        .then(response => {
                            console.log('set photo success')
                        })
                        .catch(error => {
                            console.log('Erro set fotos')
                        })
            
        },

        async downloadImage(urlImage, nameImage) {   
            let fileImage = urlImage.replace('.webp','.jpg');
            let name = nameImage.replace('.webp','.jpg');

            const blob = await (await fetch(fileImage)).blob();
        
            const url = URL.createObjectURL(blob);
            Object.assign(document.createElement('a'), { href: url, download: name })
                .click();
            URL.revokeObjectURL(url);
        }
    }
}
</script>

<style scoped>
    .dropzone .dz-preview.lp-preview {
        width: 150px;
    }
    .dropzone.hzScroll {
        width: 100%;
        height: 230px;
        overflow: auto;
        white-space: nowrap;
        background-color: blanchedalmond;
    }
</style>