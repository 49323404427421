<template>
    <div>
        <v-card
            class="pa-0 rounded-0"
            outlined
            id="table_border"
        >
            <v-simple-table dense class="rounded-0">
                <thead fixed-header>
                    <tr>
                        <th colspan="2" :class="$css_title_module">
                            Informações
                        </th>
                    </tr>
                </thead>
                <tbody>
                     <tr>
                        <td colspan="2" :class="$css_show_description" id="td_border">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_show_title">Status: </span>  
                                        <v-chip
                                            class="ml-2"
                                            :color="object.status | statusProperty"
                                            label
                                            small
                                            :class="$css_report_list"
                                        >
                                            {{ object.status }}
                                        </v-chip> 
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_show_title">Dt.Cadastro: </span>     
                                        <span :class="$css_show_description">{{ object.created_at }}</span>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_show_title">Dt.Atualizaçāo: </span>  
                                        <span :class="$css_show_description">{{ object.data_atualizacao }}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Titulo: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.titulo_publicitario }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Endereço: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        {{ object.endereco }}
                                        <span v-if="object.numero">, {{ object.numero }} </span>
                                        <span v-if="object.apartamento"> - Apto: {{ object.apartamento }} </span>
                                        <span v-if="object.andar">  - {{ object.andar }} andar </span>
                                        <span v-if="object.tower_id && object.tower_id != null && object.tower && object.tower.nome != ''"> - Torre: {{ object.tower.nome }} </span>
                                        <span v-if="object.complemento"> - Compl: {{ object.complemento }} </span>
                                        <span v-if="object.position_id && object.position_id != null && object.position.nome != ''"> - Posiçāo: {{ object.position.nome }} </span>
                                        <span v-if="object.bairro">  - {{ object.bairro }} </span>
                                        <span v-if="object.cidade">  - {{ object.cidade }} </span>
                                        <span v-if="object.estado">  - {{ object.estado }} </span>
                                        <span v-if="object.cep">  - {{ object.cep | VMask('#####-###') }} </span>
                                        <span v-if="object.condominio_avulso"> ( {{ object.condominio_avulso }} )</span>
                                        <span v-if="object.zona"> - Zona: {{ object.zona }} </span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr v-if="object.condominium && object.condominium.nome != ''">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Condomínio: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.condominium.nome }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.builder && object.builder.nome != ''">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Construtora: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.builder.nome }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr v-if="object.perto_de && object.perto_de.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Perto de: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.perto_de | convertArrayToString }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col v-if="object.valor_venda && object.valor_venda > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vl venda: </span>
                                        <span :class="$css_show_description">{{ object.valor_venda | toCurrency }}</span>
                                    </v-col>
                                    <v-col  v-if="object.valor_locacao && object.valor_locacao > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vl locaçāo: </span>
                                        <span :class="$css_show_description">{{ object.valor_locacao | toCurrency }}</span>
                                    </v-col>
                                    <v-col  v-if="object.valor_condominio && object.valor_condominio > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vl Cond: </span>
                                        <span :class="$css_show_description">{{ object.valor_condominio | toCurrency }}</span>
                                    </v-col>
                                    <v-col  v-if="object.valor_temporada && object.valor_temporada > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vl temp: </span>
                                        <span :class="$css_show_description">{{ object.valor_temporada | toCurrency }}</span>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vl IPTU:</span>
                                        <span v-if="object.valor_iptu && object.valor_iptu > 0" :class="$css_show_description">{{ object.valor_iptu | toCurrency }}</span>
                                        <span v-if="object.isento_iptu && object.isento_iptu == 'Sim'" :class="$css_show_description"> Isento </span>
                                    </v-col>
                                    <v-col  v-if="object.valor_seguro_fianca && object.valor_seguro_fianca > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vl Seg. fiança: </span>
                                        <span :class="$css_show_description">{{ object.valor_seguro_fianca | toCurrency }}</span>
                                    </v-col>
                                    <v-col  v-if="object.valor_seguro_incendio && object.valor_seguro_incendio > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vl Seg. incendio: </span>
                                        <span :class="$css_show_description">{{ object.valor_seguro_incendio | toCurrency }}</span>
                                    </v-col>
                                    <v-col  v-if="object.valor_avaliacao && object.valor_avaliacao > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vl avaliaçāo: </span>
                                        <span :class="$css_show_description">{{ object.valor_avaliacao | toCurrency }}</span>
                                    </v-col>
                                    <v-col   v-if="(object.finalidade == 'Residencial' || object.finalidade == 'Residencial / Comercial') && object.tipo_imovel != 'Área' && object.tipo_imovel != 'Terreno'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Dorm(s): </span>
                                        <span :class="$css_show_description">{{ object.dormitorios }}</span>
                                    </v-col>
                                    <v-col v-if="(object.finalidade == 'Residencial' || object.finalidade == 'Residencial / Comercial') && object.tipo_imovel != 'Área' && object.tipo_imovel != 'Terreno'"  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Suite(s): </span>
                                        <span :class="$css_show_description">{{ object.suites }}</span>
                                    </v-col>
                                    <v-col v-if="object.tipo_imovel != 'Área' && object.tipo_imovel != 'Terreno'"  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Banheiro(s): </span>
                                        <span :class="$css_show_description">{{ object.banheiros }}</span>
                                    </v-col>
                                    <v-col  v-if="object.tipo_imovel != 'Área' && object.tipo_imovel != 'Terreno'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vaga(s): </span>
                                        <span :class="$css_show_description">{{ object.vagas }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Ocupado? </span>
                                        <span :class="$css_show_description">{{ object.imovel_ocupado }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">{{ object.tipo_imovel | labelArea('area') }}: </span>
                                        <span :class="$css_show_description">{{ object.area }} m²</span>
                                    </v-col>
                                    <v-col  v-if="object.tipo_imovel != 'Área' && object.tipo_imovel != 'Terreno'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">{{ object.tipo_imovel | labelArea('area_total') }}: </span>
                                        <span :class="$css_show_description">{{ object.area_total }} m²</span>
                                    </v-col>
                                    <v-col  v-if="object.tipo_imovel == 'Terreno'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Á. const. terr.: </span>
                                        <span :class="$css_show_description">{{ object.area_construida_terreno }} m²</span>
                                    </v-col>
                                    <v-col  v-if="object.frente && object.frente > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Frente: </span>
                                        <span :class="$css_show_description">{{ object.frente }} m²</span>
                                    </v-col>
                                    <v-col  v-if="object.fundo && object.fundo > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Fundo: </span>
                                        <span :class="$css_show_description">{{ object.fundo }} m²</span>
                                    </v-col>
                                    <v-col  v-if="object.lado_direito && object.lado_direito > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">L. direito: </span>
                                        <span :class="$css_show_description">{{ object.lado_direito }} m²</span>
                                    </v-col>
                                    <v-col  v-if="object.lado_esquerdo && object.lado_esquerdo > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">L. esquerdo: </span>
                                        <span :class="$css_show_description">{{ object.lado_esquerdo }} m²</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Setor: </span>
                                        <span :class="$css_show_description">{{ object.setor }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Finalidade: </span>
                                        <span :class="$css_show_description">{{ object.finalidade }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Est. imóvel: </span>
                                        <span :class="$css_show_description">{{ object.estado_imovel }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Regiāo: </span>
                                        <span :class="$css_show_description">{{ object.regiao }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Para renda? </span>
                                        <span :class="$css_show_description">{{ object.para_renda }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Aceita permuta? </span>
                                        <span :class="$css_show_description">{{ object.aceita_permuta }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Aceita financiamento? </span>
                                        <span :class="$css_show_description">{{ object.aceita_financiamento }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Recaptaçāo? </span>
                                        <span :class="$css_show_description">{{ object.imovel_recaptado }}</span>
                                    </v-col>
                                    <!--v-col v-if="object.branch && object.branch.descricao != ''" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Unidade: </span>
                                        <span :class="$css_show_description">{{ object.branch.descricao }}</span>
                                    </v-col-->
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Topografia: </span>
                                        <span :class="$css_show_description">{{ object.topografia }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Ano construçāo: </span>
                                        <span :class="$css_show_description">{{ object.ano_construcao }}</span>
                                    </v-col>
                                    <v-col v-if="object.data_entrega != ''" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Data entrega: </span>
                                        <span :class="$css_show_description">{{ object.data_entrega }}</span>
                                    </v-col>
                                    <v-col v-if="object.vencimento_contrato_locacao && object.vencimento_contrato_locacao != ''" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vc. contrato: </span>
                                        <span :class="$css_show_description">{{ object.vencimento_contrato_locacao }}</span>
                                    </v-col>
                                    <v-col v-if="object.comissao_primeiro_aluguel && object.comissao_primeiro_aluguel > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Com. Locaçāo: </span>
                                        <span :class="$css_show_description">{{ object.comissao_primeiro_aluguel }} %</span>
                                    </v-col>
                                    <v-col v-if="object.taxa_adm && object.taxa_adm > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Taxa ADM: </span>
                                        <span :class="$css_show_description">{{ object.taxa_adm }} %</span>
                                    </v-col>
                                    <v-col v-if="object.estado_contrato_locacao && object.estado_contrato_locacao != ''" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <span :class="$css_show_title">Contrato locaçāo: </span>
                                        <span :class="$css_show_description">{{ object.estado_contrato_locacao }}</span>
                                    </v-col>
                                    <v-col  cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <span :class="$css_show_title">Documentaçāo: </span>
                                        <span :class="$css_show_description">{{ object.documentacao }}</span>
                                    </v-col>
                                    <v-col v-if="object.garantia_locacao && object.garantia_locacao.length > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_show_title">Garantias aceitas: </span>
                                        <span :class="$css_show_description">{{ object.garantia_locacao | convertArrayToString }}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td> 
                    </tr>
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Chaves: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        {{ object.chaves }}
                                        <span v-if="object.obs_chaves"> ({{ object.obs_chaves }})</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <v-row dense>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                <span :class="$css_show_title">Proprietários: </span>
                                            </v-col>   
                                        </v-row>
                                        <div v-if="permissions.view_owners_others || permissions.colaborator">
                                            <v-card
                                                class="mx-auto ma-2"
                                                outlined
                                                elevation="0"
                                            >
                                                <v-data-table
                                                    dense
                                                    :headers="headers.owners"
                                                    :items="object.property_owners"
                                                    disable-pagination
                                                    hide-default-footer
                                                    disable-sort
                                                    class="table-striped"
                                                >
                                                    <template v-slot:[`item.nome`]="{ item }">
                                                        <span :class="$css_report_list">{{ item.nome }}</span>
                                                    </template>

                                                    <template v-slot:[`item.telefone`]="{ item }">
                                                        <phone-component v-if="item.telefone" :object="item.telefone"></phone-component> 
                                                        <phone-component v-if="item.telefone2" :object="item.telefone2"></phone-component>
                                                    </template>

                                                    <template v-slot:[`item.email`]="{ item }">
                                                        <span :class="$css_report_list">{{ item.email }}</span>
                                                    </template>

                                                    <template v-slot:[`item.obs`]="{ item }">
                                                        <span :class="$css_report_list">{{ item.obs }}</span>
                                                    </template>
                                                </v-data-table>
                                            </v-card>    

                                            <!--v-row dense v-for="prop in object.property_owners" :key="prop.client_id">
                                                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                                <span :class="$css_show_title">{{ prop.nome }}</span> 
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <span v-if="prop.telefone">
                                                        <phone-component :object="prop.telefone"></phone-component>    
                                                        <phone-component  v-if="prop.telefone2" :object="prop.telefone2"></phone-component>  
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <span v-if="prop.email"> 
                                                        E-mail: 
                                                        <a :href="`mailto:${prop.email}`">
                                                            {{ prop.email }} 
                                                        </a>
                                                        <span v-if="prop.email2">
                                                            <a :href="`mailto:${prop.email2}`">
                                                            {{ prop.email2 }} 
                                                            </a>
                                                        </span>
                                                    </span>
                                                    <span v-if="prop.description"> 
                                                        Obs: {{ prop.description }} 
                                                    </span>
                                                </v-col>   
                                            </v-row-->
                                        </div>
                                        <div v-else>
                                            <v-row dense>
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <span :class="$css_show_title">Acesso Restrito</span>
                                                </v-col>   
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr v-if="object.observacoes && object.observacoes != ''">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Observações: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.observacoes }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>    
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Colaboradores: </span>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <span :class="$css_show_description">
                                            <span v-for="item in object.colaborators" :key="item.id">
                                                <div class="mr-4">
                                                    <span>{{ item.name }}</span>
                                                    <span v-if="item.description"> ({{ item.description }})</span>
                                                </div>
                                            </span>
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr v-if="object.boards && object.boards.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Placa: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <span class="mr-4">{{ object.boards[0].data_placa }}</span>
                                        <span class="mr-4">{{ object.boards[0].user }}</span>
                                        <span class="mr-4">{{ object.boards[0].obs }}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Endereço Publicidade: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                         {{ object.endereco_publicidade }}
                                        <span v-if="object.numero_publicidade">, {{ object.numero_publicidade }} </span>
                                        <span v-if="object.cep_publicidade">  - {{ object.cep_publicidade | VMask('#####-###') }} </span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.videos && object.videos.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Videos: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <v-chip
                                            small
                                            class="ma-1 caption"
                                            v-for="inf in object.videos" :key="inf.id"    
                                        >
                                            <a v-if="inf.type == 'YouTube'" :href="inf.description | urlYoutube" download target="_blank">  
                                                {{ inf.type }} - {{ inf.description }}                        
                                            </a>
                                            <a v-else :href="inf.description" download target="_blank">  
                                                {{ inf.type }} - {{ inf.description }}                        
                                            </a>
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.private_areas && object.private_areas.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Área privativa </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <v-chip
                                            small
                                            class="ma-1 caption"
                                            v-for="inf in object.private_areas" :key="inf.id"    
                                        >
                                            {{ inf.description }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.infrastructures && object.infrastructures.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Infra</span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <v-chip
                                            small
                                            class="ma-1 caption"
                                            v-for="inf in object.infrastructures" :key="inf.id"    
                                        >
                                            {{ inf.description }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.recreations && object.recreations.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Lazer</span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <v-chip
                                            small
                                            class="ma-1 caption"
                                            v-for="inf in object.recreations" :key="inf.id"    
                                        >
                                            {{ inf.description }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.places && object.places.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Locais</span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <v-chip
                                            small
                                            class="ma-1 caption"
                                            v-for="lc in object.places" :key="lc.id"    
                                        >
                                            {{ lc.distrito }} - {{ lc.cidade }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Texto Publicitário: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.texto_publicitario }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.portals && object.portals.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Portais</span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <v-chip
                                            small
                                            class="ma-1 caption"
                                            v-for="inf in object.portals" :key="inf.id"    
                                        >
                                        <span v-if="inf.portal_item">{{ inf.portal_item.nome }}</span>
                                        <span class="ml-2" v-if="inf.modelo"> ({{ inf.modelo }})</span>
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.files && object.files.length > 0">
                        <td colspan="2" :class="$css_show_description">

                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Arquivos - Descrição
                                    </th>
                                    <th class="text-left">
                                        Ação
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in object.files"
                                        :key="item.description"
                                    >
                                        <td>{{ item.description }}</td>
                                        <td> 
                                            <v-tooltip left v-if="item.file">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <a :href="item | urlUpload" target="_blank">  
                                                        <v-icon v-bind="attrs" v-on="on">mdi-download</v-icon>                           
                                                    </a>
                                                </template>
                                                <span>DOWNLOAD</span>
                                            </v-tooltip>   
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
import PhoneComponent from '@/components/phone/PhoneComponent.vue'
export default {
    components: {
        PhoneComponent
    },

    props: {
        object: {
            require: true
        },

        permissions: {
            require: true
        }
    },

    data () {
        return {
            headers: {
                owners: [
                    { text: 'NOME', align: 'start', value: 'nome', },
                    { text: 'TELEFONE', value: 'telefone' },
                    { text: 'EMAIL', value: 'email' },
                    { text: 'OBS', value: 'description' },
                ]
            },
            style: {
                details: 'mt-0 ml-0 mb-0 mr-6 pa-0 pb-1'
            }
        }
    }    
}
</script>