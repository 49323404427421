<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.andares"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">N. de andares</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.andar_unidades"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Unidade por andar</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.elevador_social"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Elevador Social</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.elevador_servico"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Elevador de serviço</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.obs"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs</span>
                        </template>
                    </v-text-field>
                </v-col>
           </v-row>
           <v-row dense>       
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.nome`]="{ item }">
                            <span :class="$css_report_list">{{ item.nome }}</span>
                        </template>

                        <template v-slot:[`item.andares`]="{ item }">
                            <span :class="$css_report_list">{{ item.andares }}</span>
                        </template>

                        <template v-slot:[`item.andar_unidades`]="{ item }">
                            <span :class="$css_report_list">{{ item.andar_unidades }}</span>
                        </template>

                        <template v-slot:[`item.elevador_social`]="{ item }">
                            <span :class="$css_report_list">{{ item.elevador_social }}</span>
                        </template>

                        <template v-slot:[`item.elevador_servico`]="{ item }">
                            <span :class="$css_report_list">{{ item.elevador_servico }}</span>
                        </template>

                        <template v-slot:[`item.obs`]="{ item }">
                             <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    text
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    class="caption"
                                    >
                                    Obs
                                    </v-btn>
                                </template>
                                <span :class="$css_report_list">{{ item.obs }}</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="copy(item.id)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Copiar</span>
                                </v-tooltip>
                                
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroyPlan(item)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row> 
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            item: {},
            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            headers: [
                { text: 'NOME', align: 'start', value: 'nome', },
                { text: 'ANDARES', value: 'andares' },
                { text: 'UNIDADE ANDAR', value: 'andar_unidades' },
                { text: 'ELEVADOR SOCIAL', value: 'elevador_social' },
                { text: 'ELEVADOR SERVIÇO', value: 'elevador_servico' },
                { text: 'OBS', value: 'obs' },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],
        }
    },

    methods:{   
        validate () {
            if (this.$refs.form.validate()) {       
                this.storeUpdate()
            }
        },
        
        storeUpdate(){
            this.item.condominium_id = this.object.item_id

            const action = this.item.id ? 'updateTower' : 'storeTower'
            this.$store.dispatch(action, this.item)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                            this.$snotify.success('Registered Successfully!')
                            this.reset()
                        })
                        .catch(error => {   
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        }) 
        },

        editItem (id) {    
            this.$store.dispatch('loadTower', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        copy(id){
            this.$store.dispatch('loadTower', id)
                        .then(response => {
                            this.item = response.data
                            this.item.id = ''
                            this.item.nome = this.item.nome + ' Copia'
                            this.item.valor_condominio = response.valor_condominio
                        })
                        .catch(error => {
                            this.errors = error.response.data.errors
                        })
        },

        reset(){
            this.item = {}
            this.$refs.form.reset()
        },

        destroyPlan(item){
            this.$store.dispatch('destroyTower', item.id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Excluido com sucesso')
                        )
        },

        loadItem(){
            this.$emit('callLoadItem',this.object.item_id)
        }
    }    
}
</script>