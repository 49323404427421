<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.created_at"
                        outlined
                        dense
                        append-icon="mdi-help-circle-outline"
                        :class="$css_field"
                        filled
                        readonly
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Data Cadastro</span>
                        </template>

                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-tooltip top max-width="650px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="item.updated_at"
                                outlined
                                dense
                                append-icon="mdi-help-circle-outline"
                                v-on="on"
                                :class="$css_field"
                                filled
                                readonly
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Última atualizaçāo</span>
                                </template>

                            </v-text-field>
                        </template>
                        <span>
                            A data de atualizaçāo está relacionada ao último histórico inserido.
                        </span>
                    </v-tooltip>
                </v-col>    
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>    
            </v-row>
        </v-form>    

        <v-form ref="form2">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span :class="$css_field">Caso tenha interesse em agendar um retorno que será exibido na agenda do sistema preencha as informações abaixo:</span>
                </v-col>    
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-menu
                        v-model="calendar.date_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                readonly
                                v-model="event.date_start"
                                append-icon="mdi-calendar-range"
                                v-on="on"
                                :class="$css_field"
                                :rules="[rules.required]"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Data Retorno (opcional)</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="date_start" @input="calendar.date_start = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="event.time_start"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="event.time_start"
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :class="$css_field"
                            append-icon="mdi-clock-time-four-outline"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Hora retorno (opcional)</span>
                            </template>
                        </v-text-field>
                        </template>
                        <v-time-picker
                            v-if="menu2"
                            v-model="event.time_start"
                            full-width
                            @click:minute="$refs.menu.save(event.time_start)"
                        ></v-time-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate2()"
                        :class="$css_button"
                    >
                        Gravar Evento na agenda
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>
               
    </div>
</template>

<script>
import { STATUS_ITEMS } from '../configs_fac/configs_fac.js'

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    computed: {
        me () {
          return this.$store.state.auth.me
        }
    },

    watch: {
        object () {  
            this.setObject() 
        },

        date_start () {
            this.event.date_start = this.formatDate(this.date_start)
        },
    }, 

    data () {
        return {
            time: null,
            menu2: false,

            item: {},

            event: {
                model: 'facs',
                item_id: this.object.id,
                name: 'FAC',
                details: '',
                user_id: '',
                color: 'purple',
                timed: 'true',
                date_start: '',
                date_end: ''
            },

            items: {
                status: STATUS_ITEMS,
            },

            date_start: '',

            calendar: {
                date_start: false,
            },

            rules: {
                required: value => !!value || 'Obrigatório'
            },
        }
    },

    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.update(this.item)
            }
        },

        validate2 () {
            if (this.$refs.form2.validate()) {
                this.eventStore(this.event)
            }
        },

        update(item){
            const action = 'updateFac' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 

        eventStore(event){
            event.user_id = this.me.id
            event.details = this.item.client.nome + ' (' + this.item.finalidade + ')'

            if(this.item.client.telefone != ''){
                event.details += ' - Tel:' + this.item.client.telefone
            }
            if(this.item.client.email != ''){
                event.details += ' - E-mail:' + this.item.client.email
            }
 
            this.$store.dispatch('storeEvent', event)
                        .then(response => {
                            var result = response
                            this.$snotify.success('Evento cadastrado com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        setObject(){
            this.item = this.object
            this.item.data_cadastro = this.formatDateTime(this.item.created_at)
            this.item.ultima_atualizacao = this.formatDateTime(this.item.updated_at)  
        },
        
    },
}
</script>

<style scoped>

</style>