<template>
    <div>
        <main-component :object="item" @callLoadItem="loadItem"></main-component>       
    </div>    
</template>


<script>
import MainComponent from './MainComponent.vue'

export default {
    created() {
        this.setObject()
    },

    components: {
        MainComponent 
    },

    props: {
        object: {
            require: false,
        }
    }, 

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    watch: {
        object () {   
            this.setObject()
        },
    },

    data () {
        return {
            item: {},
        }
    },

    methods: {
        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setObject(){
            if(this.object){
                this.item = this.object
            }
        },
        
    },
}
</script>

<style scoped>

</style>