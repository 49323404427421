<template>
    <div>
        <alert-component :alert="alerts.expire_message"></alert-component>
    </div>
</template>

<script>
import AlertComponent from '../alert/AlertComponent.vue' 

export default {
    components: {
        AlertComponent
    },

    data () {
        return {
            alerts: {
                expire_message: {
                    show: 'null',
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['O sistema detectou mensalidade vencida. Entre em "Empresa" e efetue o pagamento do boleto.<br /> Veja o video tutorial <a href="https://www.youtube.com/watch?v=uNNe43C9U3o" target="_blank" style="cursor:pointer;">Video Tutorial</a>']
                },
            }    
        }
    },
}
</script>