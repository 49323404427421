<template>
    <div> 
        <v-form ref="form">
            <v-row dense>
                <v-col v-if="item.id" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        v-model="item.complete_address"
                        outlined
                        dense
                        :class="$css_field"
                        filled
                        readonly
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>    
            </v-row>
            <v-row dense>
                <v-col v-if="item.id" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        v-model="item.client.nome"
                        outlined
                        dense
                        :class="$css_field"
                        filled
                        readonly
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Proponente</span>
                        </template>
                    </v-text-field>
                </v-col>    
            </v-row>    
            <v-row dense>
                <v-col v-if="item.id" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-currency-field
                        v-model="item.valor_proposta"
                        outlined
                        dense
                        :rules="[rules.required]"
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Proposta</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col v-if="item.setor == 'Locação'" cols="12" xs="12" sm="12"  md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.garantia"
                        :items="items.garantias"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Garantia</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.proposta"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Proposta</span>
                        </template>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>
import { STATUS_ITEMS } from '../config_proposals/config_proposals.js' 
import { GARANTIA_LOCACAO_ITEMS } from '../../properties/config_properties/config_properties.js'

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    }, 

    data () {
        return {
            item: {},

            items: {
                status: STATUS_ITEMS,
                garantias: GARANTIA_LOCACAO_ITEMS
            },

            params: {
                paginate: 10,
            },
            
            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },
        }
    },

    methods: {

        setObject(){
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            const action = item.id ? 'updateProposal' : 'storeProposal' 
            const params = {}
            params.id = item.id
            params.status = item.status
            params.valor_proposta = item.valor_proposta
            params.garantia = item.garantia
            params.proposta = item.proposta      

            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            if(action == 'storeBuilder'){
                                this.$store.commit('STORE_BUILDER', true)
                            }
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
        
    },
}
</script>

<style scoped>

</style>