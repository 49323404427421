<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense> 
                <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="params.name"
                        label="Name"
                        outlined
                        dense
                        class="rounded-0 caption"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="params.profile"
                        :items="items.profiles"
                        label="Profile"
                        outlined
                        dense
                        class="rounded-0 caption"    
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="blue darken-1"
                        text  
                        @click.prevent="clear" 
                        class="rounded-0 caption"
                    >
                        Clean
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click.prevent="submit"
                        class="rounded-0 caption"
                    >
                    search 
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>        
    </div>
</template>

<script>
import { PROFILES } from '../config_users/config_users.js'

export default {
    data() {
        return {
            params: {
                id: ''
            },
            
            items: {
                profiles: PROFILES
            },
        }
    },

    methods: {

        clear() {
            this.$refs.form.reset()
        },

        submit(){
            this.params.id = ''
            this.$emit('callLoadItems',this.params)
        },
    }
}
</script>