<template>
    <div v-if="me.profile == 'Master' || me.profile == 'Gerente'">
      <portal-component :object="portals"></portal-component>
    </div>  
</template>


<script>
import PortalComponent from '@/components/portals/PortalComponent.vue'

export default {
    name: 'EditPortalComponent',
    
    created() {
        this.setObject()
    },

    components: {
        PortalComponent,
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object: {
            handler:function() {
                this.setObject()
            },
            deep:true
        },
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },
    },

    data () {
        return {
            item: {},

            portals: {
                item_id: '',
                items: []
            },
        }
    },

    methods: {
        setObject(){
            this.$forceUpdate()
            this.item = this.object

            // portals
            this.portals.item_id = this.object.id
            this.portals.items = this.object.portals            
        },        
    },
}
</script>

<style scoped>

</style>