import { render, staticRenderFns } from "./TechnicalInformationComponent.vue?vue&type=template&id=c206a43c&scoped=true&"
import script from "./TechnicalInformationComponent.vue?vue&type=script&lang=js&"
export * from "./TechnicalInformationComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c206a43c",
  null
  
)

export default component.exports