<template>
    <div>
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="64">
                    <v-toolbar
                    flat
                    >
                    <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                    >
                        Hoje
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                    >
                        <v-icon small>
                        mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                    >
                        <v-icon small>
                        mdi-chevron-right
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu
                        bottom
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            outlined
                            color="grey darken-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right>
                            mdi-menu-down
                            </v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                        <v-list-item @click="type = 'day'">
                            <v-list-item-title>Dia</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                            <v-list-item-title>Semana</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                            <v-list-item-title>Mês</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 dias</v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                    </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                    <v-calendar
                        locale="pt-br"
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="events"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="addEvent"
                        @change="updateRange"
                    ></v-calendar>
                    <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                    >
                        <v-card
                            color="grey lighten-4"
                            min-width="350px"
                            max-width="350px"
                            flat
                        >
                            <v-system-bar
                                dark
                                :color="selectedEvent.color"
                                height="10px"
                            >    
                            </v-system-bar> 

                            <v-card class="d-print-none" >
                                <v-card-title class="body-1">
                                    <span>{{ selectedEvent.name }}</span>
                                    <v-spacer></v-spacer>
                                    
                                    <v-btn icon>
                                        <v-icon @click="loadItem(selectedEvent.id)">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon @click="destroyItem(selectedEvent.id)">mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="selectedOpen = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card-title>

                                <v-divider></v-divider>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" class="body-2">
                                            <div v-if="selectedEvent.eventable_type == 'App\\Models\\Fac' && selectedEvent.name == 'FAC'">
                                                 Acessar a FAC 
                                                 <router-link :to="{ 
                                                    name: 'admin.facs.index', 
                                                    params: {
                                                        id: selectedEvent.eventable_id                               
                                                    } 
                                                }">
                                                    <v-icon small>mdi-open-in-new</v-icon>
                                                 </router-link>
                                            </div>
                                            <div class="mt-2">
                                                {{ selectedEvent.details }}
                                            </div>    
                                            
                                        </v-col>
                                    </v-row>     
                                </v-container>
                            </v-card>  
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>

        <!-- Dialog Event -->
        <v-dialog 
            v-model="dialog.event" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span>Evento</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog('event')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="event" @callUpdateRange="updateRange"></insert-edit-component>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog Portal -->

    </div>  
</template>

<script>
import InsertEditComponent from './InsertEditComponent.vue'

export default {
    components: {
        InsertEditComponent,
    },

    data () {
        return {
            params: {
                paginate: 'false'
            },

            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Mes',
                week: 'Semana',
                day: 'Dia',
                '4day': '4 Dias',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],

            dialog: {
                event: false
            },

            event: {
                model: 'users',
                item_id: '',
                user_id: '',
                name: '',
                date_start: '',
                time_start: '',
                color: 'blue',
                timed: 'true',
                details: '',
            }
        }
    },

    mounted () {
        this.$refs.calendar.checkChange()
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },   

    methods: {        
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },

        getEventColor (event) {
            return event.color
        },

        setToday () {
            this.focus = ''
        },

        prev () {
            this.$refs.calendar.prev()
        },

        next () {
            this.$refs.calendar.next()
        },

        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        updateRange ({ start, end }) {  
            const min = new Date(`${start.date}T00:00:00`)
            const max = new Date(`${end.date}T23:59:59`)

            this.params.date_from = max.getFullYear() + '/' + (max.getMonth() + 1) + '/' + '1'
            this.params.date_to = max.getFullYear() + '/' + (max.getMonth() + 1) + '/' + '31'
            this.params.user_id = this.me.id
            
            this.loadItems(this.params)
        },

        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },


        loadItems (params) {
            this.events = []  
            this.$store.dispatch('loadPromisseEvents', params) 
                        .then(response => {
                            this.events = response.data
                        })
        },

        addEvent (date) {
            this.event.id = ''
            this.event.model = 'users'
            this.event.eventable_type = ''
            this.event.eventable_id = ''
            this.event.name = ''
            this.event.details = ''
            this.event.date_start = this.formatDate(date.date)
            this.event.date_end = this.formatDate(date.date)
            this.event.time_start = ''
            this.event.time_end = ''
            this.event.item_id = this.me.id
            this.event.user_id = this.me.id
            this.openCloseDialog('event')
        },
        
        loadItem (id) {
            this.$store.dispatch('loadEvent', id) 
                        .then(response => {
                            this.event = response.data
                            this.openCloseDialog('event')
                        })
        },

        destroyItem (id) {
            this.$store.dispatch('destroyEvent', id) 
                        .then(response => {
                            this.selectedOpen = false
                            this.loadItems(this.params)
                        })
        },

        openCloseDialog(window){
            if(window == 'event'){
                this.dialog.event = !this.dialog.event
            }

            if(this.dialog.event == false){
                this.loadItems(this.params)
            }
        }   

    },

}
</script>