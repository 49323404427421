<template>
    <div>
        <v-form ref="form" class="p-2" v-if="object.item_id">
            <v-row dense>
                <v-col dense cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Nova senha"
                        autocomplete="off"
                        @click:append="show1 = !show1"
                        outlined
                        dense
                        :class="$css_field"
                    ></v-text-field>
                </v-col>
                <v-col dense cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.password2"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confirme a nova senha"
                        autocomplete="off"
                        @click:append="show2 = !show2"
                        outlined
                        dense
                        :class="$css_field"
                        v-on:keyup="checkPassword"
                    ></v-text-field>
                </v-col>
                <v-col dense cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div v-if="confirm">
                        <v-chip
                            class="ma-2"
                            color="pink"
                            label
                            text-color="white"
                            >
                            A senha e a confirmação da senha não confere
                        </v-chip>
                    </div>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"></v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        v-if="item.password == item.password2 && item.password != ''"
                        :class="$css_button"
                    >
                    Save
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>

export default {
    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            show1: false,
            show2: false,
            confirm: false,
            success: false,
            
            item: {
                model: '',
                item_id: '',
                password: '',
                password2: '',
            },

            rules: {
                required: value => !!value || 'Required',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
            },
        }
    },

    methods: {

        checkPassword (){
            if(this.item.password != this.item.password2 && this.item.password2 != ''){
                this.confirm = true
            }else{
                this.confirm = false
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                  this.update()
            }
        },

        update(){
            this.item.model = this.object.model
            this.item.item_id = this.object.item_id
            this.item.confirm_password = this.item.password2
            
            this.$store.dispatch('updatePassword', this.item)
                        .then(response => {
                            this.success = true
                            this.$snotify.success('Senha atualizada com sucesso')
                            this.clear()
                        })
                        .catch(error => {
                            error
                        })
        },

        clear(){
            this.$refs.form.reset()
        }

    }
}
</script>
