<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.description"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Descriçāo</span>
                        </template>
                    </v-text-field>
                </v-col>
                 <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" class="text-right">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>  
           </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.description`]="{ item }">
                            <span :class="$css_report_list">{{ item.description }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>  
    </div>
</template>

<script>
    export default {
        components: {
            
        },

        props: {
            object: {
                require: true,
            },
        },

        data () {
            return {
                item: {
                    model: '',
                    item_id: '',
                    description: ''
                },

                rules: {
                    required: value => !!value || 'Required.',
                    email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
                },

                headers: [
                    { text: 'DESCRIÇĀO', align: 'start', value: 'description', },
                    { text: 'AÇĀO', value: 'actions', sortable: false },
                ],
            }
        },

        methods:{   

            validate () {
                if (this.$refs.form.validate()) {       
                    this.storeUpdate()
                }
            },
            
            storeUpdate(){
                const item = {}
                if(this.item.id && this.item.id != ''){
                    item.id = this.item.id        
                }else{
                    item.model = this.object.model
                    item.item_id = this.object.item_id    
                }
                item.description = this.item.description
              
                const action = item.id ? 'updateHowtoKnowItem' : 'storeHowtoKnowItem'
                this.$store.dispatch(action, item)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                                this.$snotify.success('Registered Successfully!')
                                this.reset()
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            }) 
            },

            editItem (id) {    
                this.$store.dispatch('loadHowtoKnowItem', id) 
                            .then(response => {
                                this.item.id = response.data.id
                                this.item.description = response.data.description
                            })
            },

            reset(){
                this.item = {}
                this.$refs.form.reset()
            },

            destroy(item){
                this.$store.dispatch('destroyHowtoKnowItem', item.id)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Successfully deleted')
                            )
            },

            actionEmitLinks(action, value){
                this.$emit(action,value)
            },
            
        }    
    }
</script>