<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        v-model="item.title"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Titulo (opcional)</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        v-model="item.description"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Descriçāo (opcional)</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center">
                    <ShareNetwork
                        :network="media"
                        :url="me.branch.url + object.link"
                        :title="item.title"
                        :description="item.description"
                        hashtags="app_ayt,ayontech"
                        twitterUser="appayt"
                        >
                        <v-btn
                            dark
                            color="primary"
                            :class="$css_button"
                        >
                        Compartilhar na midia {{ media.toUpperCase() }}
                        </v-btn>
                     </ShareNetwork>
                </v-col>    
            </v-row>                
        </v-form>
    </div>
</template>

<script>


export default {
    created(){
        this.setItem()
    },

    props: {
        object: {
            require: false,
        },

        media: {
            require: false,
        },
    }, 

    watch: {
        object: {
            handler:function() {
                this.setItem()
            },
            deep:true
        },
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    data () {
        return {
            item: {
                title: '',
                description: '',
            }
        }
    },
    
    methods: {
        setItem () {
            this.item.title = ''
            this.item.description = ''
        }
    }


}
</script>