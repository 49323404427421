<template>
    <div>
        <list-component v-if="tenant[0].status == 'Ativo'" ref="list_component" id="20231213"></list-component>
        <suspended-tenant-component v-else></suspended-tenant-component>
    </div>

</template>

<script>
import SuspendedTenantComponent from '../suspendedtenant/SuspendedTenantComponent.vue'
import ListComponent from './partials/ListComponent.vue'

export default {
    components: {
        ListComponent,
        SuspendedTenantComponent
    },

    computed: {
        tenant() {
            return this.$store.state.tenants.items.data    
        }
    },

    data () {
        return {
          
        }
    },

    methods: {

    },
}
</script>

<style scoped>

</style>