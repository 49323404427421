<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense> 
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.model"
                        :items="items.modules"
                        item-text="description"
                        item-value="model"
                        outlined
                        dense
                        clearable
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Módulo</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.codigo_model"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Código do módulo</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.status"
                        :items="items.status"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.codigo"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Código da solicitação</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="params.subject"
                        :items="items.subjects"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Assunto</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.user_request"
                        :items="users"
                        outlined
                        dense
                        item-text="name"
                        item-value="id"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Solicitante</span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="blue darken-1"
                        text  
                        @click.prevent="clear" 
                        :class="$css_button"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click.prevent="submit"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>        
    </div>
</template>

<script>
import { STATUS_ITEMS, MODULES, SUBJECTS } from '@/components/user_requests/configs_user_requests/configs_user_requests.js'

export default {
    created(){
        this.loadUsers()
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        users() {
            return this.$store.state.users.items.data    
        },  
    },

    data() {
        return {
            params: {
                model: '',
                codigo_model: '',
                codigo: '',
                status: '',
                assunto: '',
                user_request: ''
            },
            
            items: {
                status: STATUS_ITEMS,
                modules: MODULES,
                subjects: SUBJECTS
            },
        }
    },

    methods: {
        loadUsers(){
            this.$store.dispatch('loadUsers')
        },

        clear() {
            this.$refs.form.reset()
        },

        submit(){
            this.params.id = ''
            this.$emit('callLoadItems',this.params)
        }
        
    }
}
</script>