<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-4 ma-0"

                >      
                    <bread-crumb-component :object="breadcrumbs"></bread-crumb-component>    
                </v-card>
            </v-col>
        </v-row>
         
        <div>
            <report-component v-if="listitems" :object="listitems" @callLoadItems="loadItems" @callEditItem="editItem" ref="report"></report-component>
        </div>

        <div>
            <account-component :object="accountitems" @callLoadAccounts="loadAccounts"></account-component>
        </div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Empresa
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->
    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'
import AccountComponent from '@/components/accounts/AccountComponent.vue'

export default {
    components:{
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent,
        AccountComponent
    },

    created() {
        this.loadAccounts()
    },

    computed: {
        listitems() {
            return this.$store.state.tenants.items    
        },

        accountitems() {
            return this.$store.state.accounts.items    
        },
    },    

    data () {
        return {
            item: {},

            dialog: false,

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Empresa',
                    disabled: true,
                    href: 'tenants',
                },
            ],

            panel_search: [],

            params: {

            },

            excel: {
                fields: {
                   // 'Days'                      : 'days',
                },

                data: [],   
            },

        }
    },


    methods: {
        loadItems () {
            this.$store.dispatch('loadTenants') 
        },

        loadAccounts () {
            if(this.$route.params.status_payment && this.$route.params.status_payment != ""){
                this.params.status_payment = this.$route.params.status_payment
                this.$route.params.status_payment = ''
            }

            this.$store.dispatch('loadAccounts', this.params) 
        },

        loadItem(id){
            this.$store.dispatch('loadTenant', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        editItem (id) {    
            this.$store.dispatch('loadTenant', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog = true
                        })
                                   
        },

        openCloseDialog(){
            this.item = {}
            this.dialog = !this.dialog
            if(this.dialog == false){
                this.loadItems()
            }
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>