<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense> 
                <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.codigo"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Código</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.status"
                        :items="items.status"
                        outlined
                        multiple
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.nome"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12"  md="3" lg="3" xl="3">
                        <v-select
                            v-model="params.setores"
                            :items="items.setores"
                            outlined
                            dense
                            :class="$css_field"
                            multiple
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Setores</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-select
                            v-model="params.tipos_imovel"
                            :items="items.tipos_imovel"
                            outlined
                            dense
                            multiple
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Tipos</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.area_from"
                            outlined
                            dense
                            type="number"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Área Const. Mínima</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.area_to"
                            outlined
                            dense
                            type="number"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Área Const. Máxima</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.metragem_minima_from"
                            outlined
                            dense
                            type="number"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Metragem Mínima De</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.metragem_minima_to"
                            outlined
                            dense
                            type="number"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Metragem Mínima Até</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.metragem_maxima_from"
                            outlined
                            dense
                            type="number"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Metragem Máxima De</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.metragem_maxima_to"
                            outlined
                            dense
                            type="number"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Metragem Máxima Até</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.metragem_frente_from"
                            outlined
                            dense
                            type="number"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Metragem Frente De</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.metragem_frente_to"
                            outlined
                            dense
                            type="number"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Metragem Frente Até</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_minimo_locacao_from"
                            label="Vl Min. Locação de"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_maximo_locacao_to"
                            label="Vl Min. Locação até"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                     <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_maximo_locacao_from"
                            label="Vl Max. Locação de"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_maximo_locacao_to"
                            label="Vl Max. Locação até"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_minimo_venda_from"
                            label="Vl Min. Venda de"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_minimo_venda_to"
                            label="Vl Min. Venda até"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                     <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_maximo_venda_from"
                            label="Vl Max. Venda de"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_maximo_venda_to"
                            label="Vl Max. Venda até"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                    
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_min_vgv"
                            label="VL Min. VGV"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="params.valor_max_vgv"
                            label="VL Max. VGV"
                            outlined
                            dense
                            :class="$css_field"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-select
                            v-model="params.zonas"
                            :items="items.zonas"
                            outlined
                            dense
                            multiple
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Zonas</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-select
                            v-model="params.zoneamentos"
                            :items="items.zonings"
                            outlined
                            dense
                            multiple
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Zoneamentos</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                        <v-autocomplete
                            v-model="params.places"
                            :items="placeitems"
                            outlined
                            dense
                            item-text="distrito_cidade_estado"
                            item-value="all"
                            :class="$css_field"
                            multiple
                            clearable
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Locais</span>
                            </template>
                        </v-autocomplete>
                    </v-col>
            </v-row>
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="blue darken-1"
                        text  
                        @click.prevent="clear" 
                        :class="$css_button"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click.prevent="submit"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>        
    </div>
</template>

<script>
import { SETOR_ITEMS, ZONA_IMOVEL_ITEMS} from '../../properties/config_properties/config_properties.js' 
import { TIPO_IMOVEL_BULDER_ITEMS, STATUS_ITEMS } from '../config_builders/config_builders.js'  

export default {
    created(){
        this.setItems()
        this.loadPlaceItems()
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        placeitems() {
            return this.$store.state.placeitems.items.data    
        },
    },

    data() {
        return {
            place_params: {
                estado: ''
            },

            params: {
                id: ''
            },
            
            items: {
                status: STATUS_ITEMS,
                setores: SETOR_ITEMS,
                tipos_imovel: TIPO_IMOVEL_BULDER_ITEMS,
                zonas: ZONA_IMOVEL_ITEMS,
                zonings: []
            },

            filterProperty: {
                model: 'zonings',
                tipos: '',
                regioes: '',
                order: 'description'
            },
        }
    },

    methods: {

        clear() {
            this.$refs.form.reset()
        },

        submit(){
            this.params.id = ''
            this.$emit('callLoadItems',this.params)
        },

        setItems() {
            const zonings = []
            const results = this.filterPropertyItems(this.filterProperty)
            results.forEach(i => {
                    zonings.push(i.description)
            })

            this.items.zonings = zonings
        },

        loadPlaceItems(){
            this.place_params.estado = this.me.branch.estado
            this.$store.dispatch('loadPlaceItems', this.place_params) 
        },
    }
}
</script>