<template>
    <div v-if="!object.id" class="mt-8">
        <main-component :object="item" @callLoadItem="loadItem"></main-component>
    </div>
    <div v-else>
        <v-tabs
            v-model="active_tab"
            show-arrows 

        >
            <v-tab href="#tab-1" class="caption primary--text"> Principal</v-tab>
            <v-tab href="#tab-2" class="caption primary--text"> Logo e Marca </v-tab>
            <v-tab href="#tab-3" class="caption primary--text"> Textos do site </v-tab>
            <v-tab href="#tab-4" class="caption primary--text"> Banners do site </v-tab>
            <v-tab href="#tab-5" class="caption primary--text"> Canais receptivos </v-tab>
            <v-tab href="#tab-6" class="caption primary--text"> Dados Técnicos do Site </v-tab>
            <v-tab href="#tab-7" class="caption primary--text"> Vitrine do site </v-tab>
            <v-tab href="#tab-8" class="caption primary--text"> Midias sociais </v-tab>
        </v-tabs>
    

        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="item" @callLoadItem="loadItem"></main-component>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <upload-component :object="branchimages" @callLoadItem="loadItem"></upload-component> 
                </v-card>    
            </v-tab-item>                

            <v-tab-item value="tab-3">
                <v-card flat class="pa-8">
                    <text-component :object="texts" @callLoadItem="loadItem"></text-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-4">
                <v-card flat class="pa-8">
                    <upload-component :object="branchbanners" @callLoadItem="loadItem"></upload-component> 
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-5">
                <v-card flat class="pa-8">
                   <how-to-know-item-component :object="howtoknowitems" @callLoadItem="loadItem"></how-to-know-item-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-6">
                <v-card flat class="pa-8">
                    <technical-information-component :object="item" @callLoadItem="loadItem"></technical-information-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-7">
                <v-card flat class="pa-8">
                    <shop-window-component :object="shop_window" @callLoadItem="loadItem"></shop-window-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-8">
                <v-card flat class="pa-8">
                   <social-media-component :object="social_medias" @callLoadItem="loadItem"></social-media-component>
                </v-card>    
            </v-tab-item>
        </v-tabs-items>

    </div>    
</template>


<script>
import { URL_UPLOAD } from '../../../config/config.js'
import MainComponent from './MainComponent.vue'
import UploadComponent from '../../upload/UploadComponent.vue'
import TextComponent from '../../text/TextComponent.vue'
import TechnicalInformationComponent from './TechnicalInformationComponent.vue'
import HowToKnowItemComponent from '../../howtoknowitems/HowToKnowItemComponent.vue'
import ShopWindowComponent from '@/components/shop_windows/ShopWindowComponent.vue'
import SocialMediaComponent from '@/components/social_medias/SocialMediaComponent.vue'

export default {
    created() {
        this.setTab()
        this.setObject()
    },

    components: {
       MainComponent,
       UploadComponent,
       TextComponent,
       TechnicalInformationComponent,
       HowToKnowItemComponent,
       ShopWindowComponent,
       SocialMediaComponent
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.item.id != this.object.id){
                this.setTab()
            }
            this.setObject()
        },
        
        item () {   
            //this.setObject()
        }
    },

    data () {
        return {
            active_tab: 'tab-1',
            item: {},

            branchimages: {
                multiple: false,
                model: 'branches',
                item_id: '',
                items: '',
                extensions: '.jpg,.png,.jpeg',
                label_extensions: 'Arquivos permitidos  (jpeg e png)',
                path: '',
                descriptions: ['Logo','Logo quadrado','Marca','Favicon','Fundo Pesquisa']
            },

            branchbanners: {
                model: 'branches',
                item_id: '',
                items: '',
                extensions: '.jpg,.png,.jpeg',
                label_extensions: 'Arquivos permitidos  (jpeg e png)',
                path: '',
                description: 'Banner',
                order: 'true'
            },

            texts: {
                model: 'branches',
                item_id: '',
                items: '',
                types: ['A empresa','Fale conosco','Trabalhe conosco','Mensagem página inicial','Complemento Texto Publicitário'] 
            },

            howtoknowitems: {
                model: 'branches',
                item_id: '',
                items: ''
            },

            shop_window: {
                branch_id: '',
                items: ''
            },

            social_medias:{
                model: 'branches',
                item_id: '',
                items: ''
            },
        }
    },

    methods: {
        changeStep(step){
            this.active_tab = step
        },

        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        setObject(){
            this.item = this.object

            // branchimages
            this.branchimages.item_id = this.object.id
            this.branchimages.items = this.object.branchimages
            this.branchimages.path = URL_UPLOAD + `branches/${this.object.id}`

            // branchimages
            this.branchbanners.item_id = this.object.id
            this.branchbanners.items = this.object.branchbanners
            this.branchbanners.path = URL_UPLOAD + `branches/${this.object.id}`

            // texts
            this.texts.item_id = this.object.id
            this.texts.items = this.object.texts

            // howtoknowitems
            this.howtoknowitems.item_id = this.object.id
            this.howtoknowitems.items = this.object.howtoknowitems

            //shop windows
            this.shop_window.branch_id = this.object.id
            this.shop_window.items = this.object.shop_windows

             // social_medias
            this.social_medias.item_id = this.object.id
            this.social_medias.items = this.object.social_medias
        },
        
    },
}
</script>

<style scoped>

</style>