<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.setores_array"
                        :items="items.setores"
                        :rules="[rules.required]"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Setor</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.tipos_imovel_array"
                        :items="items.tipos_imovel"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo</span>
                        </template>
                    </v-select>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.metragem_minima"
                        outlined
                        dense
                        type="number"
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Metragem Mínima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.metragem_maxima"
                        outlined
                        dense
                        type="number"
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Metragem Máxima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.metragem_frente"
                        outlined
                        dense
                        type="number"
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Metragem Frente</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.area"
                        outlined
                        dense
                        type="number"
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área Construída</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_minimo_locacao"
                        label="Vl Min. Locação"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_maximo_locacao"
                        label="Vl Máx. Locação"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_minimo_venda"
                        label="Vl Min. Venda"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_maximo_venda"
                        label="Vl Máx. Venda"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_maximo_metro_quadrado_venda"
                        label="Vl de Venda Máx. Metro"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.vgv"
                        label="VGV"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.zonas_array"
                        :items="items.zonas"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Zonas</span>
                        </template>
                    </v-select>
                </v-col>  
           </v-row>
           <v-row dense>       
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        Salvar
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>  
    </div>
</template>

<script>
import { SETOR_ITEMS, ZONA_IMOVEL_ITEMS} from '../properties/config_properties/config_properties.js' 
import { TIPO_IMOVEL_BULDER_ITEMS } from '../builders/config_builders/config_builders.js'

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: true,
        },
    },

    watch: {
        object () {   
            this.setObject()
        },
    },

    data () {
        return {
            item: {
                setores_array: [],
                tipos_imovel_array: [],
                zonas_array: [],
            },

            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            items: {
                setores: SETOR_ITEMS,
                tipos_imovel: TIPO_IMOVEL_BULDER_ITEMS,
                zonas: ZONA_IMOVEL_ITEMS
            }
        }
    },

    methods:{   
        setObject(){

            if(this.object.item.id && this.object.item.id != undefined){
                this.item = this.object.item
                
                if(this.item.setores && this.item.setores != ''){
                    this.item.setores_array = this.item.setores.split(",");
                }
                if(this.item.tipos_imovel && this.item.tipos_imovel != ""){
                    this.item.tipos_imovel_array = this.item.tipos_imovel.split(",");
                }
                if(this.item.zonas && this.item.zonas != ""){
                    this.item.zonas_array = this.item.zonas.split(",");
                }
            }
        },

        changeStep(step){
            this.$emit('callChangeStep',step)
        },

        validate () {
            if (this.$refs.form.validate()) {      
                this.item.model = this.object.model            
                this.item.item_id = this.object.item_id
                this.storeUpdate(this.item)
            }
        },
        
        storeUpdate(data){
            
            if(data.setores_array && data.setores_array.length > 0){
                data.setores = data.setores_array.toString()
            }
            if(data.tipos_imovel_array && data.tipos_imovel_array.length > 0){
                data.tipos_imovel = data.tipos_imovel_array.toString()
            }
            if(data.zonas_array && data.zonas_array.length > 0){
                data.zonas = data.zonas_array.toString()
            }
 
            const action = data.id && data.id != undefined ? 'updateSearchProperty' : 'storeSearchProperty'
            this.$store.dispatch(action, data)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                            this.$snotify.success('Registered Successfully!')  
                        })
                        .catch(error => {   
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
        },    
    }    
}
</script>