<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense>
                <v-col v-if="object.types && object.types.length > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.type"
                        :items="object.types"
                        :rules="[rules.required]"
                        label="Tipo"
                        outlined
                        dense
                        :class="$css_field"
                    ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense class="mb-6">       
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                   <vue-editor v-model="item.description" :editorToolbar="customToolbar"/>
                </v-col> 
            </v-row>
        </v-form> 
        <v-row v-if="object.items">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                    >
                        <template v-slot:[`item.type`]="{ item }">
                            <span class="caption">{{ item.type  }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueEditor
    },

    props: {
        object: {
            require: true,
        },
    },

    watch: {
        
    },

    data () {
        return {
            item: {
                id: '',
                model: this.object.model,
                item_id: this.object.item_id,
                type: '',
                description: '',
                params: ''
            },

            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ color: [] }, { background: [] }],
                
            ],

            items: {
                types: this.object.types,
            },

            rules: {
                required: value => !!value || 'Required.',
            },

            headers: [
                { text: 'TIPO', align: 'start', value: 'type', },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],
        }
    },

    methods:{   
        validate () {
            if (this.$refs.form.validate()) {       
                this.storeUpdate(this.item)
            }
        },
        
        storeUpdate(item){ 
            const action = item.id ? 'updateText' : 'storeText'   
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                            this.$snotify.success('Registered Successfully!')
                            this.reset()
                        })
                        .catch(error => {   
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        }) 
        },

        editItem(id){
            this.$store.dispatch('loadText', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        reset(){
            this.item.id = ''
            this.item.description = ''
            this.item.type = ''
            this.$refs.form.reset()
        },

        confirmDestroy(item) {
            this.$snotify.error(`Tem certeza qu deseja excluir esse registro?`, 'Confirmaçāo', {
                id: 1,
                timeout: 10000,
                showProgressBar: true,
                closeOnClick: false,
                buttons: [
                    {text: 'No', action: null},
                    {text: 'Yes', action: () => { 
                        this.destroy(item)
                        //this.$snotify.remove()
                    }},
                ] 
            })
        },

        destroy(item){
            this.$store.dispatch('destroyText', item.id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Successfully deleted')
                        )
        },
    }    
}
</script>