export const SETOR_ITEMS = ['Venda','Locação','Venda e Locação']
export const FINALIDADES_IMOVEL = ['Residencial', 'Comercial', 'Industrial', 'Residencial / Comercial']
export const REQUIRES_DEFAULT = [
    { field: 'places', description: 'LOCAIS', type: 'array', rule: 'greater_than_zero', tab:'7'},
    { field: 'photos_total', description: 'FOTOS', type: 'array', rule: 'greater_than_zero', tab:'8'},
    { field: 'property_owners', description: 'PROPRIETÁRIOS', type: 'array', rule: 'greater_than_zero', tab:'13'},
    { field: 'publicidade', description: 'PUBLICIDADE', type: 'string', rule: 'not_null', tab:'9'}
]
export const TIPO_IMOVEL_PROPERTY_ITEMS = [
    { tipo: 'Apartamento', subtipos: ['Apartamento Padrão','Cobertura','Duplex','Triplex','Kitnet','Flat','Apart hotel','Loft','Pent house','Studio','Terreo','Garden'], finalidade: ['Residencial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Útil', area_total: 'Área Total'} },
    { tipo: 'Área', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial', 'Industrial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'], requires: REQUIRES_DEFAULT, areas: { area: 'Área Terreno', area_total: ''}},
    { tipo: 'Armazem', subtipos: [], finalidade: ['Comercial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Casa', subtipos: ['Casa Padrão','Assobradada','Terrea','Sobrado'], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area:['Frente','Fundo','Lado Direito','Lado Esquerdo'], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Chácara', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Chalé', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Conjunto', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Const./Útil', area_total: 'Área Total/Terreno'}},
    { tipo: 'Fazenda', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Galpão', subtipos: [], finalidade: ['Comercial','Industrial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Hotel', subtipos: [], finalidade: ['Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Loja', subtipos: ['Sobre loja'], finalidade: ['Comercial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'], requires: REQUIRES_DEFAULT, areas: { area: 'Área Const./Útil', area_total: 'Área Total/Terreno'}},
    { tipo: 'Oportunidade Comerciais', subtipos: [], finalidade: ['Comercial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'], requires: REQUIRES_DEFAULT, areas: { area: 'Área Const./Útil', area_total: 'Área Total/Terreno'}},
    { tipo: 'Ponto Comercial', subtipos: [], finalidade: ['Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Const./Útil', area_total: 'Área Total/Terreno'}},
    { tipo: 'Pousada', subtipos: [], finalidade: ['Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Predio Comercial', subtipos: [], finalidade: ['Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Sala Comercial', subtipos: [], finalidade: ['Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Útil', area_total: 'Área Total'}},
    { tipo: 'Salão', subtipos: ['Salão Padrão','Galpão'], finalidade: ['Comercial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'], requires: REQUIRES_DEFAULT, areas: { area: 'Área Const./Útil', area_total: 'Área Total / Terreno'}},
    { tipo: 'Sítio', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [], requires: REQUIRES_DEFAULT, areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Terreno', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial', 'Industrial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'], requires: REQUIRES_DEFAULT, areas: { area: 'Área Terreno', area_total: ''}}
]

export const ZONA_IMOVEL_ITEMS = ['Centro','Norte','Sul','Leste','Oeste','Interior','Litoral','ABCD']
export const ESTADO_IMOVEL_ITEMS = ['Novo','Usado','Lançamento','Breve Lançamento','Em construção']
export const REGIAO_IMOVEL_ITEMS = ['Urbana', 'Litoral', 'Campo']
export const SIM_NAO_ITEMS = ['Sim','Não']
export const ATIVIDADE_ITEMS = ['Açougue', 'Alfaiataria', 'Alhóndiga (edifício)', 'Animangá (editora)', 'Antiquário (local)', 'Banca de jornal', 'Bar', 'Bomboniere', 'Borracharia', 'Botillería', 'Brechó', 'Bricabraque', 'Cabeleireiro', 'Coffeeshop', 'Cibercafé', 'Drogaria', 'Alfaiataria', 'Alhóndiga (edifício)', 'Animangá (editora)', 'Antiquário (local)', 'Banca de jornal', 'Bar', 'Bomboniere', 'Borracharia', 'Botillería', 'Brechó', 'Bricabraque', 'Cabeleireiro', 'Coffeeshop', 'Cibercafé', 'Drogaria', 'Farmácia (estabelecimento)', 'Fliperama', 'Galeria de arte', 'Gibiteria', 'Gráfica', 'Head shop', 'LAN house', 'Loja', 'Loja de brinquedos', 'Loja de conveniência', 'Lojas preço único', 'Mercearia', 'Papelaria', 'Pet shop', 'Posto de abastecimento', 'Pub', 'Puro alimentar', 'Quiosque', 'Quitanda', 'Salão de beleza', 'Saloon', 'Sebo (alfarrabista)', 'Sex shop', 'Tabacaria', 'Taberna', 'Transportador-revendedor-retalhista', 'Viktualienmarkt']
export const OCUPACAO_IMOVEL_ITEMS = ['Nāo informado', 'Vago', 'Ocupado pelo inquilino', 'Ocupado pela proprietário']
export const TIPO_PERMUTA_ITEMS = ['Veiculo', 'Imovel']
export const TOPOGRAFIA_ITEMS = ['Aclive','Declive','Plano']
export const DOCUMENTACAO_IMOVEL_ITEMS = ['Nāo informado', 'Escritura definitiva', 'Contrato particular', 'Contrato de financiamento', 'Inventário em andamento', 'Inventário concluído', 'Habite-se em andamento', 'Inventário a realizar', 'Em andamento']
export const PERTO_DE_ITEMS = ['Aeroporto','Centro','Comércio','Escola','Faculdade / Universidade','Hospital','Metrô','Parque','Praia','Rodoviária','Shopping','Vias de acesso']
export const CHAVE_ITEMS = ['Proprietário','Imobiliária','Inquilino','Outros']
export const ESTADO_CONTRATO_LOCACAO_ITEMS = ['Nāo informado', 'Em vigência','Vencido']
export const ADMINISTRADORA_ITEMS = ['Proprietário','Terceiros','Imobiliária']
export const GARANTIA_LOCACAO_ITEMS = ['Depósito','Fiador','Seguro fiança','Titulo de captalizaçāo','Caucionante','Sem garantia']
export const MAPA_ITEMS = ['Nāo exibir','Área aproximada','Endereço exato','Endereço sem numero']
export const VIDEO_TYPE_ITEMS = ['YouTube','Link 360']
export const STATUS_PROPERTY_ITEMS = ['Pendente', 'Ativo', 'Suspenso','Reservado','Alugado pela Imobiliária','Vendido pela Imobiliária','Alugado por Terceiros','Vendido por Terceiros','Desatualizado','Cancelado','Pendente Site','Com proposta']
export const COLABORATOR_DESCRIPTION_ITEMS = ['Captador','Colaborador Venda','Colaborador Locação','Plantonista']
export const EXCEL_FIELD = {
    codigo:"codigo",
    status:"status",
    setor:"setor",
    finalidade:"finalidade",
    tipo_imovel:"tipo_imovel",
    sub_tipo_imovel:"sub_tipo_imovel",  
    estado_imovel:"estado_imovel",
    cep:"cep_format",
    endereco:"endereco",
    bairro:"bairro",
    cidade:"cidade",
    estado:"estado",
    numero:"numero",
    apartamento:"apartamento",
    complemento:"complemento",
    dormitorios:"dormitorios",
    suites:"suites",
    banheiros:"banheiros",
    vagas:"vagas",
    salas:"salas",
    area:"area",
    area_total:"area_total",
    valor_condominio:"valor_condominio",
    valor_venda:"valor_venda",
    valor_locacao:"valor_locacao",
    valor_temporada:"valor_temporada",
    valor_iptu:"valor_iptu",
    em_condominio:"em_condominio",
    em_vila:"em_vila",
    titulo_publicitario:"titulo_publicitario",
    texto_publicitario:"texto_publicitario",
    data_atualizacao:"data_atualizacao",
    proprietarios:"property_owners",
}
