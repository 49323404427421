<template>
    <div>
        <v-card
            class="pa-0 rounded-0"
            outlined
            id="table_border"
        >
            <v-simple-table dense class="rounded-0">
                <thead fixed-header>
                    <tr>
                        <th colspan="2" :class="$css_title_module">
                            Informações
                        </th>
                    </tr>
                </thead>
                <tbody>
                     <tr>
                        <td colspan="2" :class="$css_show_description" id="td_border">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_show_title">Status: </span>  
                                        <v-chip
                                            class="ml-2"
                                            :color="object.status | statusFac"
                                            label
                                            small
                                            :class="$css_report_list"
                                        >
                                            {{ object.status }}
                                        </v-chip> 
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_show_title">Dt.Cadastro: </span>     
                                        <span :class="$css_show_description">{{ object.created_at }}</span>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_show_title">Dt.Atualizaçāo: </span>  
                                        <span :class="$css_show_description">{{ object.updated_at }}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col v-if="object.client.nome" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <span :class="$css_show_title">Nome: {{ object.client.nome }}</span> 
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span v-if="object.client.telefone"> 
                                            <phone-component v-if="object.client.telefone" :object="object.client.telefone"></phone-component>    
                                            <phone-component v-if="object.client.telefone2" :object="object.client.telefone2"></phone-component>  
                                        </span>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span v-if="object.client.email"> 
                                            E-mail: 
                                            <a :href="`mailto:${object.client.email}`">
                                                {{ object.client.email }} 
                                            </a>
                                            <span v-if="object.client.email2">
                                                / 
                                                <a :href="`mailto:${object.client.email2}`">
                                                {{ object.client.email2 }} 
                                                </a>
                                            </span>
                                        </span>
                                    </v-col>   
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr v-if="object.finalidade && object.finalidade != ''">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Finalidade: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.finalidade }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr v-if="object.classificacao && object.classificacao != ''">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Classificaçāo: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.classificacao }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr v-if="object.client.como_conheceu && object.client.como_conheceu != ''">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Como conheceu: </span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                       {{ object.client.como_conheceu }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Colaboradores: </span>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <span :class="$css_show_description">
                                            <span v-for="item in object.colaborators" :key="item.id">
                                                <div class="mr-4">
                                                    <span>{{ item.name }}</span>
                                                    <span v-if="item.description"> ({{ item.description }})</span>
                                                </div>
                                            </span>
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>
                    <tr v-if="object.property_relations && object.property_relations.length > 0">
                        <td colspan="2" :class="$css_title_module">
                            Imóveis Relacionados
                        </td>
                    </tr>
                    <tr v-if="object.property_relations && object.property_relations.length > 0">
                        <td colspan="2">
                            <v-card
                                class="mx-auto ma-2"
                                outlined
                            >
                                <v-data-table
                                    :hide-default-header="$vuetify.breakpoint.mobile"
                                    :headers="headers.property_relations"
                                    :items="object.property_relations"
                                    :items-per-page="10"
                                    sort-by="id"
                                    class="elevation-0 table-striped"
                                    :footer-props="{
                                    'items-per-page-text':'Itens por página',
                                    'items-per-page-options': [5, 10],
                                    'items-per-page-all-text': '',
                                    'page-text': '{0}-{1} de {2}'
                                }"
                                >
                                    <template v-slot:[`item.photo`]="{ item }">
                                        <v-avatar
                                            class="ma-2"
                                            color="#BDBDBD"
                                            tile
                                            width="200px"
                                            height="150px"
                                        >
                                            
                                            <v-img 
                                                v-if="setFirstPhoto(item.property_photos, item.condominium_photos) && setFirstPhoto(item.property_photos, item.condominium_photos).length > 0"
                                                :src="setFirstPhoto(item.property_photos, item.condominium_photos)[0].urlremoto"
                                                :lazy-src="setFirstPhoto(item.property_photos, item.condominium_photos)[0].urlremoto"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                    >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                    ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                            <v-icon v-else large dark>
                                                mdi-camera 
                                            </v-icon>
                                        </v-avatar>
                                    </template>


                                    <template v-slot:[`item.description`]="{ item }">
                                    <div class="mb-2 mt-2">
                                            <span>
                                                <v-chip
                                                    label
                                                    small
                                                    color="blue lighten-5" 
                                                >
                                                <span style="font-size:12px">{{ item.codigo }}</span> 
                                                </v-chip>   
                                            </span>
                                            <span class="ml-2">
                                                <v-chip
                                                    :color="item.status | statusProperty"
                                                    label
                                                    small
                                                >
                                                    {{ item.status }}
                                                </v-chip> 
                                            </span>
                                            
                                            <span class="ml-2 black--text font-weight-medium">
                                                {{ item.tipo_imovel }}
                                                <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span>
                                            </span>

                                            <span class="text-right">
                                                compartilhar
                                            </span>
                                        </div>
                                        <div>
                                            {{ item.endereco }}
                                            <span v-if="item.numero">, {{ item.numero }} </span>
                                            <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                                            <span v-if="item.andar">  - {{ item.andar }} andar </span>
                                            <span v-if="item.tower && item.tower.nome != ''"> - Torre: {{ item.tower.nome }} </span>
                                            <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                                            <span v-if="item.bairro">  - {{ item.bairro }} </span>
                                            <span v-if="item.cidade">  - {{ item.cidade }} </span>
                                            <span v-if="item.estado">  - {{ item.estado }} </span>
                                            <span v-if="item.cep">  - {{ item.cep | VMask('#####-###') }} </span>
                                        </div> 
                                        <div class="mt-3">
                                            <span v-if="item.area">
                                                <v-chip
                                                    label
                                                    small
                                                    color="blue-grey lighten-5 ma-1" 
                                                >
                                                <span>Área {{ item.area }} m²</span> 
                                                </v-chip>
                                            </span>
                                            <span class="ml-2" v-if="item.dormitorios">
                                                <v-chip
                                                    label
                                                    small
                                                    color="blue-grey lighten-5 ma-1" 
                                                >
                                                <span>{{ item.dormitorios }} dorm</span> 
                                                </v-chip>
                                            </span>
                                            <span class="ml-2" v-if="item.banheiros">
                                                <v-chip
                                                    label
                                                    small
                                                    color="blue-grey lighten-5 ma-1" 
                                                >
                                                <span>{{ item.banheiros }} banheiros</span> 
                                                </v-chip>
                                            </span>
                                            <span class="ml-2" v-if="item.vagas">
                                                <v-chip
                                                    label
                                                    small
                                                    color="blue-grey lighten-5 ma-1" 
                                                >
                                                <span>{{ item.vagas }} vagas</span> 
                                                </v-chip>
                                            </span>
                                        </div>

                                        <div class="mt-3">
                                            <v-row dense>
                                                <v-col v-if="item.valor_venda && item.valor_venda > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <span :class="$css_main_value">Venda {{ item.valor_venda | toCurrency }}</span> 
                                                </v-col>
                                                <v-col v-if="item.valor_locacao && item.valor_locacao > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <span :class="$css_main_value">Locaçāo {{ item.valor_locacao | toCurrency }}</span> 
                                                </v-col>
                                                <v-col class="pa-3" v-if="item.valor_condominio && item.valor_condominio > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <span :class="$css_sub_value">Condomínio {{ item.valor_condominio | toCurrency }}</span> 
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="mt-3">
                                            <v-card-actions>
                                                <button-share-component class="mr-4" :object="item"></button-share-component>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon @click.prevent="showItem(item.id)" class="ml-2">
                                                            <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Visualizar</span>
                                                </v-tooltip>
                                                
                                            </v-card-actions>  
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </td>
                    </tr>
                    <tr v-if="object.property_profiles && object.property_profiles.length > 0">
                        <td colspan="2" :class="$css_title_module">
                            Perfil de imóvel
                        </td>
                    </tr>
                    <tr v-if="object.property_profiles && object.property_profiles.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col v-if="object.property_profiles[0].tipo_imovel && object.property_profiles[0].tipo_imovel != ''" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span :class="$css_show_title">Tipo imóvel: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].tipo_imovel }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].sub_tipo_imovel && object.property_profiles[0].sub_tipo_imovel != ''" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span :class="$css_show_title">Sub tipo Imóvel: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].sub_tipo_imovel }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].dormitorios && object.property_profiles[0].dormitorios > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Dorm: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].dormitorios }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].banheiros && object.property_profiles[0].banheiros > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Banheiros: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].banheiros }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].vagas && object.property_profiles[0].vagas > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Vagas: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].vagas }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].area && object.property_profiles[0].area > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Area: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].area }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].area_total && object.property_profiles[0].area_total > 0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Area total: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].area_total }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].valor_minimo && object.property_profiles[0].valor_minimo > 0" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <span :class="$css_show_title">Valor mínimo: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].valor_minimo | toCurrency }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].valor_maximo && object.property_profiles[0].valor_maximo > 0" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <span :class="$css_show_title">Valor máximo: </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].valor_maximo | toCurrency }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].aceita_permuta && object.property_profiles[0].aceita_permuta != ''"  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Aceita permuta? </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].aceita_permuta }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].aceita_financiamento && object.property_profiles[0].aceita_financiamento != ''"  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Aceita financiamento? </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].aceita_financiamento }}</span>
                                    </v-col>
                                    <v-col v-if="object.property_profiles[0].em_condominio && object.property_profiles[0].em_condominio != ''"  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Em condomínio? </span>
                                        <span :class="$css_show_description">{{ object.property_profiles[0].em_condominio }}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td> 
                    </tr>

                    <tr v-if="object.places && object.places.length > 0">
                        <td colspan="2" :class="$css_show_description">
                            <v-container class="ma-0 pa-1">
                                <v-row dense>
                                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <span :class="$css_show_title">Locais</span>  
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                        <v-chip
                                            small
                                            class="ma-1 caption"
                                            v-for="lc in object.places" :key="lc.id"    
                                        >
                                            {{ lc.distrito }} - {{ lc.cidade }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </td>
                    </tr>

                    <tr v-if="object.comments && object.comments.length > 0">
                        <td colspan="2" :class="$css_title_module">
                            Histórico
                        </td>
                    </tr>
                    <tr v-if="object.comments && object.comments.length > 0">
                        <td colspan="2">
                            <v-card
                                class="mx-auto ma-2"
                                outlined
                            >
                                <v-data-table
                                    hide-default-header
                                    :headers="headers.comments"
                                    :items="object.comments"
                                    :items-per-page="10"
                                    class="elevation-0 table-striped"
                                    :footer-props="{
                                    'items-per-page-text':'Itens por página',
                                    'items-per-page-options': [5, 10],
                                    'items-per-page-all-text': '',
                                    'page-text': '{0}-{1} de {2}'
                                }"
                                >
                                    <template v-slot:[`item.comment`]="{ item }">
                                        <span :class="$css_report_list">{{ item.user }} - {{ formatDateTime(item.created_at) }}</span> <br />
                                        <span :class="$css_report_list">{{ item.comment }}</span>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </td>
                    </tr>

                </tbody>
            </v-simple-table>
        </v-card>


        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="item.codigo">Código {{item.codigo}} - {{ item.tipo_imovel }} <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span> </span>
                    <v-spacer></v-spacer>
                    
                    <button-share-component class="mr-4" :object="item"></button-share-component>
                    
                    <v-btn icon @click="openCloseDialogShow()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-property :object="item" :permissions="permissions"></show-property>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->


    </div>
</template>

<script>
import PhoneComponent from '@/components/phone/PhoneComponent.vue'
import ButtonShareComponent from '@/components/button_share/ButtonShareComponent.vue'
import ShowProperty from '@/components/properties/show/ShowComponent.vue'

export default {
    components: {
        PhoneComponent,
        ButtonShareComponent,
        ShowProperty
    },

    created() {
        this.setUserPermission()
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    props: {
        object: {
            require: true
        }
    },

    data () {
        return {
            item: {},

            dialog: false,

            permissions: {
                store_property: true,
                active_property: true,
                update_active_property: true,
                view_owners_others: true,
                colaborator: true
            },

            style: {
                details: 'mt-0 ml-0 mb-0 mr-6 pa-0 pb-1'
            },

            headers:{
                property_relations: [
                    { text: 'PHOTO', align: 'start', value: 'photo', }, 
                    { text: 'DESCRIÇĀO', value: 'description', }     
                ],

                comments: [
                    { text: 'HISTORICO', align: 'start', value: 'comment', }  
                ]
            } 
        }
    },
    
    methods: {  
        setFirstPhoto(property_photos, condominium_photos){
            var photo = []
            if(property_photos && property_photos != ''){
                photo = property_photos
            }else{
                if(condominium_photos && condominium_photos != ''){
                    photo = condominium_photos
                }
            }
            return photo
        },

        showItem (id) {    
            this.dialog = false
            this.$store.dispatch('loadProperty', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog = true
                        })
                                   
        },

        openCloseDialogShow() {
            this.dialog = !this.dialog
        },

        setUserPermission(){
            this.permissions.view_owners_others = this.userPermission('Acesso aos proprietarios de outras carteiras')
        },
    }
}
</script>