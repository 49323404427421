export const NAME_TOKEN = 'TOKEN_AUTH'

var url = process.env.VUE_APP_ROOT_API

export const URL_BASE = `${url}/api/`
export const URL_UPLOAD = `${url}/storage/uploads/`
export const URL_IMAGE = `${url}/storage/images/`
 
// css
export const CSS = {
    report_list:'font-weight-regular',
    field: 'rounded-0 body-2',
    button: 'rounded-0 body-2',
    show_title: 'body-2 font-weight-bold',
    show_description: 'body-2',
    show_title_module: 'font-weight-medium body-2 text-center grey lighten-4',
    main_value: 'text-h',
    sub_value: 'body-2 grey-darken-1--text',
    subtitle_2: 'subtitle-2'
}
 