<template>
    <div> 
        <v-form ref="form">
            <v-row dense v-if="property && property.length == 0">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <alert-component :alert="alerts.property_first"></alert-component>
                </v-col>    
            </v-row>    
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="params_property.codigo"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Código do imóvel</span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-btn
                        dark
                        color="primary"
                        @click="loadProperties"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>    
            </v-row>
            <v-row dense>                
                <v-col v-if="property && property.length > 0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        v-model="property[0].complete_address"
                        outlined
                        dense
                        :class="$css_field"
                        filled
                        readonly    
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>      
            </v-row>

        <div v-if="client && client.length == 0 && property.length > 0">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <alert-component :alert="alerts.client_first"></alert-component>
                </v-col>    
            </v-row> 
        </div>

        <div v-if="property.length > 0">                
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="tipo_pessoa"
                        :items="items.tipo_pessoas"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo pessoa</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="tipo_pessoa_doc"
                        v-mask="tipo_pessoa == 'PF' ? ['###.###.###-##'] : ['##.###.###/####-##']"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">
                                <span v-if="tipo_pessoa == 'PF'">CPF</span>
                                <span v-if="tipo_pessoa == 'PJ'">CNPJ</span>
                            </span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-btn
                        dark
                        color="primary"
                        @click="loadClients"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>    
            </v-row>
        </div>            

        <div v-if="show.form">                  
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone2"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 2</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col> 
            </v-row>
            <v-row dense>
    
                <v-col v-if="tipo_pessoa == 'PF'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cpf"
                        v-mask="['###.###.###-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CPF</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="tipo_pessoa == 'PF'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.rg"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">RG</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="tipo_pessoa == 'PF'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_nascimento"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="item.data_nascimento"
                                label="Dt nascimento"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                :class="$css_field"
                            ></v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_nascimento" @input="calendar.data_nascimento = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col v-if="tipo_pessoa == 'PF'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nacionalidade"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nacionalidade</span>
                        </template>
                    </v-text-field>
                </v-col>



                <v-col v-if="tipo_pessoa == 'PJ'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cnpj"
                        v-mask="['##.###.###/####-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CNPJ</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="tipo_pessoa == 'PJ'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.inscricao_estadual"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Inscriçāo estadual</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cep"
                        @blur="searchCep(item.cep)"
                        v-mask="['#####-###']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CEP</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.endereco"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.bairro"
                        label="Bairro"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.cidade"
                        label="Cidade"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template> 
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.estado"
                        label="Estado"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.numero"
                        label="Número"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"                      
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.complemento"
                        label="Complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col>    
            </v-row>
        </div>

        <div v-if="show.form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.setor"
                        :items="items.setor"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Setor</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-currency-field
                        v-model="item.valor_proposta"
                        outlined
                        dense
                        :rules="[rules.required, rules.min]"
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Proposta</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col v-if="item.setor == 'Locação'" cols="12" xs="12" sm="12"  md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.garantia"
                        :items="items.garantias"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Garantia</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.proposta"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Proposta</span>
                        </template>
                    </v-textarea>
                </v-col>
            </v-row> 

            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>
        </div>             
        </v-form>       
    </div>
</template>

<script>
import { TIPO_PESSOA } from '../config_proposals/config_proposals'
import { GARANTIA_LOCACAO_ITEMS } from '../../properties/config_properties/config_properties.js'
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent,
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },
    
    watch: {
        data_nascimento () {
            this.item.data_nascimento = this.formatDate(this.data_nascimento)
        },
    }, 

    data () {
        return {
            property: [],
            client: [],
            tipo_pessoa: '',
            tipo_pessoa_doc: '',
            show: {
                form: false
            },

            alerts: {
                property_first: {
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['Informe o código do imóvel da proposta']
                },
                client_first: {
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['Informe o CPF ou CNPJ do cliente']
                },
            },

            params_property: {
                codigo: '',
                paginate: 'false',
            },

            params_client: {
                cpf: '',
                cnpj: '',
                paginate: 'false',
            },

            item: {
                data_nascimento: '',
                endereco: '',
                bairro: '',
                cidade: '',
                estado: ''
            },

            items: {
                garantias: GARANTIA_LOCACAO_ITEMS,
                tipo_pessoas: TIPO_PESSOA,
                setor: ['Venda','Locação']
            },
            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 7) || 'Mininmo de 7 numeros',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            data_nascimento: '',

            calendar: {
                data_nascimento: false,
            },
        }
    },

    methods: {
        loadProperties () {
            this.property = []
            this.$store.dispatch('loadPropertiesExcel', this.params_property) 
                        .then(response => {
                            this.property = response.data
                            if(this.property.length == 0){
                                this.$snotify.error('Imóvel não encontrado. Por favor, verifique se o código está correto.')
                            }
                        })
                        .catch(error => {
                            this.$snotify.error('Imóvel não encontrado')
                        })
        },

        loadClients () {
            this.clearClient()
            this.client = []
            this.show.form = false
            this.params_client.cpf = ''
            this.params_client.cnpj = '' 

            if(this.tipo_pessoa_doc){
                var doc = this.onlyNumber(this.tipo_pessoa_doc)
                if(this.tipo_pessoa == 'PF'){
                    this.params_client.cpf = doc
                    this.item.cpf = doc
                }
                if(this.tipo_pessoa == 'PJ'){
                    this.params_client.cnpj = doc
                    this.item.cnpj = doc
                }
            }

            this.$store.dispatch('loadClients', this.params_client) 
                        .then(response => {
                            this.client = response.data
                            if(this.client.length > 0){
                                this.setClient(this.client[0])
                            }
                            this.show.form = true
                        })
        },

        searchCep(value){
            if(!value) return ''
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setAddress(address){
            this.item.endereco = address.logradouro
            this.item.bairro = address.bairro
            this.item.cidade = address.localidade
            this.item.estado = address.uf
        },

        setClient(client){   
            this.item.nome = client.nome
            this.item.telefone = client.telefone
            this.item.telefone2 = client.telefone2
            this.item.email = client.email
            this.item.rg = client.rg 
            this.item.inscricao_estadual = client.inscricao_estadual
            this.item.data_nascimento = client.data_nascimento
            this.item.nacionalidade = client.nacionalidade
            this.item.profissao = client.profissao
            this.item.cep = client.cep,
            this.item.endereco = client.endereco,
            this.item.bairro = client.bairro,
            this.item.cidade = client.cidade,
            this.item.estado = client.estado,
            this.item.numero = client.numero,
            this.item.complemento = client.complemento
        },

        clearClient(){       
            this.item.nome = '',
            this.item.telefone = '',
            this.item.telefone2 = '',
            this.item.email = '',
            this.item.rg = '', 
            this.item.inscricao_estadual = '',
            this.item.data_nascimento = '',
            this.item.nacionalidade = '',
            this.item.cep = '',
            this.item.endereco = '',
            this.item.bairro = '',
            this.item.cidade = '',
            this.item.estado = '',
            this.item.numero = '',
            this.item.complemento = ''
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.store()
            }
        },

        store(){
            this.item.property_id = this.property[0].id
            const action = 'storeProposal'    
            this.$store.dispatch(action, this.item)
                        .then(response => {  
                            if(!response.data.id){
                                this.$snotify.error(response.data.error)
                            }else{
                                this.item = response.data
                                this.$snotify.success('Cadastro com sucesso!')
                                this.$emit('callLoadItem', this.item.id)
                            }
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                        }) 
                   
        }, 
        
    },
}
</script>

<style scoped>

</style>