import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'uploads'
const CONFIGS = {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
}

export default {
    state: {
        items: {
            data: []
        }
    },

    mutations: {
        LOAD_UPLOADS (state, uploads) {
            state.items = uploads
        }
    },

    actions: {
        loadUploads(context, params) {

            if(!params.nopreload)
                context.commit('PRELOADER', true)

            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then( response => { context.commit('LOAD_UPLOADS', response.data) })
                .catch(function(error) {
                    if (error.response && error.response.status === 401) {
                      window.location.href = "login";
                    }
                })
                .finally(() => context.commit('PRELOADER', false))
        },

        storeUpload (context, params) {
            context.commit('PRELOADER', true)

            return new Promise((resolve, reject) => {

                axios.post(`${URL_BASE}${RESOURCE}`, params, CONFIGS)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))

            })

        },

        storeUploadNew (context, params) {
           
            return new Promise((resolve, reject) => {

                axios.post(`${URL_BASE}${RESOURCE}`, params, CONFIGS)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))

            })

        },

        loadUpload(context, id) {

            context.commit('PRELOADER', true)

            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })

        },

        updateUpload(context, params){

            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.put(`${URL_BASE}${RESOURCE}/${params.id}`, params)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        updatePhoto(context, params){

            return new Promise((resolve, reject) => {
                axios.put(`${URL_BASE}${RESOURCE}/${params.id}`, params)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => '')
            })
        },

        destroyUpload(context, id){
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },        

        massDestroyUpload (context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}massdestroyuploads`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))

            })
            
        },

        massUpdateUpload (context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.post(`${URL_BASE}mass_update_uploads`, params)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        setPhotoFinal (context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}setphotofinal`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))

            })
            
        },
    },

    getters: {

    }

}
