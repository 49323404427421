<template>
    <div>
        <v-card
            class="scroll pa-0 rounded-0"
            max-height="200"
            min-height="200"
            outlined
            id="table_border"
        >
            <v-simple-table dense class="rounded-0">
                <thead fixed-header>
                    <tr>
                        <th colspan="2" :class="$css_title_module">
                            Contatos
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <v-data-table
                        :headers="headers"
                        :items="object"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        hide-default-footer
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.nome`]="{ item }">
                            <div :class="$css_report_list">{{ item.nome }}</div>
                            <div class="caption">{{ item.email }}</div>
                        </template>

                        <template v-slot:[`item.telefone`]="{ item }">
                            <span :class="$css_report_list">{{ item.telefone }}</span>
                            <span v-if="item.telefone2" :class="$css_report_list"> / {{ item.telefone2 }}</span>
                        </template>
                    </v-data-table>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true
        }
    },

    data () {
        return{
            headers: [
                { text: 'NOME / EMAIL', align: 'start', value: 'nome', },
                { text: 'TELEFONE', align: 'start', value: 'telefone', }
            ],
        }
    }
}
</script>