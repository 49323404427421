<template>
  <div>
    <v-breadcrumbs :items="object">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                :to="item.href"
                class="text-subtitle-2 crumb-item"
                :disabled="item.disabled"
                exact
            >
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
  </v-breadcrumbs>
  </div>
</template>

<script>
    export default {
        props: {
            object: {}
        },
    }
</script>

