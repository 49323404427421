<template>
    <div> 
        <alert-component v-if="!item.id && total >= tenant[0].app_plan.builders" :alert="alerts.limit"></alert-component>

        <v-form ref="form" v-if="item.id || total < tenant[0].app_plan.builders">
            <v-row dense>
                <v-col v-if="item.id" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cep"
                        @blur="searchCep(item.cep)"
                        :rules="[rules.required]"
                        v-mask="['#####-###']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CEP</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.endereco"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.bairro"
                        label="Bairro"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.cidade"
                        label="Cidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template> 
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.estado"
                        label="Estado"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.numero"
                        label="Número"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.complemento"
                        label="Complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col>    
            </v-row>
            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>
import { STATUS_ITEMS } from '../config_builders/config_builders.js' 
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent,
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    computed: {
        tenant() {
            return this.$store.state.tenants.items.data    
        },

        me () {
          return this.$store.state.auth.me
        },
    }, 

    data () {
        return {
            alerts: {
                limit: {
                    show: '',
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['O limite de cadastro foi atingido. Entre em contato com AYT para migrar de plano ou exclua alguns registros desnecessários.']
                },
            },

            total: 0,
            item: {
                descricao: '',
                email: '',
                telefone1: '',
                telefone2: '',
                endereco: '',
                bairro: '',
                cidade: '',
                estado: '',   
            },

            items: {
                status: STATUS_ITEMS
            },

            params: {
                paginate: 10,
            },
            
            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            params_branch: {
                paginate: 'false'
            }
        }
    },

    methods: {
        searchCep(value){
            //this.setNull()
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setNull(){
            this.item.endereco = ''
            this.item.bairro = ''
            this.item.cidade = ''
            this.item.estado = ''
        },

        setAddress(address){
            this.item.endereco = address.logradouro
            this.item.bairro = address.bairro
            this.item.cidade = address.localidade
            this.item.estado = address.uf
        },

        setObject(){
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }else{
                this.loadPromisseItems()
            }
        },

        loadPromisseItems(){
            this.$store.dispatch('loadPromisseBuilders', this.params)
                        .then(response => {
                           this.total = response.meta.total
                        })
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            item.cep = this.onlyNumber(item.cep) 
            const action = item.id ? 'updateBuilder' : 'storeBuilder' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            if(action == 'storeBuilder'){
                                this.$store.commit('STORE_BUILDER', true)
                            }
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        }) 
                   
        }, 
        
    },
}
</script>

<style scoped>

</style>