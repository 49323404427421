import axios from "axios"

export default {
    actions: {
        loadCep(context, cep) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`https://viacep.com.br/ws/` + cep + `/json/`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
    },

    getters: {

    }

}