import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'permissions'

export default {

    actions: {
        storePermission(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.post(`${URL_BASE}${RESOURCE}`, params)
                    .then(response => resolve(response.data))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
    },

    getters: {

    }

}