<template>
    <div v-if="!object.id" class="mt-8">
        <new-component @callLoadItem="loadItem"></new-component>
    </div>
    <div v-else>
        <v-tabs
            v-model="active_tab"
            show-arrows 

        >
            <v-tab href="#tab-1" class="caption"> Principal</v-tab>
            <v-tab href="#tab-2" class="caption"> Residentes </v-tab>
        </v-tabs>
    

        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="item" @callLoadItem="loadItem" ref="main"></main-component>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <resident-component :object="residents" @callLoadItem="loadItem" ref="residents"></resident-component>
                </v-card>    
            </v-tab-item>                

        </v-tabs-items>

    </div>    
</template>


<script>
import ResidentComponent from '@/components/residents/ResidentComponent.vue'
import MainComponent from './MainComponent.vue'
import NewComponent from './NewComponent.vue'


export default {
    created() {
        this.setTab()
        this.setObject()
    },

    components: {
       MainComponent,
       NewComponent,
       ResidentComponent
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.item.id != this.object.id){
                this.setTab()
            }
            this.setObject()
        },
        
        item () {   
            this.setObject()
        }
    },
    
    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    data () {
        return {
            active_tab: 'tab-1',
            item: {},

            residents: {
                model: "proposals",
                item_id: "",
                items: []     
            },
        }
    },

    methods: {
        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        setObject(){
            this.item = this.object
            // residents
            this.residents.item_id = this.item.id
            this.residents.items = this.item.residents

        },
        
    },
}
</script>

<style scoped>

</style>