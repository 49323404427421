var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list',[_c('v-list-item',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-avatar',{staticClass:"mt-4",attrs:{"color":"#BDBDBD","size":"100"}},[(_vm.me.uploads && _vm.me.uploads.length > 0)?_c('v-img',{attrs:{"src":_vm._f("urlUpload")(_vm.me.uploads[0]),"lazy-src":_vm._f("urlUpload")(_vm.me.uploads[0])},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}):_c('v-icon',{attrs:{"size":"80","dark":""}},[_vm._v(" mdi-account-circle-outline ")])],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h7"},[_vm._v(" "+_vm._s(_vm.me.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.me.email)+" ")]),_c('div',{staticClass:"mt-2 text-center"},[_c('router-link',{attrs:{"to":{ 
                                name: 'admin.users.index', 
                                params: {
                                    id: _vm.me.id                                
                                } 
                            }}},[_c('v-chip',{staticClass:"ml-3",attrs:{"label":"","small":"","color":"blue lighten-5"}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("EDITAR")])])],1)],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_vm._l((_vm.single_items),function(item,i){return _c('span',{key:i},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                    name: item.link,
                    params: item.params 
                }}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)],1)],1)}),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-login-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Sair")])],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }