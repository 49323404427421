<template>
    <div>
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-3 ma-0"
                    min-height="100"
                >
                    <v-card-title class="pa-0 ma-0 font-weight-light body-2"> 
                        <v-icon small class="mr-2">mdi-home-city</v-icon>
                        Imóveis
                    </v-card-title>
                        <v-row dense class="mt-1">
                            <v-col v-for="dash in dashboard.properties" :key="dash.status" class="text-h4 font-weight-light text-left">
                                <router-link v-if="dash.total > 0" :to="{ 
                                    name: 'admin.properties.index', 
                                    params: {
                                        status: dash.status,  
                                        multiple_colaborator: me.profile == 'Corretor' && dash.status != 'Ativo' ? [me.id] : ''                           
                                    } 
                                }">
                                    <span :class="dash.status | statusProperty('--text')">{{ dash.total }}</span>
                                    
                                    <span class="ml-2 caption black--text">{{ dash.status }}</span>    
                                </router-link>
                            </v-col> 
                            <v-col v-if="dashboard.venda_recentes && dashboard.venda_recentes[0].total > 0" class="text-h4 font-weight-light text-left"> 
                                    <router-link :to="{ 
                                        name: 'admin.properties.index', 
                                        params: {
                                            status: 'Ativo', 
                                            setor: 'Venda', 
                                            data_cadastro_to: dashboard.venda_recentes[0].data_cadastro_to,
                                            data_cadastro_from: dashboard.venda_recentes[0].data_cadastro_from                          
                                        } 
                                    }">
                                        <span>{{ dashboard.venda_recentes[0].total }}</span>
                                    </router-link>                                        
                                    
                                    <span class="ml-2 caption black--text">Recente Vendas</span>    
                            </v-col>
                            <v-col v-if="dashboard.locacao_recentes && dashboard.locacao_recentes[0].total > 0" class="text-h4 font-weight-light text-left"> 
                                    <router-link :to="{ 
                                        name: 'admin.properties.index', 
                                        params: {
                                            status: 'Ativo', 
                                            setor: 'Locacao', 
                                            data_cadastro_to: dashboard.locacao_recentes[0].data_cadastro_to,
                                            data_cadastro_from: dashboard.locacao_recentes[0].data_cadastro_from                          
                                        } 
                                    }">
                                        <span>{{ dashboard.locacao_recentes[0].total }}</span>
                                    </router-link>                                        
                                    
                                    <span class="ml-2 caption black--text">Recente Locação</span>    
                            </v-col>       
                        </v-row>    
                    </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    computed: {
        dashboard() {
            return this.$store.state.dashboard.items.data    
        },

        me () {
          return this.$store.state.auth.me
        },
    },   
}
</script>

