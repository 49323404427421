<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
       <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-4 ma-0"

                >  
                    <bread-crumb-component :object="breadcrumbs"></bread-crumb-component> 
                </v-card>
            </v-col>
        </v-row>
         
        <div>
            <v-row dense class="mt-4 text-right">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    <v-btn
                        dark
                        color="primary"
                        @click="dialogEmail()"
                        :class="$css_button"
                    >
                        Enviar por e-mail
                        <v-icon
                            right
                            dark
                        >
                            mdi-email
                        </v-icon>
                    </v-btn>

                    <v-btn
                        dark
                        color="black"
                        @click="removeAllFavorite()"
                        :class="$css_button"
                    >
                        Limpar favoritos
                        <v-icon
                            right
                            dark
                        >
                            mdi-delete
                        </v-icon>
                    </v-btn>    

                    </v-card-actions>
                </v-col>    
            </v-row>    
            <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                <v-data-table
                    :headers="headers"
                    :items="favorites"
                    :options.sync="options"
                    class="elevation-1 table-striped caption"
                    :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                >
                    
                    <template v-slot:[`item.foto`]="{ item }">
                        <v-avatar
                            class="ma-2"
                            color="#BDBDBD"
                            tile
                            width="200px"
                            height="150px"
                            @click.prevent="showCarousel(item)"
                        >
                            <v-img 

                                v-if="item.photos_total && item.photos_total.length > 0"
                                :src="item.photos_total[0] | urlUpload"
                                :lazy-src="item.photos_total[0] | urlUpload"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-icon v-else large dark>
                                mdi-camera
                            </v-icon>
                        </v-avatar>
                    </template>
                    
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            :color="item.status | statusProperty"
                            label
                            small
                        >
                            {{ item.status }}
                        </v-chip> 
                    </template>

                    <template v-slot:[`item.detalhes`]="{ item }">
                        <div class="mb-2">
                            <span>
                                <v-chip
                                    label
                                    small
                                    color="blue lighten-5" 
                                >
                                   <span style="font-size:12px">{{ item.codigo }}</span> 
                                </v-chip>   
                            </span>
                            <span class="ml-2">
                                <v-chip
                                    :color="item.status | statusProperty"
                                    label
                                    small
                                >
                                    {{ item.status }}
                                </v-chip> 
                            </span>
                            
                            <span class="ml-2 black--text font-weight-medium">
                                {{ item.tipo_imovel }}
                                <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span>
                            </span>
                        </div>
                        <div>
                            {{ item.endereco }}
                            <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                            <span v-if="item.andar">  - {{ item.andar }} andar </span>
                            <span v-if="item.tower && item.tower.nome != ''"> - Torre: {{ item.tower.nome }} </span>
                            <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                            <!--span v-if="item.position && item.position.nome != ''"> - Posiçāo: {{ item.position.nome }} </span-->
                            <span v-if="item.bairro">  - {{ item.bairro }} </span>
                            <span v-if="item.cidade">  - {{ item.cidade }} </span>
                            <span v-if="item.estado">  - {{ item.estado }} </span>
                            <span v-if="item.cep">  - {{ item.cep | VMask('#####-###') }} </span>
                        </div> 
                        <div class="mt-3">
                            <span v-if="item.area">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5" 
                                >
                                   <span>Área {{ item.area }} m²</span> 
                                </v-chip>
                            </span>
                            <span class="ml-2" v-if="item.dormitorios">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5" 
                                >
                                   <span>{{ item.dormitorios }} dorm</span> 
                                </v-chip>
                            </span>
                            <span class="ml-2" v-if="item.banheiros">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5" 
                                >
                                   <span>{{ item.banheiros }} banheiros</span> 
                                </v-chip>
                            </span>
                            <span class="ml-2" v-if="item.vagas">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5" 
                                >
                                   <span>{{ item.vagas }} vagas</span> 
                                </v-chip>
                            </span>
                        </div>

                    </template>
                    

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-card-actions>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-if="item.photos_total && item.photos_total.length > 0" @click.prevent="showCarousel(item)" class="ml-2">
                                        <v-icon v-bind="attrs" v-on="on">mdi-camera</v-icon>
                                    </v-btn>
                                </template>
                                <span>Slide Show</span>
                            </v-tooltip>
                            
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="showItem(item.id)" class="ml-2">
                                        <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Visualizar</span>
                            </v-tooltip>

                            <button-share-component class="ml-2" :object="item"></button-share-component>    

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="removeFavorite(item)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir dos favoritos</span>
                            </v-tooltip>
                        </v-card-actions>     
                    </template> 
                </v-data-table>
               
                </v-card>
            </v-col>
        </v-row>
        </div>

        <!-- Dialog -->
        <v-dialog 
            v-model="dialog.email" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Enviar anúncio por e-mail
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogEmail()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <send-property-mail></send-property-mail>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <v-dialog
            v-model="dialog.carousel"
            width="800"
            overlay-opacity="0.8"
        >
            <v-card class="d-print-none">
                <div class="text-right">
                    <v-btn icon text class="mr-8" @click="openCloseDialogCarousel()">
                        fechar <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <carousel-component :object="item" @callOpenCloseCarousel="openCloseDialogCarousel" :key="key.carousel"></carousel-component>
            </v-card> 
        </v-dialog>


        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog.show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            class="d-print-none" 
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    


            <v-card>
                <v-card-title class="text-h5">
                    <span v-if="item.codigo">Código {{item.codigo}} - {{ item.tipo_imovel }} <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span> </span>
                    <v-spacer></v-spacer>    
                    <v-btn icon @click="openCloseDialogShow()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-component :object="item" :permissions="permissions"></show-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->


    </div>
</template>

<script>
import ButtonShareComponent from '../button_share/ButtonShareComponent.vue'
import BreadCrumbComponent from '../../layouts/BreadCrumbComponent.vue'
import SendPropertyMail from './partials/SendPropertyMail.vue'
import CarouselComponent from '../carousel/CarouselComponent.vue'
import ShowComponent from '../properties/show/ShowComponent.vue'

export default {
    components:{
        ButtonShareComponent,
        BreadCrumbComponent,
        SendPropertyMail,
        CarouselComponent,
        ShowComponent
    },

    created() {
        this.setUserPermission()
    },
    
   
    computed: {
        favorites () {
            return this.$store.state.favorites.items.data
        },

        me () {
          return this.$store.state.auth.me
        },
    },    

    data () {
        return {
            item: '',
            permissions: {
                store_property: true,
                active_property: true,
                update_active_property: true,
                view_owners_others: true,
                colaborator: true
            },
            dialog: {
                email: false,
                carousel: false,
                show: false,
            },
            key: {
                carousel: 1
            },

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'admin',
                },
                {
                    text: 'Favoritos',
                    disabled: true,
                    href: 'favorites',
                },
            ],

            options: {
                page: 1,
                itemsPerPage: 20,
            },

            headers: [
                { text: 'FOTO', value: 'foto' , sortable: false},
                { text: 'DETALHES', value: 'detalhes' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

        }
    },


    methods: {
        removeFavorite(item){
            var check = this.favorites.findIndex(search => search.id == item.id)
            if(check === -1){
                this.$store.commit('ADD_FAVORITE', item)
            }else{
                this.$store.commit('REMOVE_FAVORITE', check)
            }
        },

        dialogEmail(){
            this.dialog.email = !this.dialog.email
        },

        removeAllFavorite(){
            this.$store.commit('REMOVE_ALL')
        },
        
        showCarousel(item){
            this.item = item
            this.openCloseDialogCarousel()
        },

        openCloseDialogCarousel(){
            this.dialog.carousel = !this.dialog.carousel
        },

        openCloseDialogShow(){
            this.dialog.show = !this.dialog.show
        },

        showItem (id) {    
            this.dialog.carousel = false
            this.dialog.show = false 
            this.$store.dispatch('loadProperty', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog.show = true
                            this.checkColaborator()
                        })
                                   
        },

        checkColaborator(){
            if(this.me.profile != 'Master' && this.me.profile != 'Gerente'){
                if(this.item && this.item.colaborators.length > 0){
                    var access = false
                    this.item.colaborators.forEach(i => {
                        if(i.user_id == this.me.id){
                            access = true
                        }
                    })
                    this.permissions.colaborator = access
                }
            }
        },

        setUserPermission(){
            this.permissions.store_property = this.userPermission('Cadastra imóvel')
            this.permissions.active_property = this.userPermission('Ativa imóvel da carteira')
            this.permissions.update_active_property = this.userPermission('Edita imóvel ativo da carteira')
            this.permissions.view_owners_others = this.userPermission('Acesso aos proprietarios de outras carteiras')
        },
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>