<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-0 ma-0"
                >        
                    <v-expansion-panels class="pa-0 ma-0" flat v-model="panel_search" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header hide-actions>
                                <bread-crumb-component :object="breadcrumbs"></bread-crumb-component>     
                                
                                <span class="text-right">
                                    <v-tooltip left class="text-right">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-if="$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                            <v-btn
                                                v-else
                                                color="pink"
                                                outlined
                                                class="ma-2"
                                            >
                                                <v-icon v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                                FILTROS
                                            </v-btn>
                                        </template>

                                        <span>Filtros</span>
                                    </v-tooltip>  
                                </span> 
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>                                
                                <filter-component @callLoadItems="loadItems"></filter-component>    
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
         
        <div>
            <report-component v-if="listitems" :object="listitems" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" ref="report"></report-component>
        </div>

        <div class="mb-6">&nbsp;</div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog.edit" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Solicitação

                    <span v-if="item.codigo" class="ml-2">- Código {{item.codigo}}  </span>
                    <span v-else class="ml-2"> Nova</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog('edit')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" @callLoadItem="loadItem"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'
import FilterComponent from './FilterComponent.vue'

export default {
    components:{
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent,
        FilterComponent,
    },
    
    created() {
        this.loadItems()
    },

    computed: {
        listitems() {
            return this.$store.state.user_requests.items    
        },

        me () {
          return this.$store.state.auth.me
        }
    },    

    data () {
        return {
            item: {},

            dialog: {
                edit: false,
            },
            
            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Solicitações',
                    disabled: true,
                    href: 'userrequests',
                },
            ],

            panel_search: [],

            params: {
                id: '',
                status: '',
                multiple_status: []
            },
        }
    },


    methods: {
       
        loadItems (params = '') {
          
            if(this.$route.params.status && this.$route.params.status != ''){
                this.params.status = this.$route.params.status
                this.$route.params.status = ''
            }

            if(this.params.status == ''){
                this.params.multiple_status = ['Novo','Pendente']
            }
            
            if(params != ''){
                this.params = {} 
                this.params = params
            }
            
            this.$store.dispatch('loadUserRequests', this.params) 
            this.panel_search = []
        },

        loadItem (id) {   
            this.$store.dispatch('loadUserRequest', id) 
                        .then(response => {
                            this.item = response.data
                        })
                                   
        },
        
        editItem (id) {   
            this.$store.dispatch('loadUserRequest', id) 
                        .then(response => {
                            this.item = response.data
                            this.openCloseDialog('edit')
                        })
                                   
        },

        openCloseDialog(dialog){
            if(dialog == 'edit'){
                this.dialog.edit = !this.dialog.edit
            }

            if(this.dialog.edit == false){
                this.loadItems(this.params)
            }
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>