import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth/auth.js'
import preloader from './modules/preloader/preloader.js'
import uploads from './modules/uploads/uploads.js'
import changepassword from './modules/changepassword/changepassword.js'
import branches from './modules/branches/branches.js'
import users from './modules/users/users.js'
import tenants from './modules/tenants/tenants.js'
import ceps from './modules/ceps/ceps.js'
import texts from './modules/texts/texts.js'
import builders from './modules/builders/builders.js'
import contacts from './modules/contacts/contacts.js'
import searchproperties from './modules/searchproperties/searchproperties.js'
import permissions from './modules/permissions/permissions.js'
import zonings from './modules/zonings/zonings.js'
import placeitems from './modules/placeitems/placeitems.js'
import places from './modules/places/places.js'
import dashboard from './modules/dashboard/dashboard.js'
import condominiums from './modules/condominiums/condominiums.js'
import plans from './modules/plans/plans.js'
import privateareas from './modules/privateareas/privateareas.js'
import towers from './modules/towers/towers.js'
import positions from './modules/positions/positions.js'
import infrastructures from './modules/infrastructures/infrastructures.js'
import recreations from './modules/recreations/recreations.js'
import colaborators from './modules/colaborators/colaborators.js'
import properties from './modules/properties/properties.js'
import videos from './modules/videos/videos.js'
import boards from './modules/boards/boards.js'
import portal_items from './modules/portal_items/portal_items.js'
import portals from './modules/portals/portals.js'
import portal_rules from './modules/portal_rules/portal_rules.js'
import makexml from './modules/makexml/makexml.js'
import propertyowners from './modules/propertyowners/propertyowners.js' 
import clients from './modules/clients/clients.js'
import favorites from './modules/favorites/favorites.js'
import facs from './modules/facs/facs.js'
import howtoknowitems from './modules/howtoknowitems/howtoknowitems.js' 
import property_relations from './modules/property_relations/property_relations.js'
import comments from './modules/comments/comments.js'
import propertyprofiles from './modules/propertyprofiles/propertyprofiles.js'
import events from './modules/events/events.js'
import shop_windows from './modules/shop_windows/shop_windows.js'
import socialmedias from './modules/social_medias/social_medias.js'
import closerequestpassword from './modules/closerequestpassword/closerequestpassword.js'
import proposals from './modules/proposals/proposals.js'
import residents from './modules/residents/residents.js'
import companies from './modules/companies/companies.js'
import download from './modules/download/download.js'
import user_requests from './modules/user_requests/user_requests.js'
import imovelweb from './modules/imovelweb/imovelweb.js'
import accounts from './modules/accounts/accounts.js'
import bank_slips from './modules/bank_slips/bank_slips.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        preloader,
        uploads,
        changepassword,
        users,
        branches,
        tenants,
        ceps,
        texts,
        builders,
        contacts,
        searchproperties,
        permissions,
        zonings,
        placeitems,
        places,
        dashboard,
        condominiums,
        plans,
        privateareas,
        towers,
        positions,
        infrastructures,
        recreations,
        colaborators,
        properties,
        videos,
        boards,
        portal_items,
        portals,
        portal_rules,
        makexml,
        propertyowners,
        clients,
        favorites,
        facs,
        howtoknowitems,
        property_relations,
        comments,
        propertyprofiles,
        events,
        shop_windows,
        socialmedias,
        closerequestpassword,
        proposals,
        residents,
        companies,
        download,
        user_requests,
        imovelweb,
        accounts,
        bank_slips
    }
})

export default store
