<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="tipo_pessoa"
                        :items="items.tipo_pessoas"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo pessoa</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="tipo_pessoa_doc"
                        v-mask="tipo_pessoa == 'PF' ? ['###.###.###-##'] : ['##.###.###/####-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">
                                <span v-if="tipo_pessoa == 'PF'">CPF</span>
                                <span v-if="tipo_pessoa == 'PJ'">CNPJ</span>
                            </span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.parentesco"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">
                                <span>Parentesco / Relação</span>
                            </span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.principal"
                        :items="items.principal"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Principal</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        Adicionar
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.documento`]="{ item }">
                            <span :class="$css_report_list">
                                <span v-if="item.client.cpf">{{ item.client.cpf }}</span>
                                <span v-if="item.client.cnpj">{{ item.client.cnpj }}</span>
                            </span>
                        </template>

                        <template v-slot:[`item.nome`]="{ item }">
                            <span :class="$css_report_list">{{ item.client.nome }}</span>
                        </template>

                        <template v-slot:[`item.principal`]="{ item }">
                            <span :class="$css_report_list">{{ item.principal }}</span>
                        </template>

                        <template v-slot:[`item.parentesco`]="{ item }">
                            <span :class="$css_report_list">{{ item.parentesco }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>
                                
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroyResident(item)" class="ml-3">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row> 

        <!-- Dialog -->
        <v-dialog 
            v-if="resident && resident != ''"
            v-model="dialog.edit" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="purple"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Cliente
                    <span v-if="client.codigo" class="ml-2">- Código {{client.codigo}} </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog('edit')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="resident" @callLoadItem="loadItem"></insert-edit-component>  
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>


    </div>
</template>

<script>
import { TIPO_PESSOA } from '@/components/proposals/config_proposals/config_proposals.js'
import InsertEditComponent from './partials/InsertEditComponent.vue'

export default {
    components: {
        InsertEditComponent
    },

    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            tipo_pessoa: 'PF',
            tipo_pessoa_doc: '',
            resident: {},
            client: {},
            item: {
                principal: 'Não'
            },
            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },
            dialog: {
                edit: false
            },

            items: {
                tipo_pessoas: TIPO_PESSOA,
                setor: ['Venda','Locação'],
                principal: ['Sim','Não']
            },

            headers: [
                { text: 'DOCUMENTO', align: 'start', value: 'documento' },
                { text: 'NOME', value: 'nome' },
                { text: 'PRINCIPAL', value: 'principal' },
                { text: 'PARENTESCO / RELAÇÃO',  value: 'parentesco' },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],
        }
    },

    methods:{   
        validate () {
            if (this.$refs.form.validate()) {       
                this.store()
            }
        },
        
        store(){
            this.item.tipopessoa = this.tipo_pessoa
            if(this.item.tipopessoa == 'PF'){
                this.item.cpf = this.tipo_pessoa_doc
                this.item.cnpj = ''
            }
            if(this.item.tipopessoa == 'PJ'){
                this.item.cnpj = this.tipo_pessoa_doc
                this.item.cpf = ''
            }
            this.item.model = this.object.model
            this.item.item_id = this.object.item_id

            this.$store.dispatch('storeResident', this.item)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                            this.$snotify.success('Registered Successfully!')
                            this.reset()
                        })
                        .catch(error => {   
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })  
        },

        reset(){
            this.item = {}
            this.tipo_pessoa = 'PF',
            this.tipo_pessoa_doc = '',
            this.item.cpf = ''
            this.item.cnpj = ''
            this.$refs.form.reset()
        },

        destroyResident(item){
            this.$store.dispatch('destroyResident', item.id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Excluido com sucesso')
                        )
        },

        loadItem(id){
            this.$store.dispatch('loadResident', id) 
                        .then(response => {
                            this.resident = response.data
                            this.client = this.resident.client
                        })
        },

        editItem (id) {   
            this.resident = {}
            this.dialog.edit = false 
            this.$store.dispatch('loadResident', id) 
                        .then(response => {
                            this.resident = response.data
                            this.client = this.resident.client
                            this.dialog.edit = true
                        })
                                   
        },

        loadResident (id) {   
            this.resident = {}
            this.$store.dispatch('loadResident', id) 
                        .then(response => {
                            this.resident = response.data
                            this.client = this.resident.client
                        })
                                   
        },

        openCloseDialog(dialog){
            if(dialog == 'edit'){
                this.dialog.edit = !this.dialog.edit
                if(this.dialog.edit == false){
                    this.$emit('callLoadItem',this.object.item_id)
                }
            }
            this.resident = ''
            this.client = ''
        }
    }    
}
</script>