import axios from "axios"

const CONFIGS = {
    headers: {
        'responseType': 'blob',
    }
}

export default {
    actions: {
        dowloadFile(context, urlfile) {
            axios.get(urlfile, {responseType: 'blob'})
            .then(response => {
                console.log(response)
                //saveAs(response.data, 'downloaded-file.pdf');
            })
            /**
            axios({                     
                url: urlfile,                     
                method: 'GET',                     
                responseType: 'blob',                 
            }).then((response) => {                      
                var fileURL = window.URL.createObjectURL(new 
                Blob([response.data]));                      
                var fileLink = document.createElement('a');  
                fileLink.href = fileURL;     
                fileLink.setAttribute('download', 'file.pdf');  
                document.body.appendChild(fileLink); 
                fileLink.click();                 
            });   
            */
        }
    },

    getters: {

    }

}