<template>
    <div>
        <v-bottom-sheet>
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" >{{ object | VMask(maskPhone(object)) }}</span>
            </template>
            <v-list>
                <v-subheader>Ações</v-subheader>
                <v-list-item
                    v-for="tile in items.actions"
                    :key="tile.title"
                >
                    <v-list-item-avatar>
                        <v-avatar
                            size="32px"
                            tile
                        >
                            
                            <img
                                :src="`img/share-buttons/${tile.img}`"
                                :alt="tile.title"
                            >
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-title>
                        <a v-if="tile.type == 'link'" :href="`${tile.value}`" target="_blank">
                            {{ tile.title }}
                        </a>
                        <span v-else>
                            <span v-if="tile.type == 'copyLink'" @click="copyLink(tile.value)">
                                {{ tile.title }} 
                            </span>
                        </span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-bottom-sheet>
    </div>
</template>

<script>

export default {

    components: {
    },

    props: {
        object: {
            require: true,
        },
    }, 

    watch: {
        object: {
            handler:function() {
                this.setObject()
            },
            deep:true
        },
    },

    data () {
        return {
            items: {
                actions: [
                    {id:'1', type:'link', value:'tel:' + this.object, img:'phone-360.png', title: 'Telefone'},
                    {id:'2', type:'link', value:'https://api.whatsapp.com/send?phone=55' + this.object, img:'whatsapp-360.png', title: 'WhatsApp'},
                    {id:'3', type:'copyLink', value: this.object, img:'copy-360.png', title: 'Copiar'}
                ]
            },
        }    
    },

    methods: {
        copyLink(link){
            navigator.clipboard.writeText(link);
            this.$snotify.success(`Número de telefone copiado. Utilize o CTRL + V para colar`, 'Telefone')
        },

        setObject(){
            this.items.actions = [
                    {id:'1', type:'link', value:'tel:' + this.object, img:'phone-360.png', title: 'Telefone'},
                    {id:'2', type:'link', value:'https://api.whatsapp.com/send?phone=55' + this.object, img:'whatsapp-360.png', title: 'WhatsApp'},
                    {id:'3', type:'copyLink', value: this.object, img:'copy-360.png', title: 'Copiar'}
                ]
        }
    },
}
</script>