<template>
    <div class="pa-4">
        <v-form ref="form" class="p-2">
            <v-row dense>
                <v-col v-if="object.modelos && object.modelos.length > 0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-select
                        v-model="item.modelo"
                        :items="object.modelos"
                        outlined
                        dense
                        :rules="[rules.required]"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Modelo do anúncio</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.items"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Lista de códigos imóveis separados por virgula</span>
                        </template>
                    </v-textarea>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                        text
                    >
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form> 
    </div>
</template>

<script>

export default {

    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
           item: {},

            rules: {
                required: value => !!value || 'Obrigatório'
            },
        }
    },
    
    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.store(this.item)
            }
        },

        store(item){
            item.portal_item_id = this.object.portal_item_id
            this.$store.dispatch('portalliststore', item)
                        .then(response => {
                            this.$snotify.warning('A marcação dos imóveis está em andamento. O processo poderá levar até 3 minutos.')
                            this.clear()
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        }, 

        clear() {
            this.$refs.form.reset()
        },
    },
}
/* 
items: "ap0001, ap0002"
modelo: "Padrão"
portal_item_id: "05347102-0dab-4fe0-958e-689bbd495f87"
*/

</script>