<template>
    <div v-if="!object.id" class="mt-8">
        <main-component :object="item" @callLoadItem="loadItem"></main-component>
    </div>
    <div v-else>
        <v-tabs
            v-model="active_tab"
            show-arrows 

        >
            <v-tab href="#tab-1" class="caption"> Principal</v-tab>
            <v-tab href="#tab-2" v-show="item.id" class="caption">Contatos</v-tab>
            <v-tab href="#tab-3" v-show="item.id" class="caption">Área / Imovel desejado</v-tab>
            <v-tab href="#tab-4" v-show="item.id" class="caption">Zoneamentos</v-tab>
            <v-tab href="#tab-5" v-show="item.id" class="caption">Locais</v-tab>
            <v-tab href="#tab-6" v-show="item.id" class="caption">Observações</v-tab>
        </v-tabs>
    

        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="item" @callLoadItem="loadItem"></main-component>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <contact-component :object="contacts" @callLoadItem="loadItem"></contact-component>
                </v-card>    
            </v-tab-item>                

            <v-tab-item value="tab-3">
                <v-card flat class="pa-8">
                    <search-property-component :object="search_properties" @callLoadItem="loadItem"></search-property-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-4">
                <v-card flat class="pa-8">
                    <zoning-component :object="zonings" @callLoadItem="loadItem"></zoning-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-5">
                <v-card flat class="pa-8">
                    <place-component :object="places" @callLoadItem="loadItem"></place-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-6">
                <v-card flat class="pa-8">
                    <text-component :object="texts" @callLoadItem="loadItem"></text-component>
                </v-card>    
            </v-tab-item>
        </v-tabs-items>

    </div>    
</template>


<script>
import { URL_UPLOAD } from '../../../config/config.js'
import MainComponent from './MainComponent.vue'
import UploadComponent from '../../upload/UploadComponent.vue'
import ContactComponent from '../../contacts/ContactComponent.vue'
import SearchPropertyComponent from '../../searchproperties/SearchPropertyComponent.vue'
import ZoningComponent from '../../zonings/ZoningComponent.vue'
import PlaceComponent from '../../places/PlaceComponent.vue'
import TextComponent from '../../text/TextComponent.vue'

export default {
    created() {
        this.setTab()
        this.setObject()
    },

    components: {
       MainComponent,
       UploadComponent,
       ContactComponent,
       SearchPropertyComponent,
       ZoningComponent,
       PlaceComponent,
       TextComponent
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.item.id != this.object.id){
                this.setTab()
            }
            this.setObject()
        },
        
        item () {   
            //this.setObject()
        }
    },

    data () {
        return {
            active_tab: 'tab-1',
            item: {

            },

            contacts: {
                model: 'builders',
                item_id: this.object.id,
                items: this.object.contacts
            },

            search_properties: {
                model: 'builders',
                item_id: '',
                item: ''
            },

            uploads: {
                model: 'builders',
                item_id: '',
                items: '',
                extensions: '.jpg,.png,.jpeg,.pdf,.xlsx,.docx',
                label_extensions: 'Arquivos permitidos  (pdf, xlsx, docx, jpeg e png)',
                path: '',
                descriptions: []
            },

            places: {
                model: "builders",
                item_id: "",
                cidade: "",
                estado: "",
                items: []     
            },

            texts: {
                model: 'builders',
                item_id: '',
                items: [],
                types: ['Observações']
            },

            zonings: {
                model: "builders",
                item_id: "",
                items: []     
            }
        }
    },

    methods: {
        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        setObject(){
            this.item = this.object

            // contacts
            this.contacts.item_id = this.item.id
            this.contacts.items = this.item.contacts

            // search_properties
            this.search_properties.item_id = this.item.id
            this.search_properties.item = this.object.search_properties && this.object.search_properties.length ? this.object.search_properties[0] : ''

            // texts
            this.zonings.item_id = this.object.id
            this.zonings.items = this.object.zonings

            // places
            this.places.item_id = this.object.id
            this.places.estado = this.object.estado
            this.places.items = this.object.places

            // texts
            this.texts.item_id = this.object.id
            this.texts.items = this.object.texts
        },
        
    },
}
</script>

<style scoped>

</style>