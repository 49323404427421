<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-0 ma-0"
                >        
                    <v-expansion-panels class="pa-0 ma-0" flat v-model="panel_search" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header hide-actions>
                                <bread-crumb-component :object="breadcrumbs"></bread-crumb-component>     
                                
                                <span class="text-right">
                                    <v-tooltip left class="text-right">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                        </template>
                                        <span>Filters</span>
                                    </v-tooltip>   
                                </span> 
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>                                
                                <!--filter-component @callLoadItems="loadItems"></filter-component-->    
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
         
        <div>
            <report-component v-if="listitems" :object="listitems" :permissions="permissions" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" @callShowItem="showItem" ref="report"></report-component>
        </div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog.edit" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Proposta
                    <span v-if="item.codigo" class="ml-2">- Código {{item.codigo}} </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog('edit')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" @callLoadItem="loadItem"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Dialog -->
        <!--v-dialog 
            v-if="item && item != ''"
            v-model="dialog.show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="item.codigo">Código {{item.codigo}} - {{item.nome}}</span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="permissions.update == true" icon @click="editItem(item.id)" class="mr-4">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="openCloseDialogShow()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-component :object="item"></show-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog-->
        <!-- End Dialog -->


        <!-- Icon new -->
        <v-tooltip left v-if="permissions.store == true">
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab dark color="pink" fixed right bottom @click.prevent="newItem()">
                    <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>New</span>
        </v-tooltip>
        <!-- End Icon new -->

    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'
/* 
import FilterComponent from './FilterComponent.vue'
import ShowComponent from '../show/ShowComponent.vue'
*/

export default {
    components:{
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent,
        //FilterComponent,
        //ShowComponent
    },
    
    created() {
        this.loadItems()
        //this.setUserPermission()
    },

    computed: {
        listitems() {
            return this.$store.state.proposals.items    
        },

        me () {
          return this.$store.state.auth.me
        },

        store_proposal() {
            return this.$store.state.proposals.store    
        },
    },    

    data () {
        return {
            permissions:{
                store: true,
            }, 

            item: {},
            
            dialog: {
                edit: false,
                show: false
            },

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Propostas',
                    disabled: true,
                    href: 'proposals',
                },
            ],

            panel_search: [],

            params: {
                id: ''
            },

            excel: {
                fields: {
                   // 'Days'                      : 'days',
                },

                data: [],   
            },

        }
    },


    methods: {
       
        loadItems (params = '') {
            this.$store.commit('STORE_PROPOSAL', false)
            
            if(this.$route.params.status && this.$route.params.status != ''){
                this.params.status = this.$route.params.status
                this.$route.params.status = ''
            }

            // in case is exists params from search is require set null all $route params
            if(params != ''){
                this.params = {} 
                this.params = params
            }
            this.$store.dispatch('loadProposals', this.params) 
            this.panel_search = []
        },

        newItem(){
            this.item = {}
            this.dialog.edit = true
        },

        loadItem(id){
            this.$store.dispatch('loadProposal', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        editItem (id) {   
            this.dialog.show = false 
            this.$store.dispatch('loadProposal', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog.show = false
                            this.dialog.edit = true
                        })
                                   
        },

        showItem (id) {    
            this.dialog.edit = false
            this.$store.dispatch('loadProposal', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog.edit = false
                            this.dialog.show = true
                        })
                                   
        },

        openCloseDialog(){
            if(this.item.id != undefined && this.store_proposal == true){
                this.params = {}
                this.params.id = this.item.id
            }else{
                this.$store.commit('STORE_PROPOSAL', false)
            }

            this.item = {}
            this.dialog.edit = !this.dialog
            this.dialog.show = false
            if(this.dialog.edit == false){
                this.loadItems(this.params)
            }
        },

        openCloseDialogShow(janela){
            if(janela == 'edit'){
                this.dialog.edit = !this.dialog.edit    
            }
            if(janela == 'show'){
                this.dialog.show = !this.dialog.show    
            }
        },

        setUserPermission(){
            this.permissions.store = this.userPermission('Cadastra proposta')
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>