<template>
    <div>
        <div v-if="tenant[0].id != '01ddf3b0-0eff-4bb3-a118-8f7fad06e6f0'">
            Módulo em desenvolvimento. Em breve!
        </div>
        <list-component v-else ref="list_component"></list-component>
    </div>

</template>

<script>
import ListComponent from './partials/ListComponent.vue'

export default {
    components: {
       ListComponent,
    },

    computed: {
        tenant() {
            return this.$store.state.tenants.items.data    
        }
    },

    data () {
        return {
          
        }
    },

    methods: {

    },
}
</script>

<style scoped>

</style>