<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.type"
                        :items="items.types"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        item-value="type"
                        item-text="type"
                        @change="setPrefix()"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Rede Social</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <v-text-field
                        v-model="item.description"
                        outlined
                        dense
                        :prefix="prefix"
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Link</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-btn
                        color="primary"
                        @click.prevent="validate()"
                        :class="$css_button"
                    >
                        Adcionar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- List  --> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.type`]="{ item }">
                            <span :class="$css_report_list">{{ item.type }}</span>
                        </template>

                        <template v-slot:[`item.description`]="{ item }">
                            <span :class="$css_report_list">{{ item.description }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <a :href="makeLink(item)" target="_blank">
                                        <v-btn icon class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-open-in-new</v-icon>
                                        </v-btn>
                                        </a>
                                    </template>
                                    <span>Link</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { TYPE_ITEMS } from './config_social_medias/config_social_medias.js'

export default {

    components: {
    },

    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            prefix: '',

            item: {
                model: '',
                item_id: '',
                type: '',
                description: '',
            },

            items: {
                types: TYPE_ITEMS
            },

            rules: {
                required: value => !!value || 'Required.',
            },

            headers: [
                { text: 'TIPO', align: 'start', value: 'type', },
                { text: 'DESCRIÇĀO', value: 'description' },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],
        }
    },

    methods: {
        setPrefix(){
            const result = this.items.types.filter(model => model.type == this.item.type)
            if(result.length > 0){
                this.prefix = result[0].url
            } 
        },

        makeLink(item){
            const result = this.items.types.filter(model => model.type == item.type)
            if(result.length > 0){
                var link = result[0].url + item.description
            } 

            return link
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.store()
            }
        },

        store(){
            this.item.model = this.object.model
            this.item.item_id = this.object.item_id
            const action = 'storeSocialMedia'
            this.$store.dispatch(action, this.item)
                        .then(response => {
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.object.item_id)
                            this.clear()
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

       destroy(item){
            this.$store.dispatch('destroySocialMedia', item.id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.item_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Successfully deleted')
                        )
        },

        clear() {
            this.$refs.form.reset()
            this.prefix = ''
        },

    }
}        
</script>