<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.principal"
                        :items="items.principal"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Principal</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.parentesco"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Parentesco / Relação</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.cpf != ''" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_nascimento"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="item.data_nascimento"
                                label="Dt nascimento"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                :class="$css_field"
                            ></v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_nascimento" @input="calendar.data_nascimento = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone2"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 2</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.property_owner_id" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.description"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs</span>
                        </template>
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email2"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail 2</span>
                        </template>
                    </v-text-field>
                </v-col> 
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cpf"
                        v-mask="['###.###.###-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CPF</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.rg"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">RG</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cnpj"
                        v-mask="['##.###.###/####-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CNPJ</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.inscricao_estadual"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Inscriçāo estadual</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nacionalidade"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nacionalidade</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cep"
                        @blur="searchCep(item.cep)"
                        v-mask="['#####-###']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CEP</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.endereco"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.bairro"
                        label="Bairro"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.cidade"
                        label="Cidade"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template> 
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.estado"
                        label="Estado"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.numero"
                        label="Número"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"                      
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.complemento"
                        label="Complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col>    
            </v-row>

            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                   <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>

                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {
            this.setObject()
        },

        data_nascimento () {
            this.item.data_nascimento = this.formatDate(this.data_nascimento)
        },
    },

    data () {
        return {
            item: {},

            items: {
               principal: ['Sim','Não']
            },
  
            rules: {
                required: value => !!value || 'Obrigatório',
                min_value:  v => (v && v.length >= 5) || 'O valor precisa ser maior que zero (0)',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            data_nascimento: '',

            calendar: {
                data_nascimento: false,
            },
        }
    },

    methods: {
        setObject(){
            this.item = this.object.client 
            //this.item.id = this.object.id
            this.item.principal = this.object.principal
            this.item.parentesco = this.object.parentesco
        },

        searchCep(value){
            if(!value) return ''
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setAddress(address){
            this.item.endereco = address.logradouro
            this.item.bairro = address.bairro
            this.item.cidade = address.localidade
            this.item.estado = address.uf
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.update(this.item)
            }
        },

        update(item){
            this.$delete(item, 'tipopessoa')
            this.$delete(item, 'obs')
            this.$delete(item, 'password')
            this.$delete(item, 'faturamento_anual')
            this.$delete(item, 'faturamento_mensal')
            this.$delete(item, 'branch_id')
            this.$delete(item, 'cargo')
            this.$delete(item, 'codigo')
            this.$delete(item, 'como_conheceu')
            this.$delete(item, 'created_at')
            this.$delete(item, 'id_fac')
            this.$delete(item, 'id_proprietario')
            this.$delete(item, 'midia')
            this.$delete(item, 'profissao')
            this.$delete(item, 'renda_mensal')
            this.$delete(item, 'tenant_id') 
            this.$delete(item, 'updated_at')
            item.id = this.object.id
            this.$store.dispatch('updateResident', item)
                        .then(response => {
                            //this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            //this.$emit('callLoadItem', this.object.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 

        teste(){
            this.$emit('callLoadItem', this.object.id)
        }
    },
}
</script>