<template>
    <div id="116">
        <alert-component :alert="alerts.compressphoto"></alert-component>

        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <vue-dropzone class="dropzone" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
            </v-col>
        </v-row>
        
        <div v-if="object.items || object.items_condominium">
        <v-row class="ma-2">
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"> 
                <v-btn
                    :loading="button.sendPhotos"
                    :disabled="button.sendPhotos"
                    color="blue-grey"
                    class="white--text rounded-0 "
                    @click="processQueue()"
                >
                    Enviar
                    <v-icon dark class="ml-2">
                        mdi-cloud-upload
                    </v-icon>
                </v-btn>    
                <span class="ma-4"></span>
                <v-btn
                    dark
                    color="error"
                    @click="removeAllFiles()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Excluir
                </v-btn>       
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-right">    
                <v-btn
                    v-if="!editUpload"
                    dark
                    color="success"
                    @click="editUploads()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Editar fotos
                </v-btn>  

                <v-btn
                    v-if="editUpload"
                    dark
                    color="success"
                    @click="massUpdate()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Gravar
                </v-btn> 
                <span class="ma-2"></span>
                <v-btn
                    v-if="editUpload"
                    dark
                    color="blue-grey"
                    @click="revertProcess()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Reverter
                </v-btn> 
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-right">    
                <v-btn
                    dark
                    color="primary"
                    @click="confirmMassDelete()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Excluir fotos cadastradas
                </v-btn>       
            </v-col>
        </v-row>        
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                 <v-expansion-panels  class="mb-3" v-if="object.items_condominium && object.items_condominium.length > 0">
                    <v-expansion-panel elevation="1">
                        <v-expansion-panel-header>
                        <span class="red--text ma-0">Fotos do condominio - Para alterar as fotos do condominio e necessário entrar no cadastro do condominio.</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col v-for="photo in object.items_condominium" :key="photo.id" cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
                                    <v-avatar
                                        class="ma-2"
                                        color="#BDBDBD"
                                        width="150px"
                                        height="120px"
                                        tile
                                    >
                                        <v-img 
                                            :src="photo | urlUpload"
                                            :lazy-src="photo | urlUpload"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                                >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-avatar>
                                </v-col>
                            </v-row>       
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-card
                    class="mx-auto"
                    outlined
                    v-if="object.items"
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="pagination"
                        class="elevation-1 table-striped"
                        :hide-default-footer="editUpload"
                    >
                        <template v-slot:[`item.photo`]="{ item }"> 
                            <v-avatar
                                class="ma-2"
                                color="#BDBDBD"
                                width="100px"
                                height="80px"
                                tile
                            >
                                <v-img 
                                    :src="item | urlUpload"
                                    :lazy-src="item | urlUpload"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-avatar>
                        </template>

                        <template v-slot:[`item.order`]="{ item }">
                            <v-text-field
                                class="mt-6"
                                type="number"
                                v-model="item.order"
                                outlined
                                dense
                                :class="$css_field"
                                :disabled="!editUpload"
                                :filled="!editUpload"  
                            >
                            </v-text-field>
                        </template>

                        <template v-slot:[`item.description`]="{ item }">
                            <v-text-field
                                class="mt-6"
                                v-model="item.description"
                                outlined
                                dense
                                :class="$css_field"
                                :disabled="!editUpload"
                                :filled="!editUpload"
                            >
                            </v-text-field> 
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left v-if="item.file">
                                    <template v-slot:activator="{ on, attrs }">  
                                        <v-icon @click="downloadImage(item.urlremoto, item.file)" v-bind="attrs" v-on="on">mdi-download</v-icon>                       
                                    </template>
                                    <span>DOWNLOAD</span>
                                </v-tooltip>   

                                <v-tooltip v-if="editUpload" left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="fileDestroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>EXCLUIR</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center">
                <v-btn
                    v-if="editUpload"
                    dark
                    color="success"
                    @click="massUpdate()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Gravar
                </v-btn> 
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog.massdelete"
            transition="dialog-top-transition"
            max-width="600"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Confirmação
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmMassDelete()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row dense>
                        <v-col cols="12">
                            Tem certeza que deseja excluir todas as fotos?  

                            <div class="text-center">
                                <v-radio-group
                                    v-model="confirm_delete"
                                    row
                                >
                                    <v-radio
                                        label="Sim"
                                        value="Sim"
                                    ></v-radio>

                                    <v-radio
                                        label="Não"
                                        value="Não"
                                    ></v-radio>
                                </v-radio-group>   
                            </div>                         
                        </v-col>
                    </v-row>
                    <v-row v-if="confirm_delete == 'Sim'">
                        <v-col cols="12" class="text-right">
                            <v-btn
                                dark
                                color="primary"
                                @click="massDestroyUpload()"
                                :class="$css_button"
                            >
                                Excluir
                            </v-btn>  
                        </v-col>
                    </v-row>     
                </v-container> 
            </v-card>    
        </v-dialog>  

        <v-dialog
            v-model="dialog.progressbar"
            hide-overlay
            persistent
            width="500"
        >
            <v-card
                dark
            >
                <v-card-text>
                <span class="mb-2">Processando ...</span>   
                
                <v-progress-linear
                    v-model="progressbar.uploads"
                    height="25"
                    class="mt-3"
                >
                    <strong>{{ Math.ceil(progressbar.uploads) }}%</strong>
                </v-progress-linear>

                </v-card-text>
            </v-card>
        </v-dialog>  

        </div>
    </div>
</template>

<script>
import AlertComponent from '../alert/AlertComponent.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    components: {
        vueDropzone: vue2Dropzone,
        AlertComponent
    },

    props: {
        object: {
            require: true,
        },
    },

    computed: {
        tenant() {
            return this.$store.state.tenants.items.data    
        },
    },

    data (){
        return {
            alerts: {
                compressphoto: {
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['Sugerimos sempre compactar as imagens antes de realizar o upload das fotos. Voce poderá acessar o site https://imagecompressr.com e seguir os seguintes passos: <br /> 1  - Acesse o site <a href="https://imagecompressr.com" target="_blank">https://imagecompressr.com </a> <br /> 2 - Clique no botão "Select Files" e faça o upload das imagens <br /> 3 - Altere o parâmetro "Max Size" para 0,5 e clique no botão "Start compression" ']
                },
            },

            editUpload: false,

            pagination: 10,

            button: {
                sendPhotos: false
            },

            loader: '',

            acceptedFiles: [],
            
            confirm_delete: 'Não',

            params_delete: {},

            params_setphoto: {},

            dialog: {
                massdelete: false,
                progressbar: false
            },

            progressbar: {
                uploads: 0,
            },

            editItem:{},

            dropzoneOptions: {
                ok: true,
                timeout: 0,
                uploadMultiple: true,
                parallelUploads: 2,
                autoProcessQueue: false,
                maxFiles: 30,

                url: process.env.VUE_APP_ROOT_API + '/api/uploads',
                params: {
                    model: this.object.model,
                    item_id: this.object.item_id,
                    resize: 'true'
                },
                addRemoveLinks: true,
                dictDefaultMessage: "Clique aqui para inserir as imagens (v1.1.7). Extensões válidas (JPG, PNG e WebP). <br /><br /><strong style='color: #CC0000;'> É sugerido enviar 30 fotos por vez. <br /> Para facilitar o envio de fotos diminua o tamanho das fotos antes de enviar. <br /> Em média os portais de propaganda permitem um número maximo de 30 fotos por imóvel </strong>",
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                maxFilesize: 40,
                resizeHeight: 1500,
                resizeQuality: 1,
                removeFile:true,
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN_AUTH') },
            },

            headers: [
                { text: 'FOTO', value: 'photo' , sortable: false},
                { text: 'ORDEM', value: 'order' , sortable: true}, 
                { text: 'DESCRIÇĀO', value: 'description' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],
        }
    },

    methods: {

        processQueue() {
            this.button.sendPhotos = true
            this.openCloseDialog('progressbar')
            this.acceptedFiles = this.$refs.myVueDropzone.getAcceptedFiles() 
            this.sendQueue(0)  
        },

        sendQueue(count) {
            const file = this.acceptedFiles[count]
            const total = this.acceptedFiles.length    
            this.fileSubmit(file, count, total)
        },

        fileSubmit(file, count, total){
            let contador = 0 
            if(this.object.items){
                contador = this.object.items.length 
            }
            let order =  (count + 1) + contador  
            const formData = new FormData()
            formData.append('model', this.object.model)  
            formData.append('item_id', this.object.item_id)  
            formData.append('order', order)  
            formData.append('resize', 'true')  
            formData.append('file[0]', file)

            if(this.tenant[0].watermark && this.tenant[0].watermark != ''){
                formData.append('watermark', this.tenant[0].watermark)
                //console.log(this.tenant[0].watermark)
            }

            this.$store.dispatch('storeUploadNew', formData)
                            .then(response => {
                                this.progressbar.uploads = ((count + 1)/total) * 100
                                if((count + 1) == total){
                                    this.dialog.progressbar = false
                                    this.button.sendPhotos = false
                                    this.progressbar.uploads = 0
                                    this.$snotify.success('Imagens enviadas com sucesso')
                                    this.removeAllFiles()
                                    this.setPhotoFinal()
                                }else{
                                    this.sendQueue(count + 1)
                                }
                            })
                            .catch(error => {   
                                this.$snotify.error('Interrupação no Upload. Isso pode ser causado alguma interferência na sua conexão. Tente subir novamente ')
                                this.errors = error.response.data.errors
                                this.dialog.progressbar = false
                                this.button.sendPhotos = false
                                this.progressbar.uploads = 0
                                this.$emit('callLoadItem', this.object.item_id)
                                this.removeAllFiles()
                            }) 
        },        

        removeAllFiles(){
            this.$refs.myVueDropzone.removeAllFiles()
        },

        confirmMassDelete(){    
            this.dialog.massdelete = !this.dialog.massdelete
        },

        destroy() {
             this.$refs.myVueDropzone.removeAllFiles(true)
        },

        massDestroyUpload(){
            this.params_delete.model = this.object.model
            this.params_delete.item_id = this.object.item_id
            this.$store.dispatch('massDestroyUpload', this.params_delete)
                        .then(response => {
                            this.$snotify.success('Fotos excluídas com sucesso!')
                            this.confirmMassDelete()
                            this.confirm_delete = 'Não'
                            this.setPhotoFinal()
                            this.$emit('callLoadItem', this.object.item_id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
            
        },

        save(item, count, total) {
           
            this.$store.dispatch('updatePhoto', item)
                        .then(response => {
                            this.progressbar.uploads = ((count + 1)/total) * 100
                            if((count + 1) == total){
                                this.revertProcess()
                                this.dialog.progressbar = false
                                this.progressbar.uploads = 0
                                this.setPhotoFinal()
                            }else{
                                this.massUpdate(count + 1)
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.dialog.progressbar = false
                            this.$snotify.error('Erro no cadastro')
                        })
                        
        },

        fileDestroy(file){
            this.$store.dispatch('destroyUpload', file.id)
                        .then(response => {
                            this.setPhotoFinal()   
                          
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Registro excluído com sucesso')
                        )
                     
        },

        async downloadImage(urlImage, nameImage) {
            
            let fileImage = urlImage.replace('.webp','.jpg');
            let name = nameImage.replace('.webp','.jpg');

            const blob = await (await fetch(fileImage)).blob();
        
            const url = URL.createObjectURL(blob);
            Object.assign(document.createElement('a'), { href: url, download: name })
                .click();
            URL.revokeObjectURL(url);
        },

        openCloseDialog(dialog) {
            if(dialog == 'progressbar'){
                this.dialog.progressbar = !this.dialog.progressbar
            }
        },

        editUploads(){
            this.editUpload = true
            this.pagination = 1000
        },

        revertProcess(){
            this.editUpload = false
            this.pagination = 10
            this.$emit('callLoadItem', this.object.item_id)
        },

        massUpdate(count = null){
            if(count == null){
                count = 0
                this.openCloseDialog('progressbar')
            }

            this.save(this.object.items[count], count, this.object.items.length)
            //console.log(this.object.items[count])
        },

        setPhotoFinal(){
            this.params_setphoto.model = this.object.model
            this.params_setphoto.item_id = this.object.item_id
            //console.log(this.params_setphoto)    
             
            this.$store.dispatch('setPhotoFinal', this.params_setphoto)
                        .then(response => {
                            this.$emit('callLoadItem', this.object.item_id)
                            //console.log('set photo success')
                        })
                        .catch(error => {
                            console.log('Erro set fotos')
                        })
            
        },
    }
}
</script>

<style scoped>
    .dropzone .dz-preview.lp-preview {
        width: 100px;
    }
    .dropzone.hzScroll {
        width: 100%;
        height: 100%;
        overflow: auto;
        white-space: nowrap;
        background-color: blanchedalmond;
    }
</style>