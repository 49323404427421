<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.user_id"
                        :items="items.users"
                        :rules="[rules.required]"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Colaborador</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.description"
                        :items="object.descriptions"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Funçāo</span>
                        </template>
                    </v-select>
                </v-col>         
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span v-if="item.id">Editar</span>
                        <span v-else>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.name`]="{ item }">
                            <span :class="$css_report_list">{{ item.name }}</span>
                        </template>

                        <template v-slot:[`item.description`]="{ item }">
                            <span :class="$css_report_list">{{ item.description }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>  
    </div>
</template>

<script>
    export default {
        created() {
            this.loadUsers()
        },

        props: {
            object: {
                require: true,
            },
        },

        data () {
            return {
                item: {},

                items: {
                    users: []
                },

                params: {
                    paginate: 'false'
                },

                rules: {
                    required: value => !!value || 'Required.',
                },

                headers: [
                    { text: 'NOME', align: 'start', value: 'name', },
                    { text: 'DESCRIÇĀO', align: 'start', value: 'description', },
                    { text: 'AÇĀO', value: 'actions', sortable: false },
                ],
            }
        },

        methods:{   

          
            validate () {
                if (this.$refs.form.validate()) {       
                    this.storeUpdate()
                }
            },
            
            storeUpdate(){
                this.item.model = this.object.model
                this.item.item_id = this.object.item_id
                
                const action = this.item.id ? 'updateColaborator' : 'storeColaborator'
                this.$store.dispatch(action, this.item)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                                this.$snotify.success('Registered Successfully!')
                                this.reset()
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
            },

            editItem (id) {    
                this.$store.dispatch('loadColaborator', id) 
                            .then(response => {
                                this.item = response.data
                            })
            },

            reset(){
                this.item = {}
                this.$refs.form.reset()
            },

            destroy(item){
                this.$store.dispatch('destroyColaborator', item.id)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Successfully deleted')
                            )
            },

            loadUsers(){
                    const user_params = {}
                    user_params.paginate = 'false'
                    user_params.all = 'true'
                    this.$store.dispatch('loadPromisseUsers', user_params)
                                .then(response => {
                                    this.items.users = response.data   
                                })
                                .catch(error => {
                                    console.log(error)
                                })
            },
        }    
    }
</script>